/**
 * This contains utility functions used across multiple parts of the app
 */

import {
  GPABands,
  ADABands,
  suspensionsBands,
  creditBands,
} from "@/apps/fieldBands";
import studentSchema from "@/schemas/studentSchema.json";
import { currentEnvironment } from "@/firebase/environments";

// TECH DEBT: we should not be hard coding the current year in the app. That is something that should be read in from Firestore.
// school year 2022-2023 is stored as 2223.
export const currentYear = 2425;

export const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

//TECH DEBT: this logic needs reworked because if you get to `return record[key][0]?.value;`
//then its looking for an actual propety called value from a deep object: {key: {0: {value: 'return value'}}}
//Some values coming in are simply: {key: {0: value}}
export const getValueFromObject = (record, key) => {
  // return value from object using key that may include a .
  // for example, school.name would return the value from {school: {name: value}}
  if (!key) return "";
  if (!key.includes(".")) {
    if (record[key] === null) return null;
    if (typeof record[key] === "object") {
      return record[key][0]?.value;
    }
    return record[key];
  }
  const keypath = key.split(".");
  return record[keypath[0]][keypath[1]];
};

export const processTrueFalse = (val) =>
  val === "true" ? true : val === "false" ? false : val;

export const getGroupValue = ({ field, value }) => {
  if (value === null) return value;
  if (value === "No data") return null;
  if (!field.options || field.type === "categoryArray") return value;
  return processTrueFalse(
    Object.keys(field.options).find((key) => {
      const val = field.options[key];
      const optionDisplayValue =
        typeof val === "object" ? val.displayValue : val;
      return optionDisplayValue == value;
    })
  );
};

export const distinctSet = ({ fieldKey, data }) => [
  ...new Set(data.map((record) => getValueFromObject(record, fieldKey))),
];

export const orderSet = ({ a, b, dataMissingWarning = "No data", field }) => {
  if (a === null || a === undefined || a === dataMissingWarning) return 1;
  if (b === null || b === undefined || b === dataMissingWarning) return -1;

  if (field.order)
    return field.order.indexOf(a) > field.order.indexOf(b) ? 1 : -1;
  if (field.bands) return a > b ? 1 : -1;

  const _a = getDisplayValue({ field, value: a });
  const _b = getDisplayValue({ field, value: b });

  return _a > _b ? 1 : -1;
};

export const getDisplayValue = ({ value, field }) => {
  /* shows the field category display name when it is different to the underlying data
   * for example the data are true/false and the display value is 'ELL', 'Not ELL'
   */
  const dataMissingWarning = "No data";
  if (value === null || value === undefined) return dataMissingWarning;
  if (!field.options) return value.toString();
  const val = field.options[value];
  return typeof val === "object" ? val.displayValue : val;
};

export const getAutoBandSpecs = ({ chartData, fieldKey }) => {
  const allValues = chartData.map((element) =>
    getValueFromObject(element, fieldKey)
  );

  const max = Math.ceil(Math.max(...allValues));
  const min = Math.floor(Math.min(...allValues));
  const range = max - min;
  const maxBars = 6;
  const barWidth = Math.ceil(range / maxBars);
  let barCount = Math.ceil(range / barWidth);

  if (!Number.isFinite(barCount)) {
    console.warn("Error calculating bands for numeric field");
    barCount = 0;
  }

  return [min, barWidth, barCount];
};

export const getAutoBands = ({ chartData, fieldKey }) => {
  const [min, barWidth, barCount] = getAutoBandSpecs({ chartData, fieldKey });
  if (barCount === 0) return [];

  const array = Array(barCount).fill(0);

  return array.map((e, i) => {
    let high;
    const low = min + i * barWidth;
    // If it's the last band we want it to be inclusive of the high value
    if (i + 1 === array.length) {
      high = min + (i + 1) * barWidth;
    } else {
      high = min + (i + 1) * barWidth - 0.01;
    }

    return {
      low,
      high,
      style: ["warning", "info", "gradeB", "success"][i % 4],
      label: `${low}-${high}`,
    };
  });
};

export const getAutoLabels = ({ chartData, fieldKey }) => {
  const [min, barWidth, barCount] = getAutoBandSpecs({ chartData, fieldKey });
  if (barCount === 0) return [];

  const array = Array(barCount).fill(0);
  return array.map((e, i) => {
    let high;
    const low = min + i * barWidth;
    // If it's the last band we want it to be inclusive of the high value
    if (i + 1 === array.length) {
      high = min + (i + 1) * barWidth;
    } else {
      high = min + (i + 1) * barWidth - 0.01;
    }
    return `${low} - ${high}`;
  });
};

export const testValue = ({ field, value, category }) => {
  if (field.type === "numeric") {
    if (category === "No data") {
      return value === null;
    }
    if (value === null) return false;

    const myBand = field.bands
      ? field.bands.find((band) => band.label === category)
      : {
          low: Number(category.split("-")[0]),
          high: Number(category.split("-")[1]),
        };

    //update this logic to reflect current band logic.
    return value >= myBand.low && value <= myBand.high;
  }
  return value === category;
};

export const blackOrWhite = (color) => {
  if (!color) return "black";
  if (color.slice(0, 1) === "#") color = color.slice(1);
  // returns black if the color is light, white if the color is dark
  const r = parseInt(color.substr(0, 2), 16),
    g = parseInt(color.substr(2, 2), 16),
    b = parseInt(color.substr(4, 2), 16);
  const brightness = (r * 299 + g * 587 + b * 114) / 1000;

  return brightness > 140 ? "black" : "white";
};

export const isEnrolled = (record) => {
  // this section filters out unenrolled students.

  const status = record.highSchoolEnrolledStatus;
  if (!status) {
    console.error("Student is missing highSchoolEnrolledStatus data");
    return false;
  }
  if (!Array.isArray(status)) {
    console.error("highSchoolEnrolledStatus field has unexpected data type");
    return false;
  }
  if (status[0]?.value === undefined) {
    console.error("highSchoolEnrolledStatus field has unexpected log entry");
    return false;
  }
  if (status[0].value === null) {
    return false;
  }
  if (typeof status[0].value !== "boolean") {
    console.error("highSchoolEnrolledStatus field is not a boolean or null");
    return false;
  }

  return status[0].value;
};

const checkFieldsMatchSchema = (fields) => {
  // check read only fields match correctly
  fields.forEach((field) => {
    if (field.readOnly === undefined) {
      console.error("field is missing readonly property", field);
      return;
    }
    if (!field.key) return;
    const myField = studentSchema.properties[field.key];
    if (!myField) return;
    if (myField.readOnly === undefined) {
      console.error("field is missing readonly property in schema", myField);
      return;
    }
    if (myField.readOnly !== field.readOnly) {
      console.error(
        "read only properties do not match between schema and fields.js",
        field
      );
    }
  });
};

export const processFieldsAndGroups = ({ fields, groups }) => {
  checkFieldsMatchSchema(fields);

  fields.forEach((f) => {
    const group = groups.find((g) => g.fields.includes(f.key));
    f.optionGroup = group?.name || null;
  });

  groups.forEach((group) => {
    group.fields = group.fields.map((element) => ({
      name: fields.find((e) => e.key === element).displayName,
      showInGrades: fields.find((e) => e.key === element).showInGrades,
      key: element,
      checked: true,
    }));
  });
  return [fields, groups];
};

export const getFirst = (cell) => {
  if (!cell.getValue()) {
    console.error(
      "Error, value missing. Inspect the console stack trace to find which field has missing value",
      cell
    );
    return null;
  }
  return cell.getValue()[0].value;
};

export const logSort = (a, b) => {
  // sort by first value in array
  if (a[0].value < b[0].value) return -1;
  if (a[0].value > b[0].value) return 1;
  return 0;
};

export function customHeaderFunc(headerValue, rowValue, rowData, filterParams) {
  if (!headerValue) return true;
  if (Array.isArray(headerValue) && headerValue[0] === "") return true;
  return headerValue == rowValue;
}

export function getHeaderButtons({ fn, field, hoverFn }) {
  const container = document.createElement("span");

  if (field.noHeaderMenu) return container;

  [
    { icon: "filter_alt", fn: "filter", tip: "Add/remove filter" },
    { icon: "bar_chart", fn: "chart", tip: "Show field insights" },
    { icon: "visibility_off", fn: "hide", tip: "Hide column" },
  ].forEach((element) => {
    if (element.icon === "bar_chart" && field.hideInsights) return;
    if (element.icon === "filter_alt" && field.hideHeaderFilterIcon) return;
    const el1 = document.createElement("span");
    el1.classList.add(
      "material-icons-outlined",
      "my-header-btn",
      "my-header-btn-" + element.fn
    );
    el1.innerHTML = element.icon;
    el1.setAttribute("data-cy", "my-header-btn-" + element.fn);
    el1.onclick = () => fn({ key: field.key, fn: element.fn });
    el1.onmouseenter = () => hoverFn(element.tip);
    container.appendChild(el1);
  });
  return container;
}

export const getDisplayClass = ({ student, field }) => {
  const value = getValueFromObject(student, field.key);
  if (value === null) return "dataMissing";

  // We don't want the recentAbsences field to be styled in the Student Profile
  if (field.key === "recentAbsences") return;

  if (field.bands) {
    const myBand = field.bands.find(
      (element) => element.low <= value && element.high >= value
    );
    return myBand?.style || "";
  }
  if (field.options) {
    const myOption = field.options[value];
    return myOption?.style || "";
  }
};

export const getPastCourseDetails = ({ student, course }) => {
  const grade = student.currentGradeLevel - (currentYear - course.schoolYear);

  const letterGrade =
    course.semester === "FY"
      ? course.letterGrade.Y1
      : course.letterGrade[course.semester];

  const percentageGrade =
    course.semester === "FY"
      ? course.percentageGrade.Y1
      : course.percentageGrade[course.semester];

  const credit = letterGrade === "F" ? 0 : course.semester === "FY" ? 1 : 0.5;

  return [grade, letterGrade, percentageGrade, credit];
};

export const sortPastCourses = (a, b) =>
  a.schoolYear < b.schoolYear
    ? 1
    : a.schoolYear === b.schoolYear
    ? a.semester < b.semester
      ? -1
      : 1
    : -1;

const careerFields = (grade) => [
  {
    name: "GPA",
    field: grade === 8 ? "g9RiskAndOpportunity.GPA" : `g${grade}GPA`,
    thisYearField: "GPA",
    display: (value) =>
      ["number", "string"].some((element) => element === typeof value)
        ? Number(value).toFixed(2)
        : value,
  },
  {
    name: "Credits",
    field: `g${grade}Credits`,
    thisYearField: "creditsPredicted",
  },
  {
    name: "ADA",
    field: grade === 8 ? "g9RiskAndOpportunity.ADA" : `g${grade}ADA`,
    thisYearField: "ADA",
    display: (str) => `${str}%`,
  },
  {
    name: "Suspensions",
    thisYearField: "suspensions",
    field: `g${grade}Suspensions`,
  },
];

export const getCareerSummaryEntry = ({
  grade,
  category,
  student,
  raw = false,
}) => {
  if (!student) return "";
  if (grade === "Cumulative") {
    return getCumulative({ category, student });
  }
  if (grade > student.currentGradeLevel) return "-";

  const fieldElement = careerFields(grade).find(
    (element) => element.name == category
  );

  let value;
  if (!fieldElement) return "";
  if (fieldElement.field === "g9RiskAndOpportunity.GPA") {
    value = student["g9RiskAndOpportunity"]["GPA"];
  } else if (fieldElement.field === "g9RiskAndOpportunity.ADA") {
    value = student["g9RiskAndOpportunity"]["ADA"];
  } else {
    value = student[fieldElement.field];
  }

  if (value === null) return "No data";
  if (value === undefined) return "No data";
  if (raw) return value;
  return fieldElement.display ? fieldElement.display(value) : value;
};

function getCumulative({ category, student }) {
  if (category === "GPA")
    return student.GPA === null ? "-" : student.GPA.toFixed(2);
  const arr = [8, 9, 10, 11, 12]
    .filter((num) => num <= student.currentGradeLevel)
    .map((num) =>
      getCareerSummaryEntry({ grade: num, category, student, raw: true })
    )
    .filter((e) => e !== "No data");

  const sum = arr.reduce((prev, curr) => {
    const _prev = typeof prev === "number" ? prev : 0;
    const _curr = typeof curr === "number" ? curr : 0;
    return _prev + _curr;
  }, 0);

  if (category === "ADA") return `${(sum / arr.length).toFixed(1)}%`;
  return sum;
}

const bands = {
  GPA: GPABands,
  ADA: ADABands,
  Credits: creditBands,
  Suspensions: suspensionsBands,
};

export const getCareerSummaryClass = ({ grade, category, student }) => {
  if (grade === "Cumulative") return "";
  if (grade === 8 && category === "Credits") return "";
  const value = getCareerSummaryEntry({ grade, category, student });
  if (value === null) return "dataMissing";
  const myBand = bands[category]?.find(
    (element) => element.low <= value && element.high >= value
  );
  return myBand?.style || "";
};

export const getMostRecentCheckIn = ({ data, params }) => {
  const inPersonCheckInTypes = [
    "1:1 Student Meetings",
    "Group/Workshop/Lunch Bunch",
    "Student Communication",
  ];

  const mostRecentCheckIn = params?.notes
    ?.filter((element) =>
      element.studentArr.some((entry) => entry.id === data.studentRISEId)
    )
    .filter((element) =>
      inPersonCheckInTypes.some((t) => t === element.checkInCategory)
    )
    .filter((element) => {
      if (params.user.hasPower("viewAllCheckins")) return true;
      return element.uid === params.user.uid;
    })
    .map((element) => element.date)
    .sort((a, b) => (a < b ? 1 : -1))[0];

  if (!mostRecentCheckIn) return null;

  const today = new Date(`${new Date().toISOString().slice(0, 10)}T12:00`);
  const checkInDate = new Date(`${mostRecentCheckIn}T12:00`);
  const result = (today - checkInDate) / (1000 * 60 * 60 * 24);
  return Math.round(result);
};

export const getMutatorParams = ({ state, user }) => {
  const notes = state.notes.filter((element) => element.docType === "check-in");
  const passingThreshold = state.schoolMetaData?.passingThreshold || 60;
  const currentQuarter = state.currentQuarter;

  return { notes, passingThreshold, user, currentQuarter };
};

/**
 * Convert YYYY-MM-DD to date string without day of week
 * e.g. 2022-08-29 => Aug 29 2022
 */
export const beautifyDate = (str) => {
  // Return an empty string if passed a falsy argument
  if (!str) return "";

  return new Date(str.replaceAll("-", "/")).toDateString().slice(4);
};
// This is a compare function to be passed into a JS sort function to alphabetize schools based on their display names
export const compareSchools = (a, b) => {
  const schoolA = a.displayName;
  const schoolB = b.displayName;

  let comparison = 0;
  if (schoolA > schoolB) {
    comparison = 1;
  } else if (schoolA < schoolB) {
    comparison = -1;
  }
  return comparison;
};
// Function to alphabetize an array of objects of studentData by their '_studentName' field
export const alphabetizeStudents = (a, b) => {
  let studentA;
  let studentB;
  if (a._studentName && b._studentName) {
    studentA = a._studentName;
    studentB = b._studentName;
  } else {
    studentA = a.name;
    studentB = b.name;
  }

  let comparison = 0;
  if (studentA > studentB) {
    comparison = 1;
  } else if (studentA < studentB) {
    comparison = -1;
  }
  return comparison;
};

/**
 * Get current date in the following format:
 * "December 5, 2022"
 */

export const getCurrentFormattedDate = () => {
  const options = { month: "long", day: "numeric", year: "numeric" };
  return new Date().toLocaleDateString("en-US", options);
};
/**
 * Sanitizes a file name string so it can be downloaded
 * @param {string} inputFileName string to be sanitized
 * @returns string
 */
export const sanitizeFileName = (inputFileName) => {
  const sanitizedString = inputFileName
    .replace(/[^a-zA-Z0-9_-]/g, "")
    .replace(/^-+|-+$/g, "");

  return sanitizedString;
};

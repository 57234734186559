/**
 * Makes libraries and functions accessible as global properties within vue elements.
 * For example, app.config.globalProperties.thing will be available as this.thing within any vue component.
 */

import { fields, fieldGroups } from "@/apps/fields.js";
import { gradeConfig } from "@/apps/gradeConfig";

// IdleJs Library
const IdleJs = require("@/core/idle.js");

// Events library
const EventEmitter = require("events");

export const installPlugins = (app, options) => {
  // allows different parts of the app to listen to custom events, allowing remote components to talk to each other
  app.config.globalProperties.ee = new EventEmitter();

  // convenience function for showing tooltip (explanatory text)
  app.config.globalProperties.showTooltip = (str) =>
    app.config.globalProperties.store.setTooltip(str);

  // makes idle.js library variable as this.idle
  app.config.globalProperties.idle = new IdleJs({
    idle: 300000, // 5 minutes
    onIdle: () => app.config.globalProperties.ee.emit("showIdleModal"),
    onActive: () => app.config.globalProperties.ee.emit("setNotIdle"),
    keepTracking: true,
    startAtIdle: false,
  });
};

export const initialize = (app, options) => {
  // Initialize two global objects that are available inside Vue objects as this.config and this.$user

  app.config.globalProperties.config = {
    gradeConfig,
    fields,
    fieldGroups,
  };
  app.config.globalProperties.$user = null;
};

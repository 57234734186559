/**
 * Contains all the mutator and sorter functions for the fields of student documents.
 * Used in fields.js.
 * The inputs ({data, params}) are set in getTrackerColumn in Tracker.vue
 */

import { getCuspCourses } from "@/functions/onTheCusp";
import { getMostRecentCheckIn } from "@/functions/utils";

export const mutators = {
  studentName({ data }) {
    return [data.lastName, data.firstName].join(", ");
  },
  myTags({ data, params }) {
    const id = data.studentRISEId;
    return ["star", "flag", "warning", "help", "done"].filter((icon) =>
      params.user.preferences.taggedStudents[icon].includes(id)
    );
  },
  planStatus({ data }) {
    const raw = data.plans[0]?.value;
    if (!raw || raw === "No Plan") return "No Plan";
    return raw === "Unsure" ? "Unsure" : "Confirmed Plan";
  },
  predictedCredits({ data }) {
    if (typeof data.creditsEarned !== "number") return null;
    if (typeof data.creditsPredicted !== "number") return null;

    return +(data.creditsEarned + data.creditsPredicted).toFixed(1);
  },
  test() {
    return "Predicted EOY Credits";
  },
  onTrackPlus({ data }) {
    if (data.onTrackStatus !== "on-track") return data.onTrackStatus;
    return data.GPA >= 3 ? "on-track-plus" : "on-track";
  },
  currentQuarterOnTrackStatus({ data, params }) {
    return data[`Q${params.currentQuarter}OnTrackStatus`];
  },
  onTheCusp({ data, params, _getCuspCourses = getCuspCourses }) {
    const cuspCourses = _getCuspCourses({
      courses: data.courses,
      passingThreshold: params?.passingThreshold,
      currentQuarter: params.currentQuarter,
    });
    return cuspCourses.length > 0;
  },
  onTheCuspCourses({ data, params, _getCuspCourses = getCuspCourses }) {
    const cuspCourses = _getCuspCourses({
      courses: data.courses,
      passingThreshold: params?.passingThreshold,
      currentQuarter: params.currentQuarter,
    });
    return cuspCourses.map((e) => e.courseTitle).join(", ");
  },
  climberSlider({ data, params }) {
    // find most recent available on-track data
    const previousQ = params.currentQuarter - 1;

    const lastQWasOnTrack =
      previousQ === 0
        ? data.g9RiskAndOpportunity.riskAndOpportunity === "Opportunity" ||
          data.g9RiskAndOpportunity.riskAndOpportunity === "High Opportunity"
        : data[`Q${previousQ}OnTrackStatus`] === "on-track";

    const thisQIsOnTrack =
      data[`Q${params.currentQuarter}OnTrackStatus`] === "on-track";

    return thisQIsOnTrack !== lastQWasOnTrack
      ? thisQIsOnTrack
        ? "Climber"
        : "Slider"
      : "Unchanged";
  },
  fourYearStepsDone({ data }) {
    const fieldList = [
      "navianceCollegeList",
      "FAFSAStatus",
      "collegeApps",
      "studentRequestedRecs",
      "teacherSubmittedRecs",
    ];

    return fieldList.filter(
      (element) => data[element]?.[0]?.value === "completed"
    ).length;
  },
  collegeAppsSubmitted({ data }) {
    return data.collegeAppsSubmitted?.[0]?.value ?? 0;
  },
  transcriptsSent({ data }) {
    return data.transcriptsSent?.[0]?.value || null;
  },
  daysSinceLastCheckIn({ data, params }) {
    return getMostRecentCheckIn({ data, params });
  },

  /**
   * This mutator filters out dropped courses from a Student's courses list.
   *
   * @param   {data}         Object    Student record from Firestore
   * @returns {data.courses} Object    Returns an adjusted course list that does not contain any dropped courses.
   */
  droppedCourses({ data }) {
    return Object.fromEntries(
      Object.entries(data.courses).filter(([key, course]) => {
        return course.courseDropped && course.courseDropped === true
          ? false
          : true;
      })
    );
  },
};

export const sorters = {
  tags(a, b) {
    if (!a[0]) return 1;
    if (!b[0]) return -1;

    const A = a[0].value.join(" ");
    const B = b[0].value.join(" ");

    if (A < B) return -1;
    if (A > B) return 1;
    return 0;
  },
};

/**
 * Session contains the functions that deal with logging in and out, setting up the app, and launching different tools within the app.
 */

import {
  getAuth,
  signOut,
  signInWithPopup,
  GoogleAuthProvider,
} from "firebase/auth";
import { getUserObject } from "@/core/user.js";

export const installSession = (app, options) => {
  app.config.globalProperties.session = {
    launchLoginPopup() {
      const provider = new GoogleAuthProvider();
      const auth = getAuth();

      //this is a firebase function
      signInWithPopup(auth, provider)
        .then((authResponse) => this.processLogIn(authResponse.user))
        .catch((error) => console.error(error));
    },
    async processLogIn(user) {
      const [userDoc, calendarEvents] = await Promise.all([
        app.config.globalProperties.fb.user.getMyself(user.uid),
        app.config.globalProperties.fb.calendar.getAll(),
      ]);

      if (userDoc.dataHubActiveAccount === false) {
        app.config.globalProperties.store.state.noDataHubActiveAccount = true;
        return;
      } else {
        let updateDefaultSchool = false;
        // confirm that the user's default school matches a school in their dataHubSchools array
        // if it doesn't match, update their local userDoc and set a flag to update Firestore when we are able to
        if (
          !userDoc.dataHubSchools.some(
            (school) => school === userDoc.preferences.defaultSchool
          )
        ) {
          userDoc.preferences.defaultSchool = userDoc.dataHubSchools[0];
          updateDefaultSchool = true;
        }

        // set current user. This must happen before the next async calls
        app.config.globalProperties.$user = getUserObject({
          userDoc,
          user: user,
        });

        // show security agreement if user has not yet agreed
        if (!app.config.globalProperties.$user.agreeToDataPolicy) {
          app.config.globalProperties.ee.emit("launchSecurityModal");
        }

        // if the user's default school didn't match one of the schools in their dataHubSchools array set the default school in Firestore
        // this needs to happen after setting the app.config.globalProperties.$user so we have access to the "updatePreference" function
        if (updateDefaultSchool) {
          app.config.globalProperties.fb.user.updatePreference(
            "defaultSchool",
            userDoc.dataHubSchools[0]
          );
        }

        // set the current school to user's default. This has to happen before getting school meta data
        app.config.globalProperties.store.setSchool(
          app.config.globalProperties.$user.defaultSchool
        );

        // load in the rest of the school list and school metadata
        const [schoolList, meta] = await Promise.all([
          app.config.globalProperties.fb.schools.getSchoolList(
            app.config.globalProperties.$user.schools
          ),
          app.config.globalProperties.fb.schools.getMetaData(),
        ]);

        // apply user preferences to store (currently only theme preference)
        app.config.globalProperties.store.applyUserPreferences({
          userPreferences: app.config.globalProperties.$user.preferences,
        });

        // get school meta data
        app.config.globalProperties.store.setSchoolMetaData(meta);

        // set current quarter - read from school meta so call after setting that
        app.config.globalProperties.store.setCurrentQuarter(
          meta.currentMarkingPeriod
        );

        // set available schools
        app.config.globalProperties.store.setSchools(schoolList);

        // get calendar data for strategic data calendar
        app.config.globalProperties.store.setCalendarEvents(calendarEvents);

        app.config.globalProperties.store.setLaunched(true);

        // add log in event to events DB
        app.config.globalProperties.fb.events.add({
          uid: user.uid,
          datetime: Date.parse(new Date()),
          userSchools:
            app.config.globalProperties.$user.district === "RISENetwork"
              ? ["RISE"]
              : app.config.globalProperties.$user.schools,
          userDistrict: app.config.globalProperties.$user.district,
          userRoles: app.config.globalProperties.$user.roles,
          eventType: "login",
        });

        // navigate home
        app.config.globalProperties.$router.push("/home");

        // start the idle countdown timer
        app.config.globalProperties.idle.start();
      }
    },
    subscribe(grade) {
      this.clearAndUnsubscribe();
      app.config.globalProperties.store.setGradeLevel(grade);

      // subscribe to Firebase updates
      app.config.globalProperties.fb.subscribe();
    },
    processLogOut() {
      this.clearAndUnsubscribe();

      const auth = getAuth();
      signOut(auth)
        .then(() => {
          // Sign-out successful.

          // reset UI
          app.config.globalProperties.store.resetUI();

          // stop the idle countdown timer
          app.config.globalProperties.idle.stop();

          // reload the page forces resetting of all local app state
          window.location.reload();
        })
        .catch((error) => {
          console.info(error);
        });
    },
    clearAndUnsubscribe() {
      // cause the app to clear any local state
      app.config.globalProperties.ee.emit("clearLocalState");

      // clear the store
      app.config.globalProperties.store.clear();

      // unsubscribe from Firebase updates
      app.config.globalProperties.fb.unsubscribeAll();
    },

    /**
     * Events are emitted in this order:
     * loading
     * clearLocalState
     * gradeLevelChange
     * schoolChanged
     * firebase subscribe events (async):
     *   noteDataReceived
     *   tagDataReceived
     *   studentDataReceived
     *
     * @param {grade}     type? grade to change to.
     * @param {schoolKey} type? determines which school is active.
     */
    async resubscribe({ grade, schoolKey }) {
      //yes we want to send out a loading event first
      //immediately give user impression that data is changing because it is.
      app.config.globalProperties.ee.emit("loading");

      this.clearAndUnsubscribe();

      // set grade
      app.config.globalProperties.store.setGradeLevel(grade);

      // set school
      app.config.globalProperties.store.setSchool(schoolKey);

      // school meta data
      const meta = await app.config.globalProperties.fb.schools.getMetaData();
      app.config.globalProperties.store.setSchoolMetaData(meta);

      // set current quarter - read from school meta so call after setting that
      app.config.globalProperties.store.setCurrentQuarter(
        meta.currentMarkingPeriod
      );

      const loadSDC = [
        "superuser",
        "calendarManager",
        "riseStaff",
        "schoolAdmin",
        "leadCounselor",
        "OTCManager",
        "OTC",
      ].some((role) => app.config.globalProperties.$user.roles.includes(role));
      if (loadSDC) {
        // get calendar data
        const calendarEvents =
          await app.config.globalProperties.fb.calendar.getAll();
        app.config.globalProperties.store.setCalendarEvents(calendarEvents);
      }

      // subscribe to Firebase updates
      app.config.globalProperties.fb.subscribe();
    },
  };
};

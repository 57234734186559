/** Notification that pops up in the lower right hand corner of the screen and
then disappears. */
<template>
  <div class="message-container">
    <div class="notification-message" :class="dynamicClass">
      {{ msg }}
    </div>
  </div>
</template>

<script>
export default {
  name: "Notification",
  props: {
    msg: String,
  },
  data() {
    return {
      dynamicClass: "hidden",
    };
  },
  methods: {
    show() {
      this.dynamicClass = "visible";
      setTimeout(() => {
        this.dynamicClass = "hidden";
      }, 3000);
    },
  },
};
</script>

<style lang="scss">
.hidden {
  display: none;
}

.visible {
  display: block;
}

.notification-message {
  font-family: "Lato", sans-serif;
  position: fixed;
  z-index: 1;
  right: 50px;
  bottom: 50px;
  padding: 10px;
  border-radius: 4px;

  background-color: var(--color-success);
  color: var(--color-bg);

  -webkit-animation-name: slideup;
  -webkit-animation-duration: 3s;
  animation-name: slideup;
  animation-duration: 3s;
}

/* Add Animation */
@-webkit-keyframes slideup {
  0% {
    bottom: -300px;
    opacity: 0;
  }
  10% {
    bottom: 50px;
    opacity: 1;
  }
  90% {
    bottom: 50px;
    opacity: 1;
  }
  100% {
    bottom: 50px;
    opacity: 0;
  }
}

@keyframes slideup {
  0% {
    bottom: -300px;
    opacity: 0;
  }
  10% {
    bottom: 50px;
    opacity: 1;
  }
  90% {
    bottom: 50px;
    opacity: 1;
  }
  100% {
    bottom: 50px;
    opacity: 0;
  }
}
</style>

/** This is the Custom Charts view which lets a user create their own reports */
<template>
  <div id="summary-app">
    <Modal
      ref="addChart"
      title="Add new chart"
      instructions="Enter name of the custom chart"
      @success="addChart"
    >
      <input
        class="spaced-input"
        v-model="modals.addChartName"
        placeholder="Type here"
      />
    </Modal>
    <Modal
      ref="renameChart"
      title="Rename chart"
      instructions="Enter name of the custom chart"
      @success="renameChart"
    >
      <input
        class="spaced-input"
        v-model="modals.renameChart"
        placeholder="Type here"
      />
    </Modal>
    <Sidebar>
      <div class="sidebar-section">
        <div class="sidebar-section-title">Summary Charts</div>
        <div class="sidebar-section-padded">
          <CustomSelect
            :options="summaryChartsByCurrentGrade"
            title=""
            v-model="selectedChart"
            data-cy="analytics-summary-charts-select"
          />
        </div>
      </div>
      <div class="sidebar-section">
        <div class="sidebar-section-title">Saved Charts</div>
        <div class="sidebar-section-padded">
          <CustomSelect
            style="display: inline-block"
            :customWidth="185"
            :options="savedChartsOptions"
            v-model="selectedSavedChart"
            @mouseover="
              showTooltip(
                'Choose one of your saved charts from the dropdown menu.'
              )
            "
            @mouseleave="showTooltip('')"
          />
          <Popout>
            <div
              @click="this.$refs.addChart.show()"
              @mouseover="
                showTooltip(
                  'Save the current chart so you can return to it later'
                )
              "
              @mouseleave="showTooltip('')"
            >
              Save Current Chart
            </div>
            <div
              v-if="showingCustomChart"
              @click="this.$refs.renameChart.show()"
              @mouseover="showTooltip('Rename the current chart.')"
              @mouseleave="showTooltip('')"
            >
              Rename Chart
            </div>
            <div
              class="warning"
              v-if="showingCustomChart"
              @click="deleteChart()"
              @mouseover="
                showTooltip('Delete the current chart from this dropdown menu')
              "
              @mouseleave="showTooltip('')"
            >
              Delete Chart
            </div>
          </Popout>
        </div>
      </div>
      <div class="sidebar-section">
        <div class="sidebar-section-title">Display Options</div>
        <div class="sidebar-section-padded">
          <CustomSelect
            data-cy="analytics-toggle-chart-view"
            :options="modes"
            title="Show"
            v-model="mode"
          />
          <CustomSelect
            data-cy="analytics-format-options"
            :options="formatOptions"
            title="Number Format"
            v-model="selectedFormatOption"
          />
        </div>
        <div class="popout-download">
          <CustomButton button-text="Download" @click="downloadTable()" />
          <div v-if="mode !== 'Table'">
            <div class="download-content">
              <div
                @click="downloadPDF()"
                @mouseover="showTooltip('Download the chart as a PDF')"
                @mouseleave="showTooltip('')"
              >
                PDF
              </div>
              <div
                @click="downloadImage()"
                @mouseover="showTooltip('Download the chart as an image')"
                @mouseleave="showTooltip('')"
              >
                Image
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="sidebar-section">
        <div class="sidebar-section-title">Select Fields</div>
        <div class="sidebar-section-padded">
          <CustomSelect
            data-cy="analytics-row-grouping"
            :options="discreteFields"
            :title="getPrimaryFieldTitle(mode)"
            v-model="inputs.rowGroup"
          />
          <CustomSelect
            data-cy="analytics-row-sub-grouping"
            v-show="mode == 'Table'"
            :options="discreteFieldsNullable"
            title="Row Sub-grouping (optional)"
            v-model="inputs.rowSubGroup"
          />
          <CustomSelect
            data-cy="analytics-column-grouping"
            v-show="mode !== 'Pie Chart'"
            :options="discreteFields"
            :title="mode == 'Table' ? 'Column Grouping' : 'Color Grouping'"
            v-model="inputs.columnGroup"
          />
        </div>
        <div>
          <CustomButton
            v-show="mode !== 'Pie Chart'"
            :button-text="
              mode == 'Table'
                ? 'Swap Row and Column Groupings'
                : 'Swap Bar and Color Groupings'
            "
            @click="swapRowAndColumn"
            @mouseover="
              showTooltip(
                mode == 'Table'
                  ? 'Swap the row and column groupings'
                  : 'Swap the bar and color groupings'
              )
            "
            @mouseleave="showTooltip('')"
          />
        </div>
      </div>
      <Filters ref="filters" @filtersChanged="setFilters" />
      <hr class="sidebar-division" />
      <Info />
    </Sidebar>
    <div class="main-area-narrow main-area-indented">
      <Transition>
        <ChartLoader v-show="loading" />
      </Transition>
      <Transition>
        <div v-show="!loading">
          <div class="scroll-area" v-show="mode == 'Table'">
            <h2 class="tableheading">{{ getHeading() }}</h2>
            <MyTable
              data-cy="analytics-table"
              :tableData="summary.data"
              :columns="summary.columns"
              :customConfig="summaryTableConfig"
              :showAsInlineTable="true"
              ref="summaryTable"
            />
          </div>
          <div v-show="mode.includes('Chart')">
            <MyChart
              v-if="mode !== 'Pie Chart'"
              data-cy="analytics-chart"
              :chartData="chartData"
              :chartConfig="chartConfig"
              ref="summaryChart"
            />
            <div style="max-width: 600px">
              <MyChart
                v-if="mode === 'Pie Chart'"
                :chartData="chartData"
                :chartConfig="chartConfig"
                ref="summaryPieChart"
              />
            </div>
          </div>
        </div>
      </Transition>
    </div>
  </div>
</template>

<script>
import { setCharts } from "@/apps/setCharts.js";
import MyChart from "@/components/charts.vue";
import CustomButton from "@/components/customButton.vue";
import CustomSelect from "@/components/customSelect.vue";
import Filters from "@/components/filters.vue";
import MyTable from "@/components/table.vue";
import ChartLoader from "@/components/loaders/chartLoader.vue";
import Info from "@/components/nav/info.vue";
import Sidebar from "@/components/nav/sidebar.vue";
import Popout from "@/components/tracker/popout.vue";
import Modal from "@/components/modals/modal.vue";
import {
  getSummaryDataAndColumns,
  getRedirectCustomPropFromChart,
  getRedirectCustomProp,
} from "@/functions/summary.js";
import { addTagFields, addTagValues } from "@/functions/tagFunctions.js";
import { sanitizeFileName } from "@/functions/utils.js";

// PDFMake library
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

export default {
  name: "Analytics",
  components: {
    MyTable,
    CustomButton,
    CustomSelect,
    Sidebar,
    MyChart,
    Filters,
    Info,
    ChartLoader,
    Popout,
    Modal,
  },
  data() {
    return {
      summary: getSummaryDataAndColumns({
        studentData: this.store.state.studentData,
        postSecTags: this.store.state.postSecTags,
        freshSuccessTags: this.store.state.freshSuccessTags,
        fields: this.config.fields,
        rowKey:
          this.config.gradeConfig[this.store.state.showingGradeLevel]
            .analyticsConfig.rowGroup,
        columnKey:
          this.config.gradeConfig[this.store.state.showingGradeLevel]
            .analyticsConfig.columnGroup,
        selectedFormatOption: "Show count",
        showSumRow: true,
      }),
      filters: [],
      inputs: {
        rowGroup: this.config.fields.find(
          (element) =>
            element.key ==
            this.config.gradeConfig[this.store.state.showingGradeLevel]
              .analyticsConfig.rowGroup
        ).displayName,
        rowSubGroup: "None",
        columnGroup: this.config.fields.find(
          (element) =>
            element.key ==
            this.config.gradeConfig[this.store.state.showingGradeLevel]
              .analyticsConfig.columnGroup
        ).displayName,
      },
      discreteFieldsNullable: this.getDiscreteFieldsNullable(),
      discreteFields: this.getDiscreteFields(),
      summaryTableConfig: {
        height: "100%",
        cellDblClick: (e, cell) => {
          this.redirectToTracker(cell);
        },
      },
      mode: "Stacked Bar Chart",
      modes: [
        { displayName: "Table" },
        { displayName: "Pie Chart" },
        { displayName: "Bar Chart" },
        { displayName: "Stacked Bar Chart" },
      ],
      selectedFormatOption: "Show count",
      formatOptions: [
        { displayName: "Show count" },
        { displayName: "Show percentage" },
        { displayName: "Show count and percentage" },
      ],
      loading: true,
      chartData: this.addTagValues({
        data: this.store.state.studentData,
        postSecTags: this.store.state.postSecTags,
        freshSuccessTags: this.store.state.freshSuccessTags,
      }),
      chartFields: this.addTagFields({
        fields: this.config.fields,
        postSecTags: this.store.state.postSecTags,
        freshSuccessTags: this.store.state.freshSuccessTags,
      }),
      selectedChart: "",
      studentDataLoaded: false,
      //grade level is already set when app is initially loaded
      gradeLevelLoaded: true,
      tagDataLoaded: false,
      savedCharts: [], // loaded from user preferences when mounted
      modals: {
        addChartName: "",
        renameChart: "",
      },
      selectedSavedChart: "",
    };
  },
  inject: ["posthog"],
  watch: {
    inputs: {
      deep: true,
      handler() {
        this.refreshDisplay();
      },
    },
    selectedFormatOption: function () {
      // Instead of just refreshing the summary tables, we need to refresh everything.
      this.refreshDisplay();
    },
    selectedChart: function () {
      if (this.selectedChart) {
        const mySetChart = this.summaryChartsByCurrentGrade.find(
          (e) => e.displayName === this.selectedChart
        );
        this.inputs.rowGroup = this.config.fields.find(
          (field) => field.key === mySetChart.rowGroup
        ).displayName;
        if (mySetChart.mode === "Table") {
          this.inputs.rowSubGroup = this.config.fields.find(
            (field) => field.key === mySetChart.rowSubGroup
          ).displayName;
        }
        this.inputs.columnGroup = this.config.fields.find(
          (field) => field.key === mySetChart.columnGroup
        ).displayName;
        this.selectedFormatOption = "Show percentage";
        this.mode = mySetChart.mode;
        this.clearFilters();
        if (mySetChart.filters.length) {
          this.addFilter({ filterArr: mySetChart.filters });
        }
      }
      this.selectedSavedChart = "";
    },
    selectedSavedChart: function () {
      if (this.selectedSavedChart) {
        const mySavedChart = this.savedCharts.find(
          (e) => e.displayName === this.selectedSavedChart
        );
        this.inputs.rowGroup = mySavedChart.rowGroup;
        this.inputs.columnGroup = mySavedChart.columnGroup;
        if (mySavedChart.mode === "Table") {
          this.inputs.rowSubGroup = mySavedChart.rowSubGroup;
        }
        this.selectedFormatOption = mySavedChart.formatOption;
        this.mode = mySavedChart.mode;
        this.clearFilters();
        this.addFilter({ filterArr: mySavedChart.filters });
      }
      this.selectedChart = "";
    },
  },
  methods: {
    addTagFields,
    addTagValues,
    setFilters(filters) {
      this.filters = filters;
      this.refreshDisplay();
    },
    setEventListeners() {
      [
        {
          name: "studentDataReceived",
          fn: (dataType) => this.refreshDisplay(dataType),
        },
        {
          name: "gradeLevelChange",
          fn: (dataType) => this.refreshDisplay(dataType),
        },
        {
          name: "tagDataReceived",
          fn: (dataType) => this.refreshDisplay(dataType),
        },
        { name: "loading", fn: () => this.showLoader() },
      ].forEach((event) => {
        this.ee.on(event.name, event.fn);
      });
    },
    redirectToTracker(cell) {
      // navigate to tracker page and show students that match current filters

      // if user double-clicked on category column(s), ignore
      if (cell._cell.column.field == this.summaryKeys.rowGroup) return;
      if (cell._cell.column.field == this.summaryKeys.rowSubGroup) return;

      const myProp = getRedirectCustomProp({
        rowKey: this.summaryKeys.rowGroup,
        rowSubKey: this.summaryKeys.rowSubGroup,
        columnKey: this.summaryKeys.columnGroup,
        fields: this.config.fields,
        rowValue: cell._cell.row.cells[0].value,
        rowSubValue: cell._cell.row.cells[1].value,
        columnValue: cell._cell.column.field,
        redirectDefaultFields:
          this.config.gradeConfig[this.store.state.showingGradeLevel]
            .analyticsConfig.redirectDefaultFields,
        sidebarFilters: this.filters,
      });

      if (!myProp) return;

      this.store.setCustomProp(myProp);

      this.$router.push("/tracker");
    },
    renderSummaryTable() {
      if (this.$route.name !== "Custom Charts") return;
      if (!this.$refs.summaryTable) return;
      // if (this.loading) return;

      this.summary = getSummaryDataAndColumns({
        studentData: this.store.state.studentData,
        postSecTags: this.store.state.postSecTags,
        freshSuccessTags: this.store.state.freshSuccessTags,
        fields: this.config.fields,
        rowKey: this.summaryKeys.rowGroup,
        rowSubKey: this.summaryKeys.rowSubGroup,
        columnKey: this.summaryKeys.columnGroup,
        selectedFormatOption: this.selectedFormatOption,
        showSumRow: true,
        filters: this.filters,
      });

      this.$refs.summaryTable.doMethod("setColumns", this.summary.columns);
      this.$refs.summaryTable.doMethod("setData", this.summary.data);
    },
    refreshDisplay(dataType) {
      this.discreteFieldsNullable = this.getDiscreteFieldsNullable();
      this.discreteFields = this.getDiscreteFields();
      this.renderSummaryTable();
      this.refreshChart();

      if (dataType) this[dataType] = true;
      if (this.studentDataLoaded && this.gradeLevelLoaded && this.tagDataLoaded)
        this.loading = false;
    },
    refreshChart() {
      if (this.$route.name !== "Custom Charts") return;

      this.chartData = this.addTagValues({
        data: this.store.state.studentData,
        postSecTags: this.store.state.postSecTags,
        freshSuccessTags: this.store.state.freshSuccessTags,
      });
      this.chartFields = this.addTagFields({
        fields: this.config.fields,
        postSecTags: this.store.state.postSecTags,
        freshSuccessTags: this.store.state.freshSuccessTags,
      });
    },
    getHeading() {
      return this.inputs.rowSubGroup === "None"
        ? `${this.inputs.rowGroup} and ${this.inputs.columnGroup}`
        : `${this.inputs.rowGroup}, ${this.inputs.rowSubGroup} and ${this.inputs.columnGroup}`;
    },
    getDiscreteFields() {
      let result = [...this.config.fields];

      result = result
        .filter(
          (element) =>
            element.type === "numeric" ||
            element.type === "boolean" ||
            element.type === "category"
        )
        .filter((element) => !element.hideInFilters)
        .filter((element) => !element.hideInAnalytics)
        .filter((element) =>
          element.showInGrades.includes(this.store.state.showingGradeLevel)
        );

      if (!this.$user.hasPower("viewDataByCounselor")) {
        result = result.filter((element) => element.key !== "counselorName");
      }

      // add postseconary tags
      if (this.store.state.showingGradeLevel >= 11) {
        if (this.config.fields.find((field) => field.key === "postSecTags")) {
          this.store.state.postSecTags.forEach((tag) => {
            result.push({
              displayName: `Tag: ${tag.title}`,
            });
          });
        }
      }

      if (
        this.config.fields.find((field) => field.key === "freshSuccessTags")
      ) {
        this.store.state.freshSuccessTags.forEach((tag) => {
          result.push({
            displayName: `Tag: ${tag.title}`,
          });
        });
      }

      return result.sort((a, b) => (a.displayName > b.displayName ? 1 : -1));
    },
    getDiscreteFieldsNullable() {
      return [
        {
          displayName: "None",
        },
      ].concat(this.getDiscreteFields());
    },
    getFieldKey(displayName) {
      if (displayName.slice(0, 4) === "Tag:") {
        return displayName;
      }
      return this.config.fields.find(
        (field) => field.displayName == displayName
      ).key;
    },
    chartClickHandler({ filters }) {
      const myFilters = filters.filter((f) => f !== undefined);
      this.store.setCustomProp(
        getRedirectCustomPropFromChart({
          filters: myFilters,
          sidebarFilters: this.filters,
          redirectDefaultFields:
            this.config.gradeConfig[this.store.state.showingGradeLevel]
              .analyticsConfig.redirectDefaultFields,
          fields: this.config.fields,
        })
      );

      this.$router.push("/tracker");
    },
    getPrimaryFieldTitle(mode) {
      return mode === "Table"
        ? "Row Grouping"
        : mode === "Pie Chart"
        ? "Color Grouping"
        : "Bar Grouping";
    },
    showLoader() {
      this.studentDataLoaded = false;
      this.gradeLevelLoaded = false;
      this.tagDataLoaded = false;
      this.loading = true;
    },
    // Swaps the inputs for row and column grouping for tables or bar and color groupings for bar charts and stacked bar charts
    swapRowAndColumn() {
      // Use a placeholder variable to keep the value of this.inputs.rowGroup
      let temp;
      temp = this.inputs.rowGroup;
      this.inputs.rowGroup = this.inputs.columnGroup;
      this.inputs.columnGroup = temp;
    },
    // Saving a new chart to our savedCharts array and to Firestore
    addChart() {
      this.savedCharts.push({
        displayName: this.modals.addChartName,
        rowGroup: this.inputs.rowGroup,
        rowSubGroup: this.inputs.rowSubGroup,
        columnGroup: this.inputs.columnGroup,
        mode: this.mode,
        formatOption: this.selectedFormatOption,
        filters: this.filters,
      });

      this.fb.user.updatePreference("customCharts", this.savedCharts);

      this.selectedSavedChart = this.modals.addChartName;
    },
    renameChart() {
      const name = this.modals.renameChart;
      const oldName = this.selectedSavedChart;

      const myChart = this.savedCharts.find(
        (chart) => chart.displayName == oldName
      );

      myChart.displayName = name;

      this.fb.user.updatePreference("customCharts", this.savedCharts);

      this.selectedSavedChart = this.modals.renameChart;
    },
    deleteChart() {
      const currentChart = this.savedCharts.find(
        (chart) => chart.displayName === this.selectedSavedChart
      );
      const saveObj = this.savedCharts.filter(
        (chart) => chart !== currentChart
      );

      this.savedCharts = saveObj;

      this.fb.user.updatePreference("customCharts", saveObj);
    },
    addFilter({ filterArr }) {
      if (this.$refs.filters) {
        this.$refs.filters.addFilter({ filterArr });
      }
    },
    clearFilters() {
      this.setFilters([]);
      if (this.$refs.filters) {
        this.$refs.filters.clearFilters();
      }
    },
    // download current chart as a png image
    downloadImage() {
      let chartPNG;
      if (this.mode === "Pie Chart") {
        chartPNG = this.$refs["summaryPieChart"].chart.toBase64Image();
      } else {
        chartPNG = this.$refs["summaryChart"].chart.toBase64Image();
      }

      const link = document.createElement("a");
      link.href = chartPNG;

      link.download =
        this.mode === "Pie Chart"
          ? sanitizeFileName(`${this.inputs.rowGroup}_Pie_Chart`)
          : sanitizeFileName(
              `${this.inputs.rowGroup}_and_${this.inputs.columnGroup}_Chart`
            );

      document.body.appendChild(link);

      link.click();

      document.body.removeChild(link);
    },
    // download current chart as a pdf
    downloadPDF() {
      let chartPNG;
      if (this.mode === "Pie Chart") {
        chartPNG = this.$refs["summaryPieChart"].chart.toBase64Image();
      } else {
        chartPNG = this.$refs["summaryChart"].chart.toBase64Image();
      }

      const docDefinition = {
        content: [{ image: chartPNG, maxWidth: 500 }],
      };

      const fileName =
        this.mode === "Pie Chart"
          ? sanitizeFileName(`${this.inputs.rowGroup}_Pie_Chart`)
          : sanitizeFileName(
              `${this.inputs.rowGroup}_and_${this.inputs.columnGroup}_Chart`
            );

      pdfMake.createPdf(docDefinition).download(fileName);
    },
    downloadTable() {
      if (this.mode === "Table") {
        this.$refs["summaryTable"].doMethod(
          "download",
          "xlsx",
          "tableData.xlsx",
          { sheetName: "Table Download Data" }
        );
      }
    },
  },
  mounted() {
    this.setEventListeners();
    // Apply user's saved custom charts
    if (this.$user.preferences) {
      this.savedCharts = this.$user.preferences.customCharts;
    }

    if (this.store.state.studentData && this.store.state.postSecTags) {
      this.studentDataLoaded = true;
      this.tagDataLoaded = true;
      this.refreshDisplay();
    }
  },
  activated() {
    this.discreteFieldsNullable = this.getDiscreteFieldsNullable();
    this.discreteFields = this.getDiscreteFields();
    this.refreshDisplay();

    // Send pageview to posthog
    this.posthog.capture("$pageview", {
      $current_url: "/analytics",
    });
  },
  computed: {
    summaryKeys: function () {
      return {
        rowGroup: this.getFieldKey(this.inputs.rowGroup),
        rowSubGroup:
          this.inputs.rowSubGroup === "None"
            ? null
            : this.getFieldKey(this.inputs.rowSubGroup),
        columnGroup: this.getFieldKey(this.inputs.columnGroup),
      };
    },
    showAsPercentage: function () {
      return this.selectedFormatOption === "Show percentage";
    },
    chartConfig: function () {
      const type = this.mode === "Pie Chart" ? "analyticsPie" : "grouped";
      const title =
        this.mode === "Pie Chart"
          ? this.inputs.rowGroup
          : `${this.inputs.rowGroup} and ${this.inputs.columnGroup}`;

      return {
        type,
        title,
        primaryField: this.chartFields.find(
          (field) => field.key == this.summaryKeys.rowGroup
        ),
        secondaryField: this.chartFields.find(
          (field) => field.key == this.summaryKeys.columnGroup
        ),
        stacked: this.mode === "Stacked Bar Chart",
        showAsPercentage: this.showAsPercentage,
        selectedFormatOption: this.selectedFormatOption,
        onClick: this.chartClickHandler,
        filters: this.filters,
      };
    },
    // Determines if a user is looking at a saved chart
    showingCustomChart: function () {
      if (this.selectedSavedChart === "") return false;
      const currentSavedChart = this.savedCharts.find(
        (chart) => chart.displayName == this.selectedSavedChart
      );
      if (!currentSavedChart) return false;
      return true;
    },
    savedChartsOptions: function () {
      const options = [...this.savedCharts];
      options.unshift({ displayName: "" });
      return options;
    },
    summaryChartsByCurrentGrade: function () {
      const summaryCharts = setCharts(this.store.state.currentQuarter);
      let summaryChartsCurrentGrade = [];
      summaryCharts.forEach((chart) => {
        if (chart.showInGrades.includes(this.store.state.showingGradeLevel)) {
          summaryChartsCurrentGrade.push(chart);
        }
      });

      return summaryChartsCurrentGrade;
    },
  },
};
</script>

<style lang="scss">
.tableheading {
  margin-bottom: 2rem;
  margin-top: 0;
}

.download-content {
  display: none;
  position: absolute;
  margin-left: 4px;
  background-color: var(--color-contrast-lower);
  min-width: 120px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  div {
    padding: 12px 16px;
    display: block;
    &:hover {
      background-color: var(--color-primary);
      color: var(--color-bg);
      cursor: pointer;
    }
  }
}

.popout-download:hover .download-content {
  display: block;
}
</style>

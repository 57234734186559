/** App selector is used on the home page and navbar for choosing the view. */
<template>
  <table class="tool-list" data-cy="tool-list">
    <tr
      class="tool-row"
      v-for="(route, index) in routes"
      :key="index"
      @click="this.$emit('launchTool', route)"
    >
      <td class="tool-icon-cell">
        <span class="material-icons tool-icon">
          {{ route.meta.icon }}
        </span>
      </td>
      <td class="tool-title" data-cy="tool-title">{{ route.name }}</td>
      <td class="tool-description">{{ route.userDescription }}</td>
    </tr>
  </table>
</template>

<script>
export default {
  name: "App Selector",
  props: {
    routes: Object,
  },
  emits: ["launchTool"],
};
</script>

<style lang="scss">
.tool-list {
  margin: 1rem 0 0;
  width: 100%;
  border-collapse: collapse;
  text-align: left;
  .tool-row:hover {
    background-color: var(--color-contrast-low);
    cursor: pointer;
  }
  .tool-icon {
    //all icons should be 24px. gradually decreasing
    font-size: 32px;
    color: var(--color-primary);
  }
  .tool-title {
    font-weight: bold;
  }
  td {
    padding: 0.2rem 0.5rem;
  }
}
</style>

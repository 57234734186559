/** In the student profile, this is a band that holds a number of fields. */
<template>
  <div class="field-area">
    <div class="field-area-title">
      {{ title }}
    </div>
    <div class="box-area">
      <div
        v-for="(field, index) in myFields"
        :key="index"
        class="field-box"
        :class="getBoxClass({ student, field })"
        @click="launchEdit({ student, field })"
        @mouseover="showTooltip(field.userDescription || '')"
        @mouseleave="showTooltip('')"
      >
        <div class="field-box-title">
          {{ field.displayName }}
        </div>
        <div class="field-box-value" :class="tall ? 'tall' : ''">
          {{ val({ student, field }) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getValueFromObject,
  getDisplayValue,
  getDisplayClass,
} from "@/functions/utils.js";

export default {
  name: "Field Area",
  props: {
    fields: Array,
    student: Object,
    title: {
      type: String,
      required: false,
      default: "",
    },
    tall: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    myFields: function () {
      return this.fields
        .map((f) => this.config.fields.find((field) => field.key == f))
        .filter((f) => f !== undefined);
    },
  },
  methods: {
    getProfileDisplayValue({ field, value }) {
      if (!field.profileFormatter) return value;
      return field.profileFormatter(value);
    },
    val({ student, field }) {
      const value = getValueFromObject(student, field.key);
      const raw = getDisplayValue({ value, field });
      const result = this.getProfileDisplayValue({ value: raw, field });
      return result;
    },
    getBoxClass({ student, field }) {
      let result = getDisplayClass({ student, field });
      if (!field.readOnly && this.$user.hasPower("updateStudents"))
        result += " editable";
      if (this.tall) result += " tall";
      return result;
    },
    launchEdit({ student, field }) {
      if (field.readOnly) return;
      if (!this.$user.hasPower("updateStudents")) return;
      const value = getValueFromObject(student, field.key);
      this.ee.emit("launchEditField", {
        key: field.key,
        currentValue: value,
        id: student.studentRISEId,
        studentName: student._studentName,
      });
    },
  },
};
</script>

<style lang="scss">
.field-area {
  background-color: var(--color-contrast-low);
  padding: 1rem;
}
.box-area {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.field-box {
  background-color: var(--color-bg);
  border-radius: 5px;
  width: 14rem;
  text-align: center;
  margin: 5px;
  height: 10rem;
  color: var(--color-primary);
  padding: 5px;
  box-sizing: border-box;

  &.editable:hover {
    box-shadow: 0px 0px 10px var(--color-contrast-high);
    cursor: pointer;
  }
  &.alert {
    background-color: var(--color-alert);
    color: var(--color-bg);
  }
  &.warning {
    background-color: var(--color-warning);
    color: var(--color-bg);
  }
  &.info {
    background-color: var(--color-info);
    color: var(--color-contrast-full);
  }
  &.success {
    background-color: var(--color-success);
    color: var(--color-bg);
  }
  &.gradeB {
    background-color: var(--color-grade-b);
    color: var(--color-contrast-full);
  }
  &.dataMissing {
    background-color: var(--color-contrast-lower);
    color: var(--color-contrast-medium);
  }
  .field-box-title {
    text-transform: uppercase;
    font-weight: bold;
    height: 3rem;
    font-size: 1.3rem;
  }
  .field-box-value {
    font-weight: bold;
    font-size: 1.7rem;
    position: relative;
    top: 5px;
    &.tall {
      font-size: 1.5rem;
    }
  }
}
</style>

/**Custom Button component*/

<template>
  <button class="custom-button">{{ buttonText }}</button>
</template>

<script>
export default {
  props: {
    buttonText: String,
  },
};
</script>

<style lang="scss">
@import "../assets/styles/colors.scss";

.custom-button {
  background-color: var(--color-contrast-lower);
  color: var(--color-primary);
  &:hover {
    background-color: var(--color-primary);
    color: var(--color-bg);
    border-color: var(--color-primary);
  }
  font-family: "Lato", sans-serif;
  font-size: 14px;
  border-radius: 8px;
  border: solid;
  border-width: 2px;
  margin: 5px;
  padding: 6px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  transition-duration: 0.3s;
  cursor: pointer;
}

.btn-warning {
  background-color: var(--color-warning);
  color: var(--color-bg);
  &:hover {
    background-color: var(--color-warning-light);
  }
}

.btn-success {
  background-color: var(--color-success);
  color: var(--color-bg);
  &:hover {
    background-color: var(--color-success-light);
  }
}

.btn-disabled {
  color: var(--color-contrast-medium);
  background-color: var(--color-contrast-low);
  &:hover {
    cursor: not-allowed;
    color: var(--color-contrast-medium);
    background-color: var(--color-contrast-low);
  }
}

.learn-more-button {
  font-size: 1.6rem;
  padding: 5px 10px;
  background-color: var(--color-primary-light);
  color: var(--color-bg);
  border-color: var(--color-contrast-low);
  &:hover {
    color: var(--color-contrast-full);
    background-color: var(--color-contrast-lower);
  }
}
</style>

/**
 * This contains the functions for building the config file so the PDFMake library can create an On Track Report PDF.
 */

// Import required libraries and utilities
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { currentYear, getCurrentFormattedDate } from "@/functions/utils.js";

// Import custom font files for the PDF
import { Bold } from "../assets/NotoSansJP-Bold.js";
import { Regular } from "../assets/NotoSansJP-Regular.js";
import { SemiBold } from "../assets/NotoSansJP-SemiBold.js";
import { ON_TRACKPDF_SVG_ICONS as SVG_ICONS } from "./utils-svgIcons.js";

const get = {};

// Define the virtual font storage for the custom fonts
pdfMake.vfs = pdfFonts.pdfMake.vfs;
pdfMake.vfs["NotoSansJP-Bold.ttf"] = Bold;
pdfMake.vfs["NotoSansJP-Regular.ttf"] = Regular;
pdfMake.vfs["NotoSansJP-SemiBold.ttf"] = SemiBold;

// Define custom fonts for use in the PDF
pdfMake.fonts = {
  NotoSansJP: {
    normal: "NotoSansJP-Regular.ttf",
    italics: "NotoSansJP-Bold.ttf",
    bold: "NotoSansJP-SemiBold.ttf",
  },
  Roboto: {
    normal:
      "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf",
    bold: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf",
  },
};

// Define font size constants for various text elements in the PDF
const FONT_SIZE = {
  normal: 11.0175,
  header: 14.625,
  subheader: 12.855,
  small: 10.95,
};

// Utility function to truncate a string if it exceeds the maximum length
const truncateString = (str, maxLength) => {
  if (str.length > maxLength) {
    return str.slice(0, maxLength) + "...";
  }
  return str;
};

const sections = [
  { title: "Goals" },
  { title: "Next Steps" },
  { title: "Note for Counselor" },
];

// Utility function to determine the On Track status of the student
const onTrackStatus = (status) => {
  if (["on-track", "off-track", "almost on-track"].includes(status)) {
    return status.toUpperCase(); // Return status in uppercase if valid
  }
  return status == null || status === "" ? "No Data" : "No Data";
};

// Utility function to get the color associated with a given On Track status
const getTrackStatusColor = (status) => {
  const statusColors = {
    "on-track": "#008000", // Green color for on-track
    "off-track": "#B32A1E", // Red color for off-track
    "almost on-track": "#ffc107", // Yellow color for almost on-track
  };

  if (status && statusColors[status.toLowerCase()]) {
    return statusColors[status.toLowerCase()];
  }

  return "#707070";
};

// Function to generate table content dynamically based on the report sections
const createDynamicTableContent = (sections) => {
  const tableBody = [
    sections.map((section) => ({
      stack: [
        {
          text: section.title,
          alignment: "center",
          fontSize: FONT_SIZE.header,
          bold: true,
        },
        {
          canvas: [
            {
              type: "rect", // Rectangle shape for the section box
              x: 0,
              y: 0,
              w: 178,
              h: 101,
              r: 8,
              color: "#ffffff", // White background
              lineWidth: 1,
              strokeColor: "#000000", // Black border
              lineColor: "black",
              fillColor: null, // No fill color inside the rectangle
            },
          ],
          margin: [0, 5, 0, 0],
        },
      ],
      alignment: "center",
    })),
  ];

  return {
    alignment: "center",
    table: {
      widths: ["*", "*", "*"], // Define equal column widths
      body: tableBody,
    },
    layout: "noBorders",
  };
};

const markingPeriods = ["Q1", "Q2", "Q3", "Q4", "S1", "S2"];

// change formate of currentYear '2425' -> '2024-25'
const yearRange = `20${String(currentYear)?.slice(0, 2)}-${String(
  currentYear
)?.slice(2)}`;

// Function to get the list of courses for a given student
get.Courses = (student) => {
  const courseKeys = student.courses ? Object.keys(student.courses) : []; // Get all course keys

  const myCourses = courseKeys
    .map((key) => student.courses[key]) // Map each course key to its course data
    .filter((course) => course !== undefined) // Filter out undefined courses
    .filter((course) => course.schoolYear == currentYear) // Filter courses for the current year
    .sort((a, b) => (a.period > b.period ? 1 : -1)) // Sort courses by period
    .map((course, index) => {
      const fillColor = index % 2 !== 0 ? "#f2f2f2" : "#ffffff"; // Alternate row colors

      return [
        { text: truncateString(course.courseTitle, 21), fillColor }, // Truncated course title
        { text: truncateString(course.teacher.lastName, 10), fillColor }, // Truncated teacher's last name
        { text: course.creditHours, fillColor, alignment: "center" }, // Course credit hours
        ...markingPeriods.map((period) => {
          if (!course.letterGrade[period])
            return { text: "", fillColor, alignment: "center" }; // No grade for the period
          return {
            text: course.percentageGrade[period]?.toFixed(), // Percentage grade for the period
            fillColor,
            alignment: "center",
          };
        }),
        {
          text: course.currentAbsences.toString(),
          fillColor,
          alignment: "center",
        },
        {
          text: course.currentTardies.toString(),
          fillColor,
          alignment: "center",
        },
      ];
    });

  // Define Table header Data
  const headerRow = [
    {
      text: "Courses",
      bold: true,
      fontSize: FONT_SIZE.subheader,
      alignment: "left",
    },
    {
      text: "Teacher",
      bold: true,
      fontSize: FONT_SIZE.subheader,
      alignment: "left",
    },
    {
      text: "Credits",
      bold: true,
      fontSize: FONT_SIZE.subheader,
      alignment: "center",
    },
    {
      text: "Q1",
      bold: true,
      fontSize: FONT_SIZE.subheader,
      alignment: "center",
    },
    {
      text: "Q2",
      bold: true,
      fontSize: FONT_SIZE.subheader,
      alignment: "center",
    },
    {
      text: "Q3",
      bold: true,
      fontSize: FONT_SIZE.subheader,
      alignment: "center",
    },
    {
      text: "Q4",
      bold: true,
      fontSize: FONT_SIZE.subheader,
      alignment: "center",
    },
    {
      text: "S1",
      bold: true,
      fontSize: FONT_SIZE.subheader,
      alignment: "center",
    },
    {
      text: "S2",
      bold: true,
      fontSize: FONT_SIZE.subheader,
      alignment: "center",
    },
    {
      text: "Abs",
      bold: true,
      fontSize: FONT_SIZE.subheader,
      alignment: "center",
    },
    {
      text: "Tdy",
      bold: true,
      fontSize: FONT_SIZE.subheader,
      alignment: "center",
    },
  ];

  return {
    style: "coursesTable",
    fontSize: FONT_SIZE.normal,
    lineHeight: 0.85,
    color: "#333333",
    margin: [0, 5, 0, 0],
    layout: "noBorders",
    table: {
      headerRows: 1, // First row is the header
      widths: [
        "29%",
        "17%",
        "10%",
        "5%",
        "5%",
        "5%",
        "5%",
        "5%",
        "5%",
        "6%",
        "6%",
      ], // Define column widths
      alignment: "center",
      body: [headerRow, ...myCourses], // Combine header and courses data
    },
  };
};

// Function to generate the PDF based on the student data
export const generatePDF = ({ student, areasToShow, schoolName }) => {
  // Function to generate SVG icons for the On Track status
  const generateSVGIcon = (isOnTrack, onTrackSVG, offTrackSVG) => {
    if (isOnTrack === null) {
      return "";
    }
    return {
      svg: isOnTrack ? onTrackSVG : offTrackSVG,
      margin: [3, 3, 0, 0],
    };
  };

  // Function to create a column of content for the report
  const createColumnContent = (label, value) => {
    return {
      columnGap: "25",
      width: "25%",
      stack: [
        {
          text: !["", null].includes(value) ? value : "-", // Display value or "-" if no value
          fontSize: 15,
          color: "#707070",
          italics: true,
        },
        { text: label, fontSize: 11, color: "#1a1c1e", margin: [5, 0] },
      ],
    };
  };

  // Function to create the  Track status label in the PDF
  const createOnTrackLabel = (
    isOnTrack,
    label,
    hideLabel = false,
    options = {}
  ) => {
    const { fontSize = FONT_SIZE.header } = options;
    if (hideLabel) {
      return {
        text: [{ text: `${label}`, fontSize, bold: true }],
        width: "auto",
      };
    }
    return {
      text: [
        { text: `${label}: `, fontSize, bold: true },
        {
          text: isOnTrack ? "ON-TRACK" : "OFF-TRACK", // Display "ON-TRACK" or "OFF-TRACK"
          fontSize,
          italics: true,
          color: isOnTrack ? "#008000" : "#B32A1E", // Green or Red color based on the status
        },
      ],
      width: "auto",
    };
  };

  // Function to create the student information section in the report
  function createStudentInfo(student, svgimg, yearRange, schoolName) {
    return {
      columns: [
        { svg: svgimg, width: 90, margin: [11, 18, 0, 0] }, // RISE NETWORK logo
        {
          stack: [
            {
              text: [
                {
                  text: `${student?.firstName} ${student?.lastName} `, //Student Name
                  bold: true,
                  fontSize: 19.5,
                },
                { text: "On-Track Report \n", fontSize: 18 },
                {
                  text: `${yearRange} ${schoolName}`, //school Name  & Year
                  fontSize: 14.62,
                  bold: true,
                  color: "#333333",
                },
              ],
              margin: [0, 0, 0, 8],
            },
            {
              columns: [
                [
                  {
                    text: `Grade: ${
                      ["", null].includes(student.currentGradeLevel) // Student Grade
                        ? "No data"
                        : student.currentGradeLevel
                    }`,
                  },
                  {
                    text: `Cohort: ${
                      ["", null].includes(student.cohort) // Student Cohort
                        ? "No data"
                        : student.cohort
                    }`,
                  },
                  { text: `Data From: ${getCurrentFormattedDate()}` },
                ],
                [
                  {
                    text: `Student ID: ${
                      ["", null].includes(student.studentDistrictId) // Student ID
                        ? "No data"
                        : student.studentDistrictId
                    }`,
                  },
                  {
                    text: `Counselor: ${
                      ["", null].includes(student.counselorName) // Counselor
                        ? "No data"
                        : student.counselorName
                    }`,
                  },
                  { text: "Conferencer: ____________" },
                ],
              ],
              alignment: "left",
              color: "#1a1c1e",
              fontSize: 13,
              columnGap: 57,
            },
          ],
          margin: [53, 0, 0, 0],
        },
      ],
      color: "#1A1C1E",
    };
  }

  // Function to create the row showing the On Track status in the report title
  function createOnTrackTitleRow(iconSvg) {
    return {
      columns: [
        {
          text: [
            {
              text: "Courses and Grades: ",
              fontSize: FONT_SIZE.header,
              bold: true,
            },
            {
              text: onTrackStatus(student?.onTrackStatus),
              fontSize: FONT_SIZE.header,
              italics: true,
              color: getTrackStatusColor(student?.onTrackStatus),
            },
          ],
          width: "auto",
        },
        student?.onTrackStatus
          ? {
              svg: `${
                student?.onTrackStatus === "on-track"
                  ? SVG_ICONS.ON_TRACK_SVG
                  : student?.onTrackStatus === "almost on-track"
                  ? SVG_ICONS.ALMOST_ON_TRACK_SVG
                  : SVG_ICONS.OFF_TRACK_SVG
              } `,
              width: 17,
              margin: [5, 2, 5, 0],
            }
          : { text: "", width: 17, margin: [5, 2, 5, 0] },
      ],
      margin: [0, 9, 0, 0],
    };
  }

  const createAttendanceSection = (student) => {
    return {
      columns: [
        {
          width: "35%",
          stack: [
            {
              columns: [
                createOnTrackLabel(
                  student?.ADA?.toFixed() >= 90,
                  "Attendance",
                  ["", null].includes(student?.ADA)
                ),
                !["", null].includes(student?.ADA)
                  ? generateSVGIcon(
                      student?.ADA?.toFixed() >= 90,
                      SVG_ICONS.ON_TRACK_SVG,
                      SVG_ICONS.OFF_TRACK_SVG
                    )
                  : null,
              ],
              width: "auto",
            },
            {
              text: "On-track means having above 90% Average Daily Attendance.",
              fontSize: 11,
              margin: [0, 4, 0, 0],
              color: "#333333",
            },
          ],
        },
        {
          text: "Absences",
          fontSize: 11,
          alignment: "center",
          color: "#1a1c1e",
          width: "13%",
          bold: true,
          margin: [0, 22, 0, 0],
        },
        {
          stack: [
            {
              columns: Array.from({ length: 4 }, (_, i) =>
                createColumnContent(`Q${i + 1}`, student?.[`Q${i + 1}Absences`])
              ),
            },
          ],
          width: "35%",
          alignment: "center",
          layout: "noBorders",
          margin: [0, 14, 0, 0],
        },
        {
          canvas: [
            {
              type: "line",
              x1: 10,
              y1: 0,
              x2: 10,
              y2: 40,
              lineWidth: 1,
              lineColor: "#707070",
              dash: { length: 5, space: 1 },
            },
          ],
          width: "5%",
          alignment: "center",
          margin: [0, 15, 0, 0],
        },
        {
          stack: [
            {
              text: !["", null].includes(student?.ADA)
                ? student?.ADA?.toFixed(1) + " %"
                : "No data",
              fontSize: 15,
              italics: true,
              color: "#707070",
            },
            { text: "Average", fontSize: 12 },
          ],
          alignment: "center",
          width: "12%",
          margin: [0, 15, 0, 0],
        },
      ],
      margin: [0, 5, 0, 0],
    };
  };

  // Behavior section
  const createBehaviorSection = (student) => {
    return {
      columns: [
        {
          width: "35%",
          stack: [
            {
              columns: [
                createOnTrackLabel(
                  student?.suspensions === 0, // On-track if no suspensions
                  "Behavior", // Label text
                  ["", null].includes(student?.suspensions) // Hide label if no suspension data
                ),
                generateSVGIcon(
                  !["", null].includes(student?.suspensions) &&
                    student?.suspensions === 0,
                  SVG_ICONS.ON_TRACK_SVG,
                  SVG_ICONS.OFF_TRACK_SVG
                ),
              ],
              width: "auto",
            },
            {
              text: "On-track means no suspensions in \n the current school year.",
              fontSize: 11,
              margin: [0, 4, 0, 0],
              color: "#333333",
            },
          ],
        },
        {
          text: "Suspensions", // Label for suspensions column
          fontSize: 11,
          alignment: "center",
          color: "#1a1c1e",
          width: "13%",
          bold: true,
          margin: [0, 22, 0, 0],
        },
        {
          stack: [
            {
              columns: Array.from(
                { length: 4 },
                (_, i) =>
                  createColumnContent(
                    `Q${i + 1}`,
                    student?.[`Q${i + 1}Suspensions`]
                  ) // Creates columns for each quarter's suspensions
              ),
            },
          ],
          width: "35%",
          alignment: "center",
          layout: "noBorders",
          margin: [0, 14, 0, 0],
        },
        {
          canvas: [
            {
              type: "line", // Line separator between columns
              x1: 10,
              y1: 0,
              x2: 10,
              y2: 40,
              lineWidth: 1,
              lineColor: "#707070",
              dash: { length: 5, space: 1 }, // Dashed line style
            },
          ],
          width: "5%",
          alignment: "center",
          margin: [0, 15, 0, 0],
        },
        {
          stack: [
            {
              text: `${student?.suspensions?.toFixed()}`, // Total suspensions
              fontSize: 15,
              italics: true,
              color: "#707070",
            },
            { text: "Total", fontSize: 12 },
          ],
          alignment: "center",
          width: "12%",
          margin: [0, 15, 0, 0],
        },
      ],
      margin: [0, 5, 0, 0],
    };
  };

  // student's postsecondary plan, GPA, applications submitted, FAFSA status
  const createPostSecondarySection = (student) => {
    return {
      table: {
        widths: ["*", "*"], // Two equal-width columns for postsecondary info
        body: [
          [
            {
              stack: [
                {
                  text: "Postsecondary Success", // Postsecondary Success label
                  style: "header",
                  fontSize: 15,
                  bold: true,
                  margin: [0, 0, 0, 3],
                },
                {
                  text: "Postsecondary Plan", // Postsecondary label
                  style: "subheader",
                  bold: true,
                },
                {
                  text: `${
                    student?.plans.length ? student?.plans[0].value : "No Data" // Display the student's plan
                  }`,
                  style: "subheader",
                  color: "#1a1c1e",
                },
              ],
              fontSize: FONT_SIZE.small,
            },
            {
              table: {
                widths: ["*", "*", "*"], // Three columns for GPA, applications, FAFSA
                body: [
                  [
                    {
                      stack: [
                        {
                          text: `${
                            !["", null].includes(student?.GPA) // Display GPA or "No data"
                              ? student.GPA
                              : "No data"
                          }`,
                          style: "gpa",
                          italics: true,
                          alignment: "center",
                          fontSize: 15,
                          color: "#707070",
                        },
                        {
                          text: "Cumulative \n GPA", // Label for GPA
                          style: "label",
                          alignment: "center",
                        },
                      ],
                      alignment: "center",
                    },
                    {
                      stack: [
                        {
                          text: `${student?._collegeAppsSubmitted}`, // Number of college applications submitted
                          style: "applications",
                          alignment: "center",
                          fontSize: 15,
                          italics: true,
                          color: "#707070",
                        },
                        {
                          text: "Applications Submitted", // Label for applications
                          style: "label",
                          alignment: "center",
                        },
                      ],
                      alignment: "center",
                    },
                    {
                      stack: [
                        {
                          text: `${
                            student?.FAFSAStatus[0].value
                              ? student?.FAFSAStatus[0].value === "completed"
                                ? "Yes"
                                : "No"
                              : "No"
                          }`, // Display FAFSA status (Completed/Not completed)
                          style: "response",
                          alignment: "center",
                          fontSize: 15,
                          italics: true,
                          color: "#707070",
                        },
                        {
                          text: "FAFSA", // FAFSA label
                          style: "label",
                          alignment: "center",
                        },
                        {
                          text: `Completed`, // Completed label
                          style: "label",
                          alignment: "center",
                        },
                      ],
                      alignment: "center",
                    },
                  ],
                ],
              },
              margin: [13, 0, 0, 0],
              fontSize: FONT_SIZE.small,
              layout: "noBorders",
            },
          ],
        ],
      },
      margin: [0, 5, 0, 0],
      layout: "noBorders",
    };
  };

  // student's intended major and GPA-based track status
  const createMajorSection = (student) => {
    return {
      table: {
        widths: ["*", "*"],
        body: [
          [
            {
              stack: [
                { text: "Intended Major", style: "header", bold: true }, // Title
                {
                  text: `${
                    student.intendedMajor[0]?.value // Display intended major
                      ? student.intendedMajor[0]?.value
                      : "No Data"
                  }`,
                  color: "#333333",
                },
              ],
              margin: [0, 0, 10, 0],
              fontSize: FONT_SIZE.small,
            },
            {
              stack: [
                {
                  columns: [
                    {
                      text: [
                        {
                          text: "Bs or Better", // Label for GPA track status
                          style: "header",
                          bold: true,
                        },
                        !["", null].includes(student?.GPA)
                          ? {
                              text: `: ${
                                student?.GPA < 3 ? "OFF-TRACK" : "ON-TRACK" // On/Off track based on GPA
                              }`,
                              italics: true,
                              color: `${
                                student?.GPA < 3 ? "#B32A1E" : "#008000"
                              }`,
                            }
                          : "",
                      ],
                      width: "auto",
                    },
                    !["", null].includes(student?.GPA)
                      ? {
                          svg: `${
                            student?.GPA < 3
                              ? SVG_ICONS.OFF_TRACK_SVG // On-track or off-track icon
                              : SVG_ICONS.ON_TRACK_SVG
                          }`,
                          width: 14,
                          margin: [5, 1.3, 5, 0],
                        }
                      : "",
                  ],
                },
                {
                  text: "On-track means having a cumulative GPA of \n 3.0 or higher.",
                  color: "#333333",
                },
              ],
              margin: [25, 0, 0, 0],
              fontSize: FONT_SIZE.small,
            },
          ],
        ],
      },
      margin: [0, 3, 0, 3],
      layout: "noBorders",
    };
  };

  // Main function that generates the complete PDF document
  const documentDefinition = {
    content: [
      createStudentInfo(
        student,
        SVG_ICONS.HEADER_IMG_SVG,
        yearRange,
        schoolName
      ), // Include Student Information
      createOnTrackTitleRow(SVG_ICONS.courseIconSvg), //student Courses and Grades TRACK status
      get.Courses(student), // Include courses table
      createAttendanceSection(student), // Include attendance section
      createBehaviorSection(student), // Include behavior section
      createPostSecondarySection(student), // Include postsecondary section
      createMajorSection(student), // Include major section
      createDynamicTableContent(sections), // Other dynamic content
    ],
    defaultStyle: {
      font: "NotoSansJP",
    },
    pageSize: {
      width: 612, // Page width
      height: 790, // Page height
    },
    pageMargins: [23, 23, 18, 5], // Margins of the document
  };
  const fileName = `${student.lastName}_${student.firstName}_${student.studentDistrictId}`;

  pdfMake.createPdf(documentDefinition).download(fileName);
};

/** This is the Login page which shows to unauthenticated users. */
<template>
  <Modal
    ref="loginHelp"
    title="Help logging in"
    instructions="Please log in using your personal credentials. If you are an educator and do not have an account, please contact your school or district security manager."
    :showCancelButton="false"
  >
  </Modal>
  <div class="login-main-area">
    <NotificationBanner
      v-if="hubDownBanner"
      message="The Data Hub will be offline for start-of-year updates between Friday afternoon (8/23) and Sunday evening (8/25)."
    />
    <div class="login-container">
      <img class="rise-logo" src="../assets/img/rise_logo_vertical_old.png" />
      <div class="welcome-title" data-cy="welcome-title">
        Welcome to the RISE Data Hub
      </div>
      <div class="welcome-blurb">
        The RISE Data Hub provides school partners with access to secure and
        user-friendly tools to support student success.
      </div>

      <Spinner v-if="loading && !this.store.state.noDataHubActiveAccount" />

      <div v-if="this.store.state.noDataHubActiveAccount" class="login-error">
        Please contact a User Manager from your district to re-activate your
        account. (This is likely your Principal, Assistant Principal, or a
        member of your District IT team.)
      </div>

      <div v-if="showPopover && popoverFlag" class="popover">
        <span v-if="macOS">
          The app is having trouble logging you in. Please clear your cache (Cmd
          + Shift + R) and try logging in again.
        </span>
        <span v-else>
          The app is having trouble logging you in. Please clear your cache
          (Ctrl + Shift + R) and try logging in again.
        </span>
      </div>

      <div v-if="!loading">
        <button @click="login">
          <img src="../assets/img/btn_google_light_normal_ios.svg" />
          <span class="login-button-text">Log in with Google</span>
        </button>
        <div class="login-help-area">
          <div class="need-help-logging-in" @click="showLoginHelp">
            Can't log in?
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Modal from "@/components/modals/modal.vue";
import Spinner from "@/components/loaders/spinner.vue";
import NotificationBanner from "@/components/notificationBanner.vue";
import { getAuth, getRedirectResult } from "firebase/auth";
import "@/firebase/firebase.js"; // need this to initialize the FB app first
getAuth(); // need this line for Cypress E2E tests to run.

export default {
  name: "Login",
  components: { Modal, Spinner, NotificationBanner },
  inject: ["posthog"],
  data() {
    return {
      loading: true,
      hubDownBanner: false,
      showPopover: false,
      popoverTimer: null,
      popoverFlag: false,
      macOS: false,
    };
  },
  methods: {
    login() {
      this.popoverTimer = setTimeout(() => {
        this.showPopover = true;
      }, 30000);
      this.session.launchLoginPopup();
      this.loading = true;
    },
    afterLogin(authResponse) {
      // Called by Cypress automated tests
      try {
        this.session.processLogIn(authResponse);
      } catch (error) {
        this.posthog.capture("login-error", {
          errorCode: error.code,
          errorMessage: error.message,
        });
      }
    },
    showLoginHelp() {
      this.$refs.loginHelp.show();
    },
  },
  beforeCreate() {
    const auth = getAuth();
    if (auth.currentUser) {
      try {
        this.session.processLogIn(auth.currentUser);
      } catch (error) {
        console.log(error);
        this.posthog.capture("login-error", {
          errorCode: error.code,
          errorMessage: error.message,
        });
      }
    } else {
      getRedirectResult(auth)
        .then((result) => {
          if (!result) this.loading = false;
          if (result)
            try {
              this.session.processLogIn(result.user);
            } catch (error) {
              console.log(error);
              this.posthog.capture("login-error", {
                errorCode: error.code,
                errorMessage: error.message,
              });
            }
        })
        .catch((error) => {
          // Handle Errors here.
          const errorCode = error.code;
          const errorMessage = error.message;
          console.log({ errorCode, errorMessage });
          this.posthog.capture("login-error", {
            errorCode: errorCode,
            errorMessage: errorMessage,
          });
        });
    }
  },
  activated() {
    // Reset PostHog and send pageview to posthog
    this.posthog.reset();
    this.posthog.capture("$pageview", {
      $current_url: "/",
    });
    this.posthog.onFeatureFlags(() => {
      if (this.posthog.isFeatureEnabled("hub-down-banner")) {
        this.hubDownBanner = true;
      }
      if (this.posthog.isFeatureEnabled("login-popover")) {
        this.popoverFlag = true;
      }
    });

    if (window.navigator.userAgent.indexOf("Mac OS") != -1) this.macOS = true;
  },
  beforeUnmount() {
    clearTimeout(this.popoverTimer);
  },
};
</script>

<style lang="scss">
.login-help-area {
  position: relative;
  top: 10px;
}

.need-help-logging-in {
  color: var(--color-contrast-medium);
  &:hover {
    color: var(--color-contrast-full);
    cursor: pointer;
  }
}

body {
  margin: 0;
}

.login-button-text {
  padding: 10px;
  font-size: 15px;
  vertical-align: 100%;
}

.welcome-message {
  font-size: 25px;
  padding: 0 20px 40px;
}
.login-main-area {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #263c7f;
  background-image: url("../assets/img/bluePattern.png");
  background-attachment: fixed;
  background-size: cover;
  overflow: hidden;
}
.login-container {
  width: 400px;
  background-color: #f0f0f0;
  border-radius: 10px;
  height: 360px;
  position: absolute;
  top: 15%;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  .welcome-title {
    font-size: 2rem;
    font-weight: bold;
  }
  .welcome-blurb {
    margin: 2rem 0;
    padding: 0 3rem;
  }
  .rise-logo {
    width: 130px;
  }
  button {
    &:hover {
      cursor: pointer;
    }
  }
  .login-error {
    margin: 2rem 0;
    padding: 0 3rem;
    color: red;
  }
}

.popover {
  position: absolute;
  background-color: lightgrey;
  border: 1px solid #ccc;
  padding: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 300px;
  border-radius: 10px;
  top: 70%;
  left: 10%;
}
</style>

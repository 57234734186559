/**
 * The roles array maps each user role to its corresponding powers.
 * Ther powers array describes what each power allows the user to do.
 */

export const roles = [
  {
    key: "viewer",
    displayName: "Viewer",
    powers: [],
    description: "Can view data for school but cannot edit student data.",
  },
  {
    key: "counselor",
    displayName: "Counselor",
    powers: ["updateStudents", "assignFreshSuccessTags"],
    description: "Can edit Postsecondary fields.",
  },
  {
    key: "leadCounselor",
    displayName: "Lead Counselor",
    powers: [
      "viewDataByCounselor",
      "updateStudents",
      "markCalendarItemsComplete",
      "assignFreshSuccessTags",
    ],
    description:
      'Has all counselor permissions plus the ability to see student data disaggregated by counselor and the ability to check items as "complete" on the Strategic Data Calendar',
  },
  {
    key: "schoolAdmin",
    displayName: "School/District Admin",
    powers: [
      "viewDataByCounselor",
      "markCalendarItemsComplete",
      "assignFreshSuccessTags",
    ],
    description:
      'Has the ability to see student data disaggregated by counselor and the ability to check items as "complete" on the Strategic Data Calendar.',
  },
  {
    key: "riseStaff",
    displayName: "RISE Staff",
    powers: [
      "viewDataByCounselor",
      "managePostSecTags",
      "updateStudents",
      "viewInternalFeatures",
      "viewLoginReports",
      "assignFreshSuccessTags",
    ],
    description: "",
  },
  {
    key: "calendarManager",
    displayName: "Calendar Manager",
    powers: ["writeToCalendar", "markCalendarItemsComplete", "viewOTCEvents"],
    description:
      'Can access OTC features, check items as "complete" on the Strategic Data Calendar, and change the events on the Strategic Data Calendar',
  },
  {
    key: "OTC",
    displayName: "OTC",
    powers: ["viewOTCEvents", "assignFreshSuccessTags"],
    description: "Can access OTC features, including check-ins.",
  },
  {
    key: "OTCManager",
    displayName: "OTC Manager",
    powers: [
      "viewOTCEvents",
      "viewAllCheckins",
      "markCalendarItemsComplete",
      "assignFreshSuccessTags",
    ],
    description:
      'Has all OTC permissions, and can also view reports of all check-ins from within the school and check items as "complete" on the Strategic Data Calendar.',
  },
  {
    key: "superuser",
    displayName: "Developer",
    description: "",
  },
];

export const powers = [
  {
    key: "viewDataByCounselor",
    description: "Can view disaggregated student data by counselor",
  },
  {
    key: "managePostSecTags",
    description: "Can create, update, and delete postsecondary tags",
  },
  {
    key: "updateStudents",
    description:
      "Can update student data for all students at permitted schools; can assign and unassign tags to students",
  },
  {
    key: "assignFreshSuccessTags",
    description: "Can assign and unassign grade 9 tags to students",
  },
  {
    key: "writeToCalendar",
    description: "Can create, update, and delete Strategic Data Calendar items",
  },
  {
    key: "markCalendarItemsComplete",
    description: "Can mark calendar items complete",
  },
  {
    key: "viewOTCEvents",
    description: "Can view OTC calendar items",
  },
  {
    key: "viewAllCheckins",
    description:
      "Can view check-ins created by any user within permitted schools",
  },
  {
    key: "viewInternalFeatures",
    description:
      "Can view apps that are not yet intended for release to partners",
  },
];

/**
 * This contains the functions for building the small SVG which summarizes and displays a student's current grades in a small space.
 */

import variables from "@/assets/styles/colors.scss";
import { currentYear } from "@/functions/utils.js";

const markingPeriods = ["Q1", "Q2", "Q3", "Q4"];

const getRectangle = ({ i, j, width, height, color }) => `
    <rect x="${width * j}" y="${height * i}" height="${height - 1}" width="${
  width - 1
}" fill="${color}"></rect>
`;

const getAllRectangles = ({ colorTextArray, height, width }) =>
  colorTextArray.map((row, i) =>
    row.map((color, j) => getRectangle({ i, j, width, height, color }))
  );

const colorMatrixToSVG = ({ colorTextArray, height, width }) => `
    <svg width="55" height="30" xmlns="http://www.w3.org/2000/svg">
        ${getAllRectangles({ colorTextArray, height, width })}
    </svg>
`;

const colorMap = ["A", "B", "C", "D", "F", "P"].map((e) => ({
  letter: e,
  color: variables["defaultgrade" + e],
}));

const getColor = (letterGrade) =>
  colorMap.find((element) => new RegExp(`^${element.letter}`).test(letterGrade))
    ?.color || "grey";

const calculate = {
  max: (letterList) =>
    ["A", "B", "C", "D", "F"].find((letter) =>
      letterList.some((element) => new RegExp(`^${letter}`).test(element))
    ) || null,
  median: (letterList) => {
    const sorted = letterList
      .filter((element) =>
        ["A", "B", "C", "D", "F"].some((letter) =>
          new RegExp(`^${letter}`).test(element)
        )
      )
      .sort((a, b) => (a > b ? 1 : -1));
    const recordCount = sorted.length;
    const mid = Math.floor((recordCount - 1) / 2);
    return sorted[mid] || null;
  },
  min: (letterList) =>
    ["F", "D", "C", "B", "A"].find((letter) =>
      letterList.some((element) => new RegExp(`^${letter}`).test(element))
    ) || null,
};

const getSummary = (courses, mode) => {
  const result = { letterGrade: {} };
  ["Q1", "Q2", "Q3", "Q4"].forEach((quarter) => {
    const letterList = Object.values(courses).map(
      (course) => course.letterGrade[quarter]
    );
    result.letterGrade[quarter] = calculate[mode](letterList);
  });
  return result;
};

const getCourseSummary = (courses) => ({
  topGrade: getSummary(courses, "max"),
  medianGrade: getSummary(courses, "median"),
  lowGrade: getSummary(courses, "min"),
});

export const coursesToSVG = (courses) => {
  const thisYearCourses = Object.values(courses).filter(
    (element) => element.schoolYear == currentYear
  );
  const courseSummary = getCourseSummary(thisYearCourses);
  return colorMatrixToSVG({
    height: 10,
    width: 14,
    colorTextArray: Object.keys(courseSummary).map((key) =>
      markingPeriods.map((markingPeriod) =>
        getColor(courseSummary[key].letterGrade[markingPeriod])
      )
    ),
  });
};

/** This modal allows the user to edit student documents. */
<template>
  <Modal
    data-cy="edit-field"
    ref="editField"
    title="Edit Entry"
    :instructions="editFieldInstructions"
    @success="updateDataFromModal"
  >
    <input
      v-if="editFieldInputToShow === 'text'"
      class="edit-field-text-input"
      v-model="editField"
      placeholder="Type here"
    />
    <input
      v-if="editFieldInputToShow === 'number'"
      type="number"
      v-model="editField"
    />
    <MultipleChoice
      v-if="editFieldInputToShow === 'selectMultiple'"
      :options="editCategories"
      v-model="editField"
    />
    <CustomSelect
      v-if="editFieldInputToShow === 'select'"
      :options="editCategories"
      v-model="editField"
    />
  </Modal>
</template>

<script>
import { getGroupValue } from "@/functions/utils.js";
import CustomSelect from "@/components/customSelect.vue";
import MultipleChoice from "@/components/multipleChoice.vue";
import Modal from "@/components/modals/modal.vue";

export default {
  data() {
    return {
      editFieldInstructions: "",
      editField: "",
      editCategories: [],
      editFieldInputToShow: "text",
      fieldBeingEdited: {},
    };
  },
  components: { CustomSelect, MultipleChoice, Modal },
  methods: {
    setEventListeners() {
      this.ee.on("launchEditField", (obj) => this.launchEditField(obj));
      this.ee.on("updateRecord", (obj) => this.updateRecord(obj));
    },
    getOption(element) {
      return typeof element === "object" ? element.displayValue : element;
    },
    launchEditField({
      key, // field key
      currentValue, // current value of data
      id, // studentRISEId of record to be changed
      studentName, // display name of record to be changed
    }) {
      const myField = this.config.fields.find((field) => field.key == key);

      // hold the data of field to be edited
      this.fieldBeingEdited = { id, key };

      // decide what kind of input to show

      this.editFieldInputToShow = [
        { type: "categoryArray", show: "selectMultiple" },
        { type: "category", show: myField.options ? "select" : "text" },
        { type: "numeric", show: "number" },
        { type: "boolean", show: "select" },
        { type: "unique", show: "text" },
      ].find((element) => element.type == myField.type)?.show;

      if (!this.editFieldInputToShow) return;

      // populate the options available
      if (this.editFieldInputToShow === "select") {
        this.editCategories = Object.values(myField.options).map((element) => {
          const result = this.getOption(element);
          return { displayName: result };
        });
      } else if (this.editFieldInputToShow === "selectMultiple") {
        this.editCategories = Object.values(myField.options).map(
          (element) => element
        );
      }

      // populate the existing value
      this.editField =
        this.editFieldInputToShow === "select"
          ? this.getOption(myField.options[currentValue])
          : currentValue;

      // set the instructions in modal window
      this.editFieldInstructions = `Set ${myField.displayName} for ${studentName}`;

      // show the modal window
      this.$refs.editField.show();
    },
    updateDataFromModal() {
      this.updateRecord({
        fieldKey: this.fieldBeingEdited.key,
        id: this.fieldBeingEdited.id,
        value: getGroupValue({
          field: this.config.fields.find(
            (f) => f.key === this.fieldBeingEdited.key
          ),
          value: this.editField,
        }),
      });
    },
    updateRecord({ fieldKey, id, value }) {
      // make a clone to avoid mutating the store here
      const myRecord = {
        ...this.store.state.studentData.find(
          (record) => record.studentRISEId === id
        ),
      };

      // add a log to the array of changes to this field
      myRecord[fieldKey].unshift({
        value: value,
        timestamp: new Date().valueOf(),
        author: this.$user.uid,
        displayName: this.$user.displayName,
      });

      // update firestore
      this.fb.studentData.update({
        id: myRecord.docID,
        record: myRecord,
        fieldKey,
      });
    },
  },
  mounted() {
    this.setEventListeners();
  },
};
</script>

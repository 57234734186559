/**
 * This is the main entry-point of the entire app. It:
 *  - loads the top level component (found in App.vue)
 *  - adds some global properties and plugins with the various use commands
 *  - and renders it on the element with '#app' id (found in public/index.html).
 */

import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { store, initializeColorTheme } from "@/core/store.js";
import { installFB } from "@/firebase/firebase.js";
import { installPlugins, initialize } from "@/core/plugins.js";
import { installSession } from "@/core/session.js";
import posthog from "posthog-js";

// Initialize posthog analytics
posthog.init("phc_ddRsCxwVnHuCbguwHx7bE8P02wMpPYwKIu3J6djLFA", {
  api_host: "https://app.posthog.com",
  autocapture: false,
});

const myVueApp = createApp(App)
  .use(router)
  .use(initialize)
  .use(installPlugins)
  .use(installFB)
  .use(installSession)
  .use(store)
  .use(initializeColorTheme)
  .provide("posthog", posthog)
  .mount("#app");

// Allow the app object to be accessible to the test suite
if (window.Cypress) window.myVueApp = myVueApp;

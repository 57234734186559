/** This is the most common protocol slide page. It contains a chartJS chart and
corresponding bullet points. */
<template>
  <h1 class="slide-header">{{ slide.title }}</h1>
  <h3>{{ slide.subtitle }}</h3>
  <div class="slide-body">
    <div
      v-for="(chart, index) in slide.charts"
      :key="`${index}_${slide.index}`"
      class="slide-chart-container"
      :class="getDynamicClass(slide)"
    >
      <MyChart
        data-cy="protocol-chart"
        :chartConfig="addInteraction(chart.chartConfig)"
        :chartData="store.state.studentData"
      />
    </div>
    <ul class="slide-questions" :class="getQuestionsClass(slide)">
      <li v-for="(question, index) in slide.questions" :key="index">
        {{ question }}
      </li>
    </ul>
  </div>
</template>

<script>
import MyChart from "@/components/charts.vue";
import { getRedirectCustomPropFromChart } from "@/functions/summary.js";

export default {
  props: {
    slide: Object,
  },
  components: { MyChart },
  methods: {
    getDynamicClass(slide) {
      return slide.charts[0].chartConfig.type === "analyticsPie"
        ? "pie-container"
        : "regular-chart-container";
    },
    getQuestionsClass(slide) {
      return slide.charts[0].chartConfig.type === "analyticsPie"
        ? "pie-questions"
        : "";
    },
    addInteraction(chartConfig) {
      chartConfig.onClick = this.chartClickHandler;
      return chartConfig;
    },
    chartClickHandler({ filters }) {
      const defaultFields = [
        "_studentName",
        "gender",
        "raceAndEthnicity.race",
        "englishLearner",
        "specialEducation",
      ];
      const redirectDefaultFields =
        this.slide.charts[0]?.chartConfig?.redirectDefaultFields ||
        defaultFields;

      this.store.setCustomProp(
        getRedirectCustomPropFromChart({
          filters,
          redirectDefaultFields,
          fields: this.config.fields,
        })
      );

      this.$router.push("/tracker");
    },
  },
};
</script>

<style lang="scss">
.slide-body {
  .regular-chart-container {
    max-width: 900px;
  }
  .pie-container {
    width: 400px;
    position: relative;
    top: -80px;
  }
  .pie-questions {
    position: relative;
    top: -100px;
  }
}
</style>

/** This is the Settings page which lets a user update their preferences */
<template>
  <div class="settings-app">
    <Sidebar>
      <Info />
    </Sidebar>
    <div class="main-area-narrow main-area-indented settings-area">
      <div>
        <h1>My Settings</h1>
        <table>
          <tr>
            <td class="setting-title-column">Theme</td>
            <td>
              <CustomSelect
                data-cy="theme-setting"
                class="settings-dropdown"
                :options="store.state.ui.loadedThemes"
                v-model="colorTheme"
              />
            </td>
          </tr>
          <tr v-if="userSchoolsSorted.length > 1">
            <td>Default School</td>
            <td>
              <CustomSelect
                data-cy="default-school-setting"
                class="settings-dropdown"
                :options="userSchoolsSorted"
                v-model="defaultSchool"
              />
            </td>
          </tr>
        </table>
        <CustomButton @click="goBack" buttonText="Done" />
      </div>
      <hr class="divider" />
      <div>
        <h1>My Role<span v-if="userRoles.length > 1">s</span></h1>
        <div v-for="(role, i) in userRoles" :key="i">
          <p>
            {{ role.displayName }}<span v-if="role.description">:</span>
            {{ role.description }}
          </p>
        </div>
        <img
          v-if="innovationFellow"
          src="../assets/img/Innovation-Fellow.png"
          alt="Innovation Fellow 2024-25"
          width="300"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { roles } from "@/apps/roles";
import Sidebar from "@/components/nav/sidebar.vue";
import CustomSelect from "@/components/customSelect.vue";
import CustomButton from "@/components/customButton.vue";
import Info from "@/components/nav/info.vue";
import { preferences } from "@/core/user.js";
import { compareSchools } from "@/functions/utils.js";

export default {
  name: "Settings",
  data() {
    return {
      colorTheme: "",
      defaultSchool: "",
      innovationFellow: false,
    };
  },
  inject: ["posthog"],
  watch: {
    colorTheme: function () {
      this.update("colorTheme");
    },
    defaultSchool: function () {
      this.update("defaultSchool");
    },
  },
  computed: {
    /**
     * Gets the list of user schools and alphabetizes them
     * @returns an array of school objects sorted alphabetically by display name
     */
    userSchoolsSorted: function () {
      const schools = this.getSchools();
      const sortedSchools = schools.sort(compareSchools);
      return sortedSchools;
    },
    /**
     * Gets a list of roles (with their displayName and description) based on the user's dataHubRoles
     * @returns an array of role objects that match the user's dataHubRoles
     */
    userRoles: function () {
      const rolesToDisplay = [];
      this.$user.roles.forEach((role) => {
        for (let i = 0; i < roles.length; i++) {
          if (roles[i].key === role) {
            rolesToDisplay.push(roles[i]);
          }
        }
      });
      return rolesToDisplay;
    },
  },
  methods: {
    goBack() {
      this.$router.back();
    },
    getSchools() {
      const mySchools = this.store.state.schools
        .filter((school) => this.$user.schools.includes(school.key))
        .map((school) => ({
          displayName: school.displayName,
          value: school.key,
        }));
      preferences.find((p) => p.key === "defaultSchool").options = mySchools;
      return mySchools;
    },
    update(key) {
      const myPreference = preferences.find((e) => e.key === key);
      if (!myPreference) return;
      const value = myPreference.options.find(
        (e) => e.displayName == this[key]
      )?.value;
      if (!value) return;
      if (myPreference.setFn) this.store[myPreference.setFn](value);
      this.fb.user.updatePreference(myPreference.key, value);
    },
  },
  components: {
    Sidebar,
    CustomSelect,
    Info,
    CustomButton,
  },
  activated() {
    preferences.forEach((element) => {
      const obj = element.options.find(
        (e) => e.value == this.$user.preferences[element.key]
      );
      this[element.key] = obj ? obj.displayName : element.default;
    });

    //  Check Innovation Fellow flag
    this.posthog.onFeatureFlags(() => {
      if (this.posthog.isFeatureEnabled("innovation-fellow-24-25")) {
        this.innovationFellow = true;
      }
    });

    // Send pageview to posthog
    this.posthog.capture("$pageview", {
      $current_url: "/settings",
    });
  },
};
</script>

<style lang="scss">
.settings-app {
  .settings-dropdown {
    box-shadow: 0px 0px 10px var(--color-contrast-low);
  }
  .setting-title-column {
    width: 200px;
  }
}

.divider {
  border-top: 1px solid var(--color-contrast-high);
  margin-top: 28px;
}
</style>

/** This is the Student List view which shows custom lists of students as well
as some analytics charts. */
<template>
  <div class="tracker-app">
    <Modal
      ref="visibleColumnsModal"
      data-cy="visible-columns-modal"
      title="Select Visible Columns"
      instructions=""
      @success="renderTrackerTable"
      :showCancelButton="false"
    >
      <div id="columns-select-area" data-cy="columns-select-area">
        <div
          class="column-category"
          v-for="(group, i) in this.config.fieldGroups"
          :key="i"
        >
          <div>
            <h3>{{ group.name }}</h3>
            <div v-for="(item, index) in group.fields" :key="index">
              <div>
                <input type="checkbox" :id="item.name" v-model="item.checked" />
                <label :for="item.name">{{ item.name }}</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
    <Modal
      :showCancelButton="false"
      ref="miniAnalytics"
      data-cy="field-insights-modal"
      title="Field Insights"
      instructions=""
    >
      <MiniAnalytics
        data-cy="mini-analytics-area"
        :fieldKey="miniAnalyticsFieldKey"
        :filteredData="filteredData"
      />
    </Modal>
    <Notification ref="message" :msg="messageText" />
    <Sidebar data-cy="tracker-sidebar">
      <Views
        ref="views"
        :customViews="customViews"
        @applyView="applyView({ views: getViews() })"
        @addView="addView"
        @renameView="renameView"
        @updateView="updateView"
        @notify="notify"
        @deleteView="deleteView"
        @launchUpdateColumns="this.$refs.visibleColumnsModal.show()"
        @download="download"
      />
      <Filters
        ref="filters"
        :amountOfStudents="filteredData.length"
        @filtersChanged="setFilters"
      />
      <div class="sidebar-section">
        <div
          class="sidebar-section-title"
          @mouseover="
            showTooltip(
              'The Key Insights section shows you quick summary information about all students in the current grade.'
            )
          "
          @mouseleave="showTooltip('')"
        >
          Key Insights
        </div>
        <div class="sidebar-section-padded">
          <CustomSelect
            style="display: inline-block"
            data-cy="key-insight-selector"
            :options="filteredInsights"
            displayField="name"
            v-model="selectedInsight"
            @mouseover="
              showTooltip('Choose a key insight from this dropdown menu.')
            "
            @mouseleave="showTooltip('')"
          />
        </div>
        <MiniChart
          :datasets="insightData"
          :insightTitle="selectedInsight"
          :insightMatrix="insightMatrix"
          :key="insightKey"
          @addMatrixFilter="addMatrixFilter"
          @addInsightFilter="addInsightFilter"
        />
      </div>
      <hr class="sidebar-division" />
      <Info />
    </Sidebar>
    <div class="main-area-narrow main-area-indented">
      <div
        :class="courses ? 'coursesTable' : 'hidden'"
        id="coursesTable"
        @mouseleave="courses = null"
      >
        <div class="courses-heading">Courses</div>
        <CoursesTable :courses="courses" />
      </div>
      <TableLoader v-show="loading" />
      <MyTable
        v-show="!loading"
        :tableData="studentDataAlphabetized"
        :columns="trackerColumns"
        :customConfig="trackerTableConfig"
        ref="trackerTable"
      />
    </div>
  </div>
</template>

<script>
import MiniChart from "@/components/tracker/miniChart.vue";
import MiniAnalytics from "@/components/tracker/miniAnalytics.vue";
import Notification from "@/components/tracker/notification.vue";
import Views from "@/components/tracker/views.vue";
import MyTable from "@/components/table.vue";
import Sidebar from "@/components/nav/sidebar.vue";
import Modal from "@/components/modals/modal.vue";
import CoursesTable from "@/components/coursesTable.vue";
import Filters from "@/components/filters.vue";
import Info from "@/components/nav/info.vue";
import CustomSelect from "@/components/customSelect.vue";
import CustomButton from "@/components/customButton.vue";
import TableLoader from "@/components/loaders/tableLoader.vue";
import {
  alphabetizeStudents,
  getHeaderButtons,
  getGroupValue,
  getDisplayValue,
  currentYear,
  getMutatorParams,
} from "@/functions/utils.js";
import { downloadOnTheCusp } from "@/functions/onTheCusp.js";
import {
  getInsightData,
  getInsightMatrix,
  insights,
} from "@/functions/insight.js";
import { getBandsFilter } from "@/functions/summary.js";
import { formatCell } from "@/functions/formatters.js";
import { getMatrixFilters } from "@/functions/matrix.js";
import { matrixConfig } from "@/apps/matrix.js";
import { gradeStandardViews, defaultViews } from "@/apps/views.js";

export default {
  name: "Tracker",
  components: {
    MyTable,
    Sidebar,
    CustomSelect,
    CustomButton,
    Views,
    Notification,
    Filters,
    Info,
    CoursesTable,
    Modal,
    MiniChart,
    MiniAnalytics,
    TableLoader,
  },
  data() {
    return {
      messageText: "",
      selectedFocus: "",
      filters: [],
      customViews: [], // loaded from user preferences when mounted
      trackerTableConfig: {
        cellDblClick: (e, cell) => this.cellDblClick(e, cell),
      },
      trackerColumns: this.config.fields
        .filter((f) => f.key === "_studentName")
        .map((field) => this.getTrackerColumn(field)),
      customColumns: null,
      columnOrder: this.config.fields.map((f) => f.displayName),
      loading: true,
      matrixConfig,
      courses: null,
      filteredData: [],
      insightData: [],
      insightKey: 0,
      insightMatrix: {},
      insights,
      selectedInsight:
        this.config.gradeConfig[this.store.state.showingGradeLevel]
          .trackerConfig.defaultInsight,
      miniAnalyticsFieldKey: "",
      studentDataLoaded: false,
      noteDataLoaded: false,
      studentDataAlphabetized:
        this.store.state.studentData.sort(alphabetizeStudents),
    };
  },
  inject: ["posthog"],
  watch: {
    selectedInsight: function () {
      this.updateInsightData();
    },
  },
  methods: {
    // Event listeners

    setEventListeners() {
      [
        { name: "clearLocalState", fn: () => this.clearLocalState() },
        {
          name: "studentDataReceived",
          fn: (dataType) => this.updateTrackerData(dataType),
        },
        {
          name: "noteDataReceived",
          fn: (dataType) => this.updateTrackerData(dataType),
        },
        { name: "tagDataReceived", fn: () => this.renderTrackerTable() },
        { name: "colorThemeChanged", fn: () => this.renderTrackerTable() },
        { name: "gradeLevelChange", fn: () => this.setDefaultView() },
        { name: "dataFiltered", fn: (rows) => this.dataFilteredHandler(rows) },
        { name: "columnMoved", fn: (cols) => this.columnMovedHandler(cols) },
        { name: "loading", fn: () => this.showLoader() },
      ].forEach((event) => {
        this.ee.on(event.name, event.fn);
      });
    },

    // Display

    notify(msg) {
      this.messageText = msg;
      this.$refs.message.show();
    },
    updateTrackerData(dataType) {
      if (dataType) this[dataType] = true;
      if (!this.$refs.trackerTable) return;
      this.$refs.trackerTable.doMethod(
        "replaceData",
        this.store.state.studentData.sort(alphabetizeStudents)
      );
      this.updateInsightData();
      this.setFilteredData();
      if (this.studentDataLoaded) this.loading = false;
    },
    renderTrackerTable() {
      // update tabulator table
      // when filters are changed, columns are selected, and views are applied
      if (!this.$refs.trackerTable) return;

      //only call this function once and store results;
      const visibleCols = this.visibleColumns();

      //use custom arrangement of columns first - happens when user reorders columns
      if (this.customColumns) {
        //grab any new columns selected
        const newCols = visibleCols
          .map((name) =>
            this.customColumns.find((col) => col.title === name) ? null : name
          )
          .filter((n) => n);

        //we want to preserve the custom order so add the new columns behind student name column
        newCols.forEach((fieldName) =>
          this.customColumns.splice(
            1,
            0,
            this.getTrackerColumn(
              this.config.fields.find((f) => f.displayName === fieldName)
            )
          )
        );

        //check for absense of columns - get index
        this.customColumns.forEach((col, index) => {
          if (visibleCols.indexOf(col.title) === -1)
            this.customColumns.splice(index, 1);
        });

        this.trackerColumns = this.customColumns;
      } else {
        this.trackerColumns = visibleCols
          .map((fieldName) =>
            this.config.fields.find((f) => f.displayName === fieldName)
          )
          .filter((f) => f !== undefined)
          .sort((a, b) =>
            a.key === "_studentName" ? -1 : b.key === "_studentName" ? 1 : 0
          )
          .map((field) => this.getTrackerColumn(field));
      }

      //check to see if all filters have an appropriate column present in the table to filter on.
      if (this.filters && this.filters.length) {
        this.filters.forEach((filter) => {
          //check to see if the filter has a column in the table
          let hasColumn = this.trackerColumns.some(
            (column) => column.field === filter.field
          );
          if (!hasColumn) {
            //if not column present then get column from field
            const column = this.getTrackerColumn(
              this.config.fields.find((f) => f.key === filter.field)
            );
            //add column to end of array
            this.trackerColumns.push(column);
          }
        });
      }

      this.$refs.trackerTable.doMethod("setColumns", this.trackerColumns);

      this.$refs.trackerTable.render({
        filters: this.filters,
      });

      this.renderFilterIcons();

      this.updateInsightData();

      if (this.studentDataLoaded) this.loading = false;
    },
    dataFilteredHandler(rows) {
      // responds to 'dataFiltered' event, set when filter applied to any Tabulator table
      if (this.$router.currentRoute._value.name !== "Student Lists") return;
      this.setFilteredData(rows);
    },
    setFilteredData(rows) {
      // set filtered data field to reflect current active rows in table
      if (!this.$refs.trackerTable) return;

      // Update data to use for field insights
      this.filteredData = rows
        ? rows
        : this.$refs.trackerTable.doMethod("getData", "active");
    },
    updateInsightData() {
      if (!this.$refs.trackerTable) return;

      // Update key insights in sidebar
      const config = this.insights.find(
        (element) => element.name === this.selectedInsight
      );
      if (!config) return;
      if (config.type === "matrix") {
        this.insightMatrix = getInsightMatrix({
          data: this.store.state.studentData,
          config: matrixConfig,
          fields: this.config.fields,
        });
      } else {
        this.insightData = getInsightData({
          data: this.store.state.studentData,
          config,
          fields: this.config.fields,
        });
      }
      this.insightKey++;
    },
    showDetails(cell) {
      // navigate to student profile page and open chat when user double clicks on student name
      if (
        !this.store.state.routes.find((element) => element.name === "Profile")
      )
        return;

      const cellData = cell._cell.row.data;

      const obj = {
        id: cellData.studentRISEId,
        name: [cellData.lastName, cellData.firstName].join(", "),
      };

      this.store.setCustomProp(obj);
      this.$router.push("/profile");
    },
    applyViewPreferences(preferences) {
      // set default view and custom saved views
      this.customViews = preferences.customViews || [];
      // TODO: Once Firestore has been updated to replace all 'Default' defaultViews to 'Standard' this check can be removed
      if (preferences.defaultView === "Default") {
        this.$refs.views.setSelectedFocus("Standard");
      } else {
        this.$refs.views.setSelectedFocus(preferences.defaultView || null);
      }
    },
    showLoader() {
      this.loading = true;
    },

    // Insight methods

    addMatrixFilter({ i, j }) {
      const myMatrixFilters = getMatrixFilters({
        config: matrixConfig,
        fields: this.config.fields,
        i,
        j,
      });
      this.clearFilters();
      this.addFilter({ filterArr: myMatrixFilters });
    },
    addInsightFilter(label) {
      const config = this.insights.find(
        (element) => element.name === this.selectedInsight
      );
      if (!config) return;
      const myField = this.config.fields.find(
        (element) => element.key === config.field
      );
      if (!myField) return;

      const filterArr = myField.bands
        ? getBandsFilter({ field: myField, value: label })
        : [
            {
              displayName: myField.displayName,
              field: myField.key,
              type: "customIn",
              displayType: ":",
              value: [getGroupValue({ field: myField, value: label })],
            },
          ];

      this.clearFilters();
      this.addFilter({ filterArr });
    },

    // Tracker Table methods

    headerButtonClick({ key, fn }) {
      [
        { functionName: "hide", fn: () => this.hideColumn(key) },
        {
          functionName: "chart",
          fn: () => {
            this.miniAnalyticsFieldKey = key;
            this.$refs.miniAnalytics.show();
          },
        },
        { functionName: "filter", fn: () => this.clickOnHeaderFilter(key) },
      ]
        .find((element) => element.functionName == fn)
        ?.fn();
    },
    clickOnHeaderFilter(key) {
      const shouldRemoveFilter = this.filters.some(
        (filter) => filter.field == key
      );
      if (shouldRemoveFilter) {
        this.$refs.filters?.deleteFiltersFromKey(key);
      } else {
        this.$refs.filters?.launchAdd(key);
      }
    },
    hideColumn(key) {
      const myField = this.config.fields.find((field) => field.key == key);
      if (!myField) return;
      this.config.fieldGroups
        .find((obj) =>
          obj.fields.some((field) => field.name == myField.displayName)
        )
        .fields.find((f) => f.name == myField.displayName).checked = false;
      this.renderTrackerTable();
    },
    getTrackerColumn(field) {
      // maps fields object to Tabulator config object
      return {
        title: field.displayName,
        field: field.key,
        mutator: (value, data, type, params, component) => {
          if (!field.mutator) return value;

          return field.mutator({
            data,
            params: getMutatorParams({
              state: this.store.state,
              user: this.$user,
            }),
          });
        },
        formatter: (cell) => this.formatCell({ cell, field }),
        headerFilterPlaceholder:
          field.key === "_studentName" || field.key === "studentDistrictId"
            ? "Find student..."
            : "filter column...",
        headerFilter: field.showHeaderFilterTextInput
          ? field.headerFilter
          : (cell, onR, success, cancel, eP) => {
              return getHeaderButtons({
                field,
                fn: this.headerButtonClick,
                hoverFn: this.showTooltip,
              });
            },

        headerFilterFunc:
          field.key === "postSecTags"
            ? (headerValue, rowValue, rowData, filterParams) =>
                this.pstTagHeaderFilterFunc(headerValue, rowValue)
            : field.key === "freshSuccessTags"
            ? (headerValue, rowValue, rowData, filterParams) =>
                this.fstTagHeaderFilterFunc(headerValue, rowValue)
            : null,
        headerSort: field.headerSort,
        frozen: field.frozen,
        editor: field.editor,
        editorParams: field.editorParams,
        hozAlign: field.hozAlign || "center",
        headerHozAlign: field.hozAlign || "center",
        width: field.width || 120,
        sorter: field.sorter || null,
        sorterParams: field.sorterParams || null,
        titleFormatter: (cell) => this.titleFormatter(cell, field),
        cellClick: (e, cell) => this.cellClick(e, cell, field),
        contextMenu: this.getContextMenu(),
        cellMouseEnter: (e, cell) => this.cellMouseEnter(e, cell, field),
        cellMouseLeave: (e, cell) => this.cellMouseLeave(e, cell, field),
        accessorDownload: (value, data, type, params, column) =>
          this.accessorDownload(value, field),
      };
    },
    getContextMenu() {
      return [
        {
          OTCOnly: false,
          label: "View Profile",
          action: (e, cell) => this.showDetails(cell),
        },
        {
          OTCOnly: false,
          label: "Add Note",
          action: (e, cell) =>
            this.ee.emit("addTask", {
              mode: "Notes",
              student: cell._cell.row.data,
            }),
        },
        {
          OTCOnly: false,
          label: "Add Task",
          action: (e, cell) =>
            this.ee.emit("addTask", {
              mode: "Tasks",
              student: cell._cell.row.data,
            }),
        },
        {
          OTCOnly: true,
          label: "Add Check-in",
          action: (e, cell) =>
            this.ee.emit("addTask", {
              mode: "Check-ins",
              student: cell._cell.row.data,
            }),
        },
      ].filter((element) => {
        return this.$user.hasPower("viewOTCEvents") ? true : !element.OTCOnly;
      });
    },
    myTagFormatter(cell) {
      let arr = cell.getValue();
      if (!Array.isArray(arr)) arr = [];

      const container = document.createElement("span");

      ["star", "flag", "warning", "help", "done"].forEach((element) => {
        const el1 = document.createElement("span");
        el1.classList.add("material-icons", "my-tag", "my-tag-" + element);
        if (arr.includes(element)) el1.classList.add("my-tag-active");
        el1.innerHTML = element;
        el1.onclick = () => this.toggleMyTag({ cell, icon: element });
        container.appendChild(el1);
      });
      return container;
    },
    toggleMyTag({ cell, icon }) {
      const id = cell._cell.row.data.studentRISEId;
      const index = ["star", "flag", "warning", "help", "done"].indexOf(icon);
      cell._cell.element.childNodes[0].childNodes[index].classList.toggle(
        "my-tag-active"
      );
      this.store.toggleMyTag({ id, icon });
      this.$user.toggleMyTag({ id, icon, user: this.$user, fb: this.fb });
    },
    accessorDownload(value, field) {
      if (field.accessorDownload !== undefined)
        return field.accessorDownload(value);
      if (field.key === "postSecTags") return this.getPostSecTagList(value);
      if (field.key === "freshSuccessTags")
        return this.getFreshSuccessTagList(value);
      const rawValue = Array.isArray(value) ? value[0]?.value : value;
      return getDisplayValue({ value: rawValue, field });
    },
    titleFormatter(cell, field) {
      // set tooltip when user hovers over. In settimeout because this function must wait until the table is fully built to access parentNode.parentNode
      setTimeout(() => {
        const headerEl = cell.getElement()?.parentNode?.parentNode;
        if (!headerEl) return;
        const description = field.userDescription || "";
        headerEl.addEventListener("mouseover", (e) =>
          this.showTooltip(description)
        );
      }, 1000);
      return cell.getValue();
    },
    cellClick(e, cell, field) {
      // user clicks on a cell

      const cellData = cell._cell.row.data;

      // open the tags when user clicks on the tags field
      if (
        field.key === "postSecTags" &&
        this.$user.hasPower("updateStudents")
      ) {
        this.ee.emit("openPostSecTagManager", cellData.studentRISEId);
      }

      if (
        field.key === "freshSuccessTags" &&
        this.$user.hasPower("assignFreshSuccessTags")
      ) {
        this.ee.emit("openFreshSuccessTagManager", cellData.studentRISEId);
      }
    },
    cellDblClick(e, cell) {
      // user double-clicks on a cell

      const myField = this.config.fields.find(
        (field) => field.key === cell._cell.column.field
      );
      if (!myField) return;

      if (myField.key === "_studentName") {
        const profileRoute = this.store.state.routes.find(
          (element) => element.name === "Profile"
        );

        //Check if current grade is allowed to go to student profile page first
        if (
          profileRoute.meta.grades.some(
            (grade) => grade === this.store.state.showingGradeLevel
          )
        ) {
          this.showDetails(cell);
        }
        return;
      }

      if (myField.key === "postSecTags") {
        return;
      }

      if (myField.key === "freshSuccessTags") {
        return;
      }

      if (!this.$user.hasPower("updateStudents")) {
        return;
      }

      if (myField.readOnly) {
        this.notify("This field cannot be edited.");
        return;
      }

      // launch edit modal
      this.ee.emit("launchEditField", {
        key: cell._cell.column.field,
        currentValue: cell._cell.value[0].value,
        id: cell._cell.row.data.studentRISEId,
        studentName: cell._cell.row.data._studentName,
      });
    },
    cellMouseEnter(e, cell, field) {
      this.showTooltip(field.userDescription || "");
      if (field.key !== "courses") {
        this.courses = null;
        return;
      }
      setTimeout(() => {
        this.courses = Object.values(cell.getValue()).filter(
          (element) => element.schoolYear == currentYear
        );
        const ct = document.getElementById("coursesTable");
        if (ct) {
          const top = e.clientY - 150;
          const left = e.clientX < 750 ? e.clientX + 100 : e.clientX - 750;
          ct.style.left = `${left}px`;
          ct.style.top = `${top}px`;
        }
      }, 20);
    },
    cellMouseLeave(e, cell, field) {
      if (field.key !== "courses") return;
      this.courses = null;
    },
    formatCell({
      // set formatters for every cell in tracker, taking into account victory conditions
      cell,
      field,
      colors = this.store.state.ui.colors,
      tags,
    }) {
      if (field.key === "_myTags") return this.myTagFormatter(cell);
      if (field.key === "postSecTags") tags = this.store.state.postSecTags;
      if (field.key === "freshSuccessTags")
        tags = this.store.state.freshSuccessTags;
      return formatCell({
        cell,
        field,
        colors,
        tags,
      });
    },

    // Tags

    pstTagHeaderFilterFunc(headerValue, rowValue, rowData, filterParams) {
      return this.getPostSecTagList(rowValue)
        .toLowerCase()
        .includes(headerValue.toLowerCase());
    },
    fstTagHeaderFilterFunc(headerValue, rowValue, rowData, filterParams) {
      return this.getFreshSuccessTagList(rowValue)
        .toLowerCase()
        .includes(headerValue.toLowerCase());
    },
    getPostSecTagList(value) {
      if (!value[0]) return "";
      const findPostSecTag = (element) =>
        this.store.state.postSecTags.find((tag) => tag.docID == element);

      return value[0].value
        .map((element) => {
          const t = findPostSecTag(element);
          return t?.title ? t.title : "";
        })
        .join(" ");
    },
    getFreshSuccessTagList(value) {
      if (!value[0]) return "";
      const findFreshSuccessTag = (element) =>
        this.store.state.freshSuccessTags.find((tag) => tag.docID == element);

      return value[0].value
        .map((element) => {
          const tag = findFreshSuccessTag(element);
          return tag?.title ? tag.title : "";
        })
        .join(" ");
    },
    download() {
      if (this.$refs.views.selectedFocus === "On-the-cusp") {
        downloadOnTheCusp({
          data: this.store.state.studentData.sort(alphabetizeStudents),
          passingThreshold: this.store.state.schoolMetaData.passingThreshold,
          currentQuarter: this.store.state.currentQuarter,
        });
      } else {
        this.$refs.trackerTable.doMethod("download", "xlsx", "appData.xlsx", {
          sheetName: "App Download Data",
        });
      }
    },

    // Views

    addView(name) {
      this.customViews.unshift({
        name: name,
        custom: true,
        fields: this.visibleColumns(),
        filters: this.filters,
        columnLayout: this.$refs.trackerTable
          .doMethod("getColumnLayout")
          .map((e) => ({ field: e.field })),
      });
    },
    renameView({ oldName, name }) {
      const myView = this.customViews.find(
        (element) => element.name == oldName
      );
      if (!myView) {
        console.warn("view not found");
        return;
      }
      myView.name = name;
    },
    updateView(name) {
      const currentView = this.customViews.find(
        (element) => element.name == name
      );

      currentView.fields = this.visibleColumns();
      currentView.filters = this.filters;
      currentView.columnLayout = this.$refs.trackerTable
        .doMethod("getColumnLayout")
        .map((e) => ({ field: e.field }));

      this.notify("View updated");
    },
    deleteView(name) {
      const currentView = this.customViews.find(
        (element) => element.name == name
      );
      this.customViews = this.customViews.filter(
        (view) => view !== currentView
      );
    },
    setDefaultView() {
      this.applyView();
      this.selectedInsight =
        this.config.gradeConfig[
          this.store.state.showingGradeLevel
        ].trackerConfig.defaultInsight;
    },
    applyView({
      // apply view to tracker table
      customView = null,
      views = this.getViews(),
      selectedFocus = this.$refs.views?.selectedFocus || "",
      setVisibleColumns = this.setVisibleColumns,
      clearFilters = this.clearFilters,
      setFilters = this.$refs.filters?.setFilters || null,
      renderTrackerTable = this.renderTrackerTable,
    } = {}) {
      //clear out custom columns to apply correct view columns
      this.customColumns = null;

      const _view =
        customView || views.find((element) => element.name === selectedFocus);
      if (!_view) return;

      const view =
        _view.name === "Standard"
          ? gradeStandardViews.find((view) =>
              view.grades.includes(this.store.state.showingGradeLevel)
            )
          : _view;

      if (!view) return;

      this.$refs.views.setSelectedFocus(view.name || "");

      clearFilters();

      //flatten objects to array before sending to setVisisbleColumns
      let colNames = [];
      if (view.columnLayout) {
        view.columnLayout.forEach((obj) => colNames.push(obj.field));
      } else {
        //quicker to convert display names to keys here than changing code in summary tables or custom charts.
        //TECH DEBT: both summary tables or custom charts should be sending over keys instead of field display names. We shouldn't have to do the conversion here.
        this.config.fields.forEach((field) => {
          if (view.fields.includes(field.displayName)) colNames.push(field.key);
        });
      }

      setVisibleColumns({ fieldArray: colNames });

      if (view.columnLayout) {
        this.columnOrder = view.columnLayout
          .map((obj) =>
            this.config.fields.find((field) => field.key === obj.field)
          )
          .filter((f) => f !== undefined)
          .map((field) => field.displayName);
      }

      if (setFilters) setFilters(view.filters);
      renderTrackerTable();
    },

    // visible columns
    setVisibleColumns({ fieldArray, fieldGroups = this.config.fieldGroups }) {
      fieldGroups.forEach((group) => {
        group.fields.forEach((field) => {
          field.checked = fieldArray.includes(field.key);
        });
      });
    },

    /**
     * Need to keep track of order of columns when a column moves.
     *
     * @param {array} cols expecting an array of columns coming from tabulator
     */
    columnMovedHandler(cols) {
      this.customColumns = cols;
    },

    //Moved to methods cause computed functions are cached. running into state problems and do not want to cache this anymore.
    //Also computed components shouldn't be mutated afterwards and we do that in several places.
    // return list of all visible columns
    visibleColumns: function () {
      return this.config.fieldGroups
        .map((group) =>
          group.fields
            .filter((element) => element.checked)
            .map((element) => element.name)
        )
        .flat()
        .sort((a, b) =>
          this.columnOrder.indexOf(a) > this.columnOrder.indexOf(b) ? 1 : -1
        );
    },

    // Filters

    addFilter({ filterArr }) {
      if (this.$refs.filters) {
        this.$refs.filters.addFilter({ filterArr });
      }
    },
    setFilters(filters) {
      if (!filters) return;
      this.filters = filters;
      if (!this.loading) {
        this.renderTrackerTable();
      }
    },
    renderFilterIcons() {
      Array.from(
        document.querySelectorAll(".tracker-app .tabulator-col")
      ).forEach((node) => {
        const icon = node.querySelector(":scope .my-header-btn-filter");
        if (!icon) return;
        if (
          this.filters.some(
            (filter) => filter.field == node.getAttribute("tabulator-field")
          )
        ) {
          icon.classList.add("active-filter");
        } else {
          icon.classList.remove("active-filter");
        }
      });
    },
    clearFilters() {
      this.setFilters([]);
      if (this.$refs.filters) {
        this.$refs.filters.clearFilters();
      }
      this.clearHeaderFilters();
    },
    clearHeaderFilters() {
      if (!this.$refs.trackerTable) return;
      this.config.fields
        .filter((field) => field.headerFilter !== undefined)
        .forEach((field) => {
          this.$refs.trackerTable.doMethod(
            "setHeaderFilterValue",
            field.key,
            ""
          );
        });
    },

    // reset local state

    clearLocalState() {
      if (!this.config.fields) return;
      this.studentDataLoaded = false;
      this.noteDataLoaded = false;
      this.clearFilters();
    },
    getViews() {
      return this.customViews.concat(
        defaultViews.sort((a, b) => (a.name > b.name ? 1 : -1))
      );
    },
  },
  computed: {
    filteredInsights: function () {
      return insights.filter((insight) =>
        insight.showInGrades.includes(this.store.state.showingGradeLevel)
      );
    },
  },

  created() {
    this.setEventListeners();
  },
  beforeMount() {
    if (this.store.state.studentData.length) this.studentDataLoaded = true;
  },
  mounted() {
    // apply default views and custom views
    if (this.$user.preferences) {
      this.applyViewPreferences(this.$user.preferences);
    }
  },
  activated() {
    this.$refs.trackerTable.doMethod("scrollToColumn", "_studentName"); // fixes bug of table not displaying correctly when returning to student tracker view

    /* Programmatically set a custom view if one exists.
     * This is set by double-clicking a value on the summary page
     */
    if (this.store.state.customProp) {
      //check if object has keys for view + keyInsight
      const customview =
        "view" in this.store.state.customProp
          ? this.store.state.customProp.view
          : this.store.state.customProp;
      if ("keyInsight" in this.store.state.customProp)
        this.selectedInsight = this.store.state.customProp.keyInsight;

      if (this.studentDataLoaded) this.updateTrackerData;
      this.applyView({ customView: customview });
      this.store.setCustomProp(null);
    }

    // Send pageview to posthog
    this.posthog.capture("$pageview", {
      $current_url: "/tracker",
    });
  },
};
</script>

/** Component wrapper for Tabulator. */
<template>
  <div :class="tableClass">
    <div ref="table"></div>
  </div>
</template>

<script>
import Tabulator from "tabulator-tables";
import { customTabulatorFilters } from "@/functions/customTabulatorFilters";
Tabulator.prototype.extendModule("filter", "filters", customTabulatorFilters);

export default {
  name: "MyTable",
  props: {
    tableData: Array,
    columns: Array,
    customConfig: Object,
    showAsInlineTable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tabulator: null,
      config: {},
    };
  },
  methods: {
    // Maps tabulator methods to component methods
    doMethod(method, ...args) {
      return this.tabulator[method](...args);
    },
    render({ filters }) {
      const myFilters = filters.filter((element) => element !== undefined);
      this.tabulator.setFilter(myFilters);
      this.tabulator.redraw();
    },
    launch({ columns = this.columns } = {}) {
      this.config = {
        height: this.customConfig.height || "100%",
        layout: this.customConfig.layout || "fitColumns",
        reactiveData: this.customConfig.reactiveData || false,
        data: this.tableData,
        columns: columns,
        movableColumns: true,
        cellClick: this.customConfig.cellClick || null,
        cellDblClick: this.customConfig.cellDblClick || null,
        rowFormatter: (row) => {
          row.getElement().classList.add("custom-row-default");
        },

        //rows is an array of row components from tabulator
        dataFiltered: (filters, rows) => {
          //have to call getData to actually get row data
          const rowData = rows.map((row) => row.getData());
          this.ee.emit("dataFiltered", rowData);
        },

        /**
         * This is a callback handler that tabulator calls after a column has moved.
         *
         * @param {object} column special tabulator column component of the moved column
         * @param {array} columns array of special tabulator column components in the new order
         */
        columnMoved: (column, columns) => {
          const cols = columns.map((column) => column.getDefinition());
          this.ee.emit("columnMoved", cols);
        },
      };

      this.tabulator = new Tabulator(this.$refs.table, this.config);
    },
  },
  computed: {
    tableClass: function () {
      return this.showAsInlineTable
        ? "table-container-inline"
        : "table-container";
    },
  },
  mounted() {
    this.launch();
  },
};
</script>

<style lang="scss">
@import "../assets/styles/tabulator_custom.scss";
@import "../assets/styles/tableStyles.scss";
</style>

/** This modal shows the data security notice. */
<template>
  <Modal
    ref="securityModal"
    title="Data Security Reminders"
    instructions=""
    @success="agree"
    :disableSuccess="!agreed"
    acceptButtonText="Continue"
    :showXOut="false"
    :showCancelButton="false"
    :wide="true"
  >
    <div class="security-modal-area">
      <div class="security-title">Before you get started...</div>
      <p>Data security and student privacy are of paramount importance.</p>
      <p>
        Please watch the security video below to affirm your commitment to
        maintaining data security.
      </p>
      <p>
        Thank you for using RISE data tools to promote equitable and excellent
        student outcomes.
      </p>
      <iframe
        class="training-video-frame"
        width="456"
        height="288"
        src="https://www.youtube.com/embed/OTeN0lAvPho"
        title="YouTube video player"
        frameborder="0"
        allowfullscreen
      >
      </iframe>
      <div class="agreement-area">
        <input
          type="checkbox"
          id="haveWatchedVideo"
          name="haveWatchedVideo"
          v-model="agreed"
        />
        <label for="haveWatchedVideo"
          >I have watched the data security video.</label
        >
      </div>
    </div>
  </Modal>
</template>

<script>
import Modal from "@/components/modals/modal.vue";

export default {
  components: { Modal },
  data() {
    return {
      agreed: false,
    };
  },
  methods: {
    show() {
      this.$refs.securityModal.show();
    },
    agree() {
      this.fb.user.agreeToDataPolicy();
    },
  },
  mounted() {
    this.ee.on("launchSecurityModal", () => this.show());
  },
};
</script>

<style lang="scss">
.security-modal-area {
  .security-title {
    font-weight: bold;
    font-size: 2.1rem;
    padding: 1rem 0 0;
  }
  .agreement-area {
    padding: 10px 0;
  }
}
</style>

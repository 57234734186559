/**
 * This contains the configuration of each route (url) used in the app, mapped to the correct view component to use.
 */

//TECH DEBT: Move the roles to a cloud function to encapsulate the code from the browswer.

import { createRouter, createWebHistory } from "vue-router";
import Login from "../views/Login.vue";
import Home from "../views/Home.vue";
import { getAuth } from "firebase/auth";

export const routes = [
  {
    path: "/tracker",
    name: "Student Lists",
    meta: {
      grades: [8, 9, 10, 11, 12],
      key: "tracker",
      icon: "format_list_bulleted",
      roles: [
        "viewer",
        "counselor",
        "riseStaff",
        "calendarManager",
        "OTCManager",
        "schoolAdmin",
        "superuser",
        "OTC",
        "leadCounselor",
      ],
    },
    userDescription: "View and customize lists of students.",
    component: () =>
      import(/* webpackChunkName: "tracker" */ "@/views/Tracker.vue"),
  },
  {
    path: "/profile",
    name: "Profile",
    meta: {
      grades: [9, 10, 11, 12],
      key: "profile",
      icon: "person",
      roles: [
        "viewer",
        "counselor",
        "riseStaff",
        "calendarManager",
        "OTCManager",
        "schoolAdmin",
        "superuser",
        "OTC",
        "leadCounselor",
      ],
    },
    userDescription: "View detailed information for individual students.",
    component: () =>
      import(
        /* webpackChunkName: "studentProfile" */ "@/views/StudentProfile.vue"
      ),
  },
  {
    path: "/summarytables",
    name: "Summary Tables",
    meta: {
      grades: [11, 12],
      key: "summarytables",
      icon: "table_view",
      roles: [
        "viewer",
        "counselor",
        "riseStaff",
        "calendarManager",
        "OTCManager",
        "schoolAdmin",
        "superuser",
        "OTC",
        "leadCounselor",
      ],
    },
    userDescription: "View summary tables.",
    component: () =>
      import(
        /* webpackChunkName: "summaryTables" */ "@/views/SummaryTables.vue"
      ),
  },
  {
    path: "/protocols",
    name: "Protocols",
    meta: {
      grades: [9],
      key: "protocols",
      icon: "chat",
      roles: [
        "viewer",
        "counselor",
        "riseStaff",
        "calendarManager",
        "OTCManager",
        "schoolAdmin",
        "superuser",
        "OTC",
        "leadCounselor",
      ],
    },
    userDescription: "Use curated charts to facilitate data conversations.",
    component: () =>
      import(/* webpackChunkName: "slides" */ "@/views/Slides.vue"),
  },
  {
    path: "/analytics",
    name: "Custom Charts",
    meta: {
      grades: [8, 9, 10, 11, 12],
      key: "analytics",
      icon: "bar_chart",
      roles: [
        "viewer",
        "counselor",
        "riseStaff",
        "calendarManager",
        "OTCManager",
        "schoolAdmin",
        "superuser",
        "OTC",
        "leadCounselor",
      ],
    },
    userDescription: "Create your own summary data charts and tables.",
    component: () =>
      import(/* webpackChunkName: "analytics" */ "@/views/Analytics.vue"),
  },
  {
    path: "/activities",
    name: "My Activities",
    meta: {
      grades: [9, 10, 11, 12],
      key: "activities",
      icon: "task",
      roles: [
        "viewer",
        "counselor",
        "riseStaff",
        "calendarManager",
        "OTCManager",
        "schoolAdmin",
        "superuser",
        "OTC",
        "leadCounselor",
      ],
    },
    userDescription: "Keep track of tasks, notes, and student check-ins.",
    component: () =>
      import(/* webpackChunkName: "activities" */ "@/views/Activities.vue"),
  },
  {
    path: "/calendar",
    name: "Strategic Data Calendar",
    meta: {
      grades: [9, 10, 11, 12],
      key: "calendar",
      icon: "calendar_month",
      roles: [
        "viewer",
        "counselor",
        "riseStaff",
        "calendarManager",
        "OTCManager",
        "schoolAdmin",
        "superuser",
        "OTC",
        "leadCounselor",
      ],
    },
    userDescription: "See important data-driven events for this grade.",
    component: () =>
      import(/* webpackChunkName: "calendar" */ "@/views/Calendar.vue"),
  },
  {
    path: "/settings",
    name: "Settings",
    meta: {
      key: "settings",
      grades: [],
      icon: "settings",
      roles: [
        "viewer",
        "counselor",
        "riseStaff",
        "calendarManager",
        "OTCManager",
        "schoolAdmin",
        "superuser",
        "OTC",
        "leadCounselor",
      ],
    },
    userDescription: "Update your personal settings.",
    component: () =>
      import(/* webpackChunkName: "settings" */ "@/views/Settings.vue"),
  },
  {
    path: "/",
    name: "Login",
    component: Login,
    meta: {
      hideNavigation: true,
      key: "login",
      grades: [],
      icon: "login",
    },
    userDescription: "Log in",
  },
  {
    path: "/home",
    name: "Home",
    component: Home,
    meta: {
      key: "home",
      grades: [],
      icon: "home",
      roles: [
        "viewer",
        "counselor",
        "riseStaff",
        "calendarManager",
        "OTCManager",
        "schoolAdmin",
        "superuser",
        "OTC",
        "leadCounselor",
      ],
    },
    userDescription: "RISE Data Hub home page",
  },
  {
    path: "/login-reports",
    name: "Login Reports",
    component: () =>
      import(/* webpackChunkName: "loginReports" */ "@/views/LoginReports.vue"),
    meta: {
      key: "loginReports",
      grades: [],
      icon: "assessment",
      roles: ["riseStaff", "superuser"],
    },
    userDescription: "RISE Data Hub login reports",
  },
  {
    path: "/reports-center",
    name: "Reports Center",
    component: () =>
      import(
        /* webpackChunkName: "reportsCenter" */ "@/views/ReportsCenter.vue"
      ),
    meta: {
      key: "reportsCenter",
      grades: [8, 9, 10, 11, 12],
      icon: "assignment",
      roles: ["superuser"],

      // These are make-shift feature flags. We will be creating feature flags in firestore
      // at a later time. When false, the Reports Center will not be shown.
      featureFlag: false,
    },
    userDescription: "Generate and download reports.",
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from) => {
  //check to see if route is behind a feature flag
  if ("featureFlag" in to.meta && to.meta.featureFlag === false) {
    return "/";
  }

  //check to see if user is already logged in
  const auth = getAuth();
  if (to.name === "Login" && auth.currentUser)
    this.session.processLogIn(auth.currentUser);

  if (to.name !== "Login" && !auth.currentUser) {
    return "/";
  } else {
    document.title = to.name;
    return true;
  }
});

export default router;

<template>
  <div id="login-reports-app">
    <Sidebar>
      <div class="sidebar-section">
        <div class="sidebar-section-title">Display Options</div>
        <div class="sidebar-section-padded">
          <CustomSelect
            data-cy="login-analytics-mode-dropdown"
            :options="modes"
            title="Show"
            v-model="mode"
          />
        </div>
        <div class="sidebar-section-padded">
          <CustomSelect
            :options="schools"
            title="School/Network"
            v-model="school"
            data-cy="login-analytics-school-select"
          />
        </div>
        <div class="sidebar-section-padded" v-show="mode === 'Single Week'">
          <CustomSelect
            :options="weeks"
            title="Week"
            v-model="week"
            data-cy="login-analytics-week-select"
          />
        </div>
        <div class="sidebar-section-padded" v-show="mode === 'Multi-week'">
          <CustomSelect
            :options="weeks"
            title="Start Week"
            v-model="startWeek"
          />
        </div>
        <div class="sidebar-section-padded" v-show="mode === 'Multi-week'">
          <CustomSelect :options="weeks" title="End Week" v-model="endWeek" />
        </div>
      </div>
    </Sidebar>
    <div class="main-area-narrow main-area-indented">
      <MyChart
        data-cy="login-analytics-single-chart"
        v-show="mode === 'Single Week'"
        :chartData="chartData"
        :chartConfig="chartConfig"
        ref="loginChart"
      />
      <MyChart
        data-cy="login-analytics-multi-chart"
        v-show="mode === 'Multi-week'"
        :chartData="chartData"
        :chartConfig="weeklyChartConfig"
        ref="weeklyLoginChart"
      />
    </div>
  </div>
</template>

<script>
import Sidebar from "@/components/nav/sidebar.vue";
import CustomSelect from "@/components/customSelect.vue";
import MyChart from "@/components/charts.vue";
import { beautifyDate } from "@/functions/utils";

export default {
  data() {
    return {
      modes: ["Single Week", "Multi-week"].map((element) => ({
        displayName: element,
      })),
      mode: "Single Week",
      schools: this.store.state.schools
        .map((school) => school.displayName)
        .sort()
        .concat(["RISE Staff", "Network"])
        .map((element) => ({ displayName: element })),
      school: "Network",
      week: "",
      startWeek: "",
      endWeek: "",
    };
  },
  inject: ["posthog"],
  activated() {
    // Send pageview to posthog
    this.posthog.capture("$pageview", {
      $current_url: "/login-reports",
    });
  },
  methods: {
    getWeekIndex({ data, date }) {
      if (!date) {
        this.setWeekOptions();
        date = this.week;
      }
      return data
        .map((element) => beautifyDate(element.weekStartingDate))
        .indexOf(date);
    },
    setWeekOptions() {
      const loginData = this.store.state.loginAnalytics;
      this.week = beautifyDate(
        loginData[loginData.length - 1]?.weekStartingDate
      );
      this.startWeek = beautifyDate(loginData[0]?.weekStartingDate);
      this.endWeek = beautifyDate(
        loginData[loginData.length - 1]?.weekStartingDate
      );
    },
  },
  watch: {
    chartData: function () {
      this.setWeekOptions();
    },
  },
  computed: {
    chartData: function () {
      return this.store.state.loginAnalytics;
    },
    weeks: function () {
      const loginData = this.store.state.loginAnalytics;
      return loginData.map((element) => ({
        displayName: beautifyDate(element.weekStartingDate),
      }));
    },
    chartConfig: function () {
      return {
        type: "loginAnalyticsBar",
        school: this.school,
        schoolKey:
          this.store.state.schools.find(
            (school) => school.displayName === this.school
          )?.key || "RISE",
        weekIndex: this.getWeekIndex({
          data: this.store.state.loginAnalytics,
          date: this.week,
        }),
        week: this.week,
      };
    },
    weeklyChartConfig: function () {
      return {
        type: "loginAnalyticsLine",
        school: this.school,
        schoolKey:
          this.store.state.schools.find(
            (school) => school.displayName === this.school
          )?.key || "RISE",
        startWeekIndex: this.getWeekIndex({
          data: this.store.state.loginAnalytics,
          date: this.startWeek,
        }),
        endWeekIndex: this.getWeekIndex({
          data: this.store.state.loginAnalytics,
          date: this.endWeek,
        }),
        weekIndex: 0,
      };
    },
  },
  beforeCreate() {
    this.store.initializeLoginAnalytics();
  },
  components: {
    Sidebar,
    CustomSelect,
    MyChart,
  },
};
</script>

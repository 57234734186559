/** This is the Student Profile view which displays the student profile. */
<template>
  <div class="details-app">
    <Modal
      ref="profileDownloadModal"
      title="Download Profile PDF"
      instructions="Select which sections will appear on the PDF"
      @success="download"
    >
      <MultipleChoice
        :options="downloadAreas"
        v-model="selectedDownloadAreas"
        formKey="areasToShow"
      />
    </Modal>
    <Sidebar>
      <div class="sidebar-section" v-if="store.state.showingGradeLevel > 10">
        <div class="sidebar-section-title">Viewing Mode</div>
        <div class="select-student-area">
          <CustomSelect
            :options="viewModes"
            title=""
            v-model="chosenViewMode"
          />
        </div>
      </div>
      <div class="sidebar-section">
        <div class="sidebar-section-title">Choose a student</div>
        <div data-cy="select-student-area" class="select-student-area">
          <CustomSelect
            data-cy="select-student-area"
            :options="studentNames"
            displayField="name"
            title="Select Student"
            v-model="chosenStudentName"
            @selectedIndex="selectStudent"
          />
        </div>
        <div class="student-search-area">
          <p>Search for student</p>
          <input
            v-model="searchString"
            type="text"
            data-cy="search-for-student"
            @keydown.enter="submitSearch"
            @keydown.down="nextStudent"
            @keydown.up="previousStudent"
          />
        </div>
        <div class="list-of-students" data-cy="list-of-students">
          <div
            v-for="(item, index) in matchingStudents"
            :key="index"
            :class="item.class"
            class="student-lookup-entry"
            data-cy="student-lookup-entry"
            @mouseover="dropdownIndex = index"
            @click="submitSearch"
          >
            {{ item.name }}
          </div>
        </div>
        <CustomButton
          v-if="chosenStudentName"
          @click="showDownloadModal"
          buttonText="Download"
        />
        <CustomButton
          v-if="chosenStudentName && showOnTrackDownload"
          @click="reportDownload"
          buttonText="On-Track Report"
        />
      </div>
      <hr class="sidebar-division" />
      <Info />
    </Sidebar>
    <div class="main-area-narrow main-area-indented student-details-area">
      <div v-if="chosenStudentName" data-cy="student-details">
        <div v-if="chosenViewMode == 'Counselor View'">
          <StudentFieldList :selectedStudent="selectedStudent" :logs="logs" />
        </div>
        <div v-else>
          <h1 class="headline-medium contrast-full">
            {{ selectedStudent.firstName }} {{ selectedStudent.lastName }}
          </h1>
          <span class="body-large contrast-higher">
            Student ID: {{ selectedStudent.studentDistrictId }}
          </span>
          <div class="ribbon">
            Student Details
            <span class="profile-tag-area" data-cy="profile-tag-area">
              <span
                class="material-icons my-tag my-tag-star"
                :class="getTagClass(selectedStudent, 'star')"
                @click="toggleMyTag('star')"
                >star</span
              >
              <span
                class="material-icons my-tag my-tag-flag"
                :class="getTagClass(selectedStudent, 'flag')"
                @click="toggleMyTag('flag')"
                >flag</span
              >
              <span
                class="material-icons my-tag my-tag-warning"
                :class="getTagClass(selectedStudent, 'warning')"
                @click="toggleMyTag('warning')"
                >warning</span
              >
              <span
                class="material-icons my-tag my-tag-help"
                :class="getTagClass(selectedStudent, 'help')"
                @click="toggleMyTag('help')"
                >help</span
              >
              <span
                class="material-icons my-tag my-tag-done"
                :class="getTagClass(selectedStudent, 'done')"
                @click="toggleMyTag('done')"
                >done</span
              >
            </span>
            <span class="fst-tag-area">
              <span
                v-for="(tag, index) in freshSuccessTags"
                :key="index"
                class="fst-tag-entry"
              >
                <span class="badge" :style="getTagStyle(tag)">
                  {{ tag.title }}
                </span>
              </span>
            </span>
            <span class="pst-tag-area">
              <span
                v-for="(tag, index) in postSecTags"
                :key="index"
                class="pst-tag-entry"
              >
                <span class="badge" :style="getTagStyle(tag)">
                  {{ tag.title }}
                </span>
              </span>
            </span>
          </div>
          <FieldArea
            :fields="profileFields.studentDetails"
            :student="selectedStudent"
          />
          <div class="ribbon">
            <span
              class="focus-title"
              :class="focus == 'ontrack' ? 'active' : ''"
              @click="focus = 'ontrack'"
              >Current Year Summary</span
            >
            <span
              class="focus-title"
              :class="focus == 'attendance' ? 'active' : ''"
              @click="focus = 'attendance'"
              data-cy="profile-attendance"
              >Attendance</span
            >
            <span
              class="focus-title"
              :class="focus == 'behavior' ? 'active' : ''"
              @click="focus = 'behavior'"
              >Behavior</span
            >
            <span
              class="focus-title"
              :class="focus == 'credits' ? 'active' : ''"
              @click="focus = 'credits'"
              >Credits</span
            >
          </div>
          <div v-show="focus == 'ontrack'">
            <FieldArea :fields="onTrackFields" :student="selectedStudent" />
          </div>
          <div v-show="focus == 'attendance'">
            <FieldArea
              :fields="profileFields.attendance"
              :student="selectedStudent"
            />
          </div>
          <div v-show="focus == 'behavior'">
            <FieldArea
              :fields="profileFields.behavior"
              :student="selectedStudent"
            />
          </div>
          <div v-show="focus == 'credits'">
            <FieldArea
              :fields="creditsPerGrade(profileFields.credit)"
              :student="selectedStudent"
            />
          </div>
          <div class="ps-area" v-if="selectedStudent.currentGradeLevel >= 11">
            <div class="ribbon">
              <span
                class="focus-title"
                :class="psFocus == 'main' ? 'active' : ''"
                @click="psFocus = 'main'"
                >Postsecondary</span
              >
              <span
                class="focus-title"
                :class="psFocus == 'college' ? 'active' : ''"
                @click="psFocus = 'college'"
                >College</span
              >
              <span
                class="focus-title"
                :class="psFocus == 'military' ? 'active' : ''"
                @click="psFocus = 'military'"
                >Military</span
              >
              <span
                class="focus-title"
                :class="psFocus == 'workforce' ? 'active' : ''"
                @click="psFocus = 'workforce'"
                >Workforce</span
              >
            </div>
            <div v-show="psFocus == 'main'">
              <FieldArea
                :fields="profileFields.ps"
                :student="selectedStudent"
                :tall="true"
              />
            </div>
            <div v-show="psFocus == 'college'">
              <FieldArea
                :fields="profileFields.college"
                :student="selectedStudent"
                :tall="true"
              />
            </div>
            <div v-show="psFocus == 'military'">
              <FieldArea
                :fields="profileFields.military"
                :student="selectedStudent"
                :tall="true"
              />
            </div>
            <div v-show="psFocus == 'workforce'">
              <FieldArea
                :fields="profileFields.workforce"
                :student="selectedStudent"
                :tall="true"
              />
            </div>
          </div>
          <div class="ribbon">
            <span
              class="focus-title"
              :class="details == 'courses' ? 'active' : ''"
              @click="details = 'courses'"
              >Current Year Courses</span
            >
            <!-- <span class="focus-title" :class="details=='history'?'active':''" @click="details='history'">Past Courses</span> -->
            <span
              v-if="store.state.showingGradeLevel > 8"
              class="focus-title"
              :class="details == 'summary' ? 'active' : ''"
              @click="details = 'summary'"
            >
              Cumulative Summary
            </span>
            <span
              v-if="logs.length"
              class="focus-title"
              :class="details == 'log' ? 'active' : ''"
              @click="details = 'log'"
              >Log</span
            >
          </div>
          <div v-show="details == 'courses'">
            <CoursesTable
              :courses="getCurrentCourses(selectedStudent)"
              :detailed="true"
            />
          </div>
          <!-- <div v-show="details=='history'">
                        <PastCoursesTable :selectedStudent="selectedStudent"  />
                    </div> -->
          <div v-show="details == 'summary'">
            <CareerSummary :selectedStudent="selectedStudent" />
          </div>
          <div v-show="details == 'log'">
            <table
              v-if="logs.length"
              data-cy="activity-table"
              class="activity-table"
            >
              <colgroup>
                <col class="date-col" />
                <col class="user-col" />
                <col class="update-col" />
              </colgroup>
              <tr>
                <th>Date</th>
                <th>User</th>
                <th>Update</th>
              </tr>
              <tr v-for="(log, index) in logs" :key="index">
                <td>{{ log.date }}</td>
                <td>{{ log.author }}</td>
                <td>{{ log.field }} → {{ log.value }}</td>
              </tr>
            </table>
          </div>
          <div class="ribbon">
            <span class="focus-title active">Activities</span>
          </div>
          <div class="activity-container">
            <ActivityArea
              :notes="notes"
              :tasks="tasks"
              :checkins="checkins"
              :selectedStudent="selectedStudent"
            />
          </div>
        </div>
      </div>
      <div v-else>
        <h1
          class="headline-medium contrast-full"
          data-cy="no-student-selected-header"
        >
          Select a student
        </h1>
        <p>Select a student to view a student profile.</p>
      </div>
    </div>
  </div>
</template>

<script>
// import PastCoursesTable from '@/components/profile/pastCoursesTable.vue';
import CareerSummary from "@/components/profile/careerSummary.vue";
import StudentFieldList from "@/components/profile/studentFieldList.vue";
import FieldArea from "@/components/profile/fieldArea.vue";
import ActivityArea from "@/components/profile/activityArea.vue";

import Sidebar from "@/components/nav/sidebar.vue";
import Modal from "@/components/modals/modal.vue";
import MultipleChoice from "@/components/multipleChoice.vue";
import CustomSelect from "@/components/customSelect.vue";
import CustomButton from "@/components/customButton.vue";
import Info from "@/components/nav/info.vue";
import {
  alphabetizeStudents,
  blackOrWhite,
  getDisplayValue,
  currentYear,
} from "@/functions/utils.js";
import CoursesTable from "@/components/coursesTable.vue";
import { getDetailsPDF } from "@/functions/detailsPDF.js";
import {
  downloadAreas,
  PSTareas,
  profileFields,
  getAreasToShow,
} from "@/functions/profileFunctions.js";
import { generatePDF } from "@/functions/onTrackPdf.js";

// PDFMake library
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

export default {
  name: "Student Profile",
  data() {
    return {
      profileFields,
      studentIndex: 0,
      selectedDownloadAreas: this.getDownloadAreas().join(","),
      chosenStudentName: "",
      fields: this.config.fields.filter(
        (element) => element.type !== "notes" && element.type !== "tags"
      ),
      searchString: "",
      dropdownIndex: 0,
      courses: null,
      details: "courses",
      focus: "ontrack",
      psFocus: "main",
      downloadAreas: this.getDownloadAreas(),
      viewModes: ["Default", "Counselor View"].map((e) => ({ displayName: e })),
      chosenViewMode: "Default",
      showOnTrackDownload: false,
    };
  },
  inject: ["posthog"],
  watch: {
    searchString: function () {
      if (this.dropdownIndex > this.matchingStudents.length - 1)
        this.dropdownIndex = 0;
    },
  },
  methods: {
    showDownloadModal() {
      if (!this.chosenStudentName) return;
      this.$refs.profileDownloadModal.show();
    },
    getDownloadAreas() {
      return downloadAreas.filter((area) =>
        this.store.state.showingGradeLevel > 10
          ? true
          : !PSTareas.includes(area)
      );
    },
    getCurrentCourses(student) {
      return Object.values(student.courses).filter(
        (element) => element.schoolYear == currentYear
      );
    },
    getTagClass(student, icon) {
      return student._myTags.includes(icon) ? "my-tag-active" : "";
    },
    toggleMyTag(icon) {
      const id = this.selectedStudent.studentRISEId;
      this.store.toggleMyTag({ id, icon });
      this.$user.toggleMyTag({ id, icon, user: this.$user, fb: this.fb });
      this.ee.emit("tagDataReceived"); // force tracker table refresh
    },
    getOnTrackFields() {
      let result = [];
      if (!this.selectedStudent) return result;
      if (this.selectedStudent.currentGradeLevel == 9) {
        //not sure why G8 R&O is included in this list?!?
        const OTFields = [
          "Q4OnTrackStatus",
          "Q3OnTrackStatus",
          "Q2OnTrackStatus",
          "Q1OnTrackStatus",
          "g9RiskAndOpportunity.riskAndOpportunity",
        ];
        const myField = OTFields.find(
          (field) => this.selectedStudent[field] !== null
        );
        if (myField) result.push(myField);
        result.push("_currentQuarterOnTrackStatus");
      }
      return result.concat([
        "ADA",
        "GPA",
        "entryDate",
        "g9RiskAndOpportunity.riskAndOpportunity",
      ]);
    },
    initialize() {
      this.studentIndex = 0;
      this.chosenStudentName = "";
      this.downloadAreas = this.getDownloadAreas();
      this.selectedDownloadAreas = this.getDownloadAreas().join(",");
    },
    nextStudent() {
      this.dropdownIndex++;
      if (this.dropdownIndex > this.matchingStudents.length - 1)
        this.dropdownIndex = 0;
    },
    previousStudent() {
      this.dropdownIndex--;
      if (this.dropdownIndex < 0)
        this.dropdownIndex = this.matchingStudents.length - 1;
    },
    submitSearch() {
      const myStudent = this.matchingStudents.find(
        (element) => element.class === "selected"
      );
      if (!myStudent) return;
      this.chosenStudentName = myStudent.name;
      this.selectStudent(myStudent.index);
      this.searchString = "";
    },
    selectStudent(i) {
      this.studentIndex = i;
    },
    getDocs(docType) {
      if (!this.selectedStudent) return [];
      return this.store.state.notes
        .filter((element) => element.docType == docType)
        .filter((element) => element.uid == this.$user.uid)
        .filter((element) =>
          element.studentArr.some(
            (student) => student.id == this.selectedStudent.studentRISEId
          )
        );
    },
    getPostSecTags() {
      const value = this.selectedStudent?.postSecTags?.[0]?.value;
      if (!value) return [];
      return this.store.state.postSecTags.filter((tag) =>
        value.includes(tag.docID)
      );
    },
    getTagStyle(tag) {
      return `background-color: #${tag.color}; color: ${blackOrWhite(
        tag.color
      )};`;
    },
    getFreshSuccessTags() {
      const value = this.selectedStudent?.freshSuccessTags?.[0]?.value;
      if (!value) return [];
      return this.store.state.freshSuccessTags.filter((tag) =>
        value.includes(tag.docID)
      );
    },
    download() {
      const areasToShow = getAreasToShow({
        getOnTrackFields: this.getOnTrackFields,
        notes: this.notes,
        tasks: this.tasks,
        checkins: this.checkins,
      }).filter((area) =>
        this.selectedDownloadAreas
          .split(",")
          .some((element) => element == area.title)
      );

      const fileName = `${this.selectedStudent.lastName}_${this.selectedStudent.firstName}_${this.selectedStudent.studentDistrictId}`;

      pdfMake
        .createPdf(
          getDetailsPDF({
            student: this.selectedStudent,
            areasToShow,
          })
        )
        .download(fileName);
    },
    reportDownload() {
      const areasToShow = getAreasToShow({
        getOnTrackFields: this.getOnTrackFields,
        notes: this.notes,
        tasks: this.tasks,
        checkins: this.checkins,
      }).filter((area) =>
        this.selectedDownloadAreas
          .split(",")
          .some((element) => element == area.title)
      );
      const currentSchool = this.store.state.chosenSchool;
      const schoolName = this.store.state.schools.find(
        (school) => school.key === currentSchool
      )?.displayName;
      generatePDF({ student: this.selectedStudent, areasToShow, schoolName });
    },
    //need to filter by grade - only show past grades
    creditsPerGrade(creditFields) {
      let grades = [8, 9, 10, 11, 12];

      //filter out grades that are equal to or above the student's grade
      grades = grades.filter(
        (year) => year < this.selectedStudent.currentGradeLevel
      );

      //filter credit fields based on what grades we want to show
      return creditFields.filter(
        (field) =>
          field === "creditsPredicted" ||
          grades.some((grade) => field.includes(grade))
      );
    },
  },
  components: {
    Sidebar,
    CustomSelect,
    CustomButton,
    CoursesTable,
    // PastCoursesTable,
    FieldArea,
    Info,
    CareerSummary,
    ActivityArea,
    Modal,
    MultipleChoice,
    StudentFieldList,
  },
  computed: {
    selectedStudent: function () {
      return this.store.state.studentData[this.studentIndex];
    },
    notes: function () {
      return this.getDocs("note");
    },
    tasks: function () {
      return this.getDocs("task").sort((a, b) =>
        a.dueDate < b.dueDate ? -1 : 1
      );
    },
    checkins: function () {
      return this.getDocs("check-in").sort((a, b) =>
        a.date > b.date ? -1 : 1
      );
    },
    onTrackFields: function () {
      return this.getOnTrackFields();
    },
    postSecTags: function () {
      return this.getPostSecTags();
    },
    freshSuccessTags: function () {
      return this.getFreshSuccessTags();
    },
    matchingStudents: function () {
      if (!this.searchString) return [];
      return this.studentNames
        .filter((name) =>
          name.name.toLowerCase().includes(this.searchString.toLowerCase())
        )
        .slice(0, 20)
        .map((element, i) => ({
          name: element.name,
          class: i == this.dropdownIndex ? "selected" : "",
          index: element.index,
        }));
    },
    studentNames: function () {
      const students = this.store.state.studentData.map((student, i) => ({
        name: [].concat(student.lastName, student.firstName).join(", "),
        index: i,
      }));

      return students.sort(alphabetizeStudents);
    },
    logs: function () {
      // return activity log for student
      if (!this.selectedStudent) return [];

      const student = this.selectedStudent;

      let result = [];
      this.fields.forEach((field) => {
        const key = field.key;
        if (!key) return;
        if (key === "_myTags") return;

        const fieldContent = (() => {
          if (!key.includes(".")) return student[key];
          const keypath = key.split(".");
          return student[keypath[0]][keypath[1]];
        })();

        if (!fieldContent) return;
        if (!Array.isArray(fieldContent)) return; // editable data is stored in an array of update logs.
        if (fieldContent.length <= 1) return; // if data has ever been edited

        // add update(s) to result
        fieldContent.forEach((entry) => {
          result.push({
            date: new Date(entry.timestamp).toDateString(),
            timestamp: entry.timestamp,
            author: entry.displayName || "",
            field: field.displayName,
            value: getDisplayValue({ value: entry.value, field }),
          });
        });

        result.pop(); // remove original field setting. We only want the updates
      });

      // sort all updates by creation time
      return result.sort((a, b) => (a.timestamp > b.timestamp ? 1 : -1));
    },
  },
  mounted() {
    this.ee.on("clearLocalState", () => {
      this.initialize();
    });
    this.ee.on("gradeLevelChange", (newLevel) => {
      this.initialize();
    });
    this.posthog.onFeatureFlags(() => {
      if (this.posthog.isFeatureEnabled("internal-on-track-reports")) {
        this.showOnTrackDownload = true;
      }
    });
  },
  activated() {
    // when navigating to details page from tracker, requesting specific student
    if (this.store.state.customProp) {
      const student = this.store.state.studentData.find(
        (student) => student.studentRISEId == this.store.state.customProp.id
      );
      this.studentIndex = this.store.state.studentData.indexOf(student);
      this.chosenStudentName = this.store.state.customProp.name;
      this.store.setCustomProp(null);
    }

    // Send pageview to posthog
    this.posthog.capture("$pageview", {
      $current_url: "/profile",
    });
  },
};
</script>

<style lang="scss" scoped>
// @import '../assets/styles/colors';
@import "../assets/styles/tableStyles";
@import "../assets/styles/typography.scss";

//temp solution cause I don't know where else we use h1 and don't want to break anything yet.
h1 {
  margin: 0;
}

.details-app {
  .details-badge {
    margin-right: 5px;
  }

  .list-of-students {
    position: absolute;
    z-index: 1;
  }

  .tag-area {
    margin: 12px 0 12px;
  }

  .student-details-area {
    overflow: auto;
    height: calc(100vh - 50px);
  }

  .entry {
    font-weight: bold;
  }

  .victory {
    color: var(--color-success);
  }

  .no-victory {
    color: var(--color-warning);
  }

  .no-data {
    color: var(--color-contrast-medium);
  }

  .activity-table {
    table-layout: fixed;
  }

  td,
  th {
    width: 250px;
    text-align: left;
  }

  h3 {
    font-size: 2rem;
    padding: 20px 0px 0px;
  }

  th {
    // font-size: 2rem;
    padding: 5px 0px;
  }

  .date-col {
    width: 100px;
  }

  .user-col {
    width: 100px;
  }

  .update-col {
    width: 600px;
  }

  .courses-heading {
    font-weight: bold;
    font-size: 2rem;
    padding: 20px 0px 0px;
  }

  .course-table {
    margin-top: 2rem;
    .gradeCol {
      text-align: center;
      width: 6%;
    }
  }
}

.student-search-area {
  margin-left: 5px;
  margin-top: 20px;
  margin-bottom: 20px;
  input {
    width: 224px;
    border-radius: 5px;
    height: 30px;
    margin-top: 5px;
    border: none;
  }
}

.student-lookup-entry {
  margin-left: 5px;
  padding: 4px;
  background-color: var(--color-bg);
  width: 220px;
}

.selected {
  background-color: var(--color-primary) !important;
  color: var(--color-bg);
  cursor: pointer;
}

.select-student-area {
  margin-left: 5px;
  margin-top: 5px;
}

.ribbon {
  background-color: var(--color-primary);
  color: var(--color-bg);
  padding: 10px;
  font-size: 2rem;
  margin: 2rem 0 0;
  .focus-title {
    color: var(--color-contrast-medium);
    margin-right: 20px;
    &:hover {
      cursor: pointer;
      color: var(--color-bg);
    }
    &.active {
      color: var(--color-bg);
    }
  }
}

.profile-tag-area {
  background-color: var(--color-bg);
  margin-left: 10px;
  border-radius: 5px;
  color: var(--color-contrast-full);
  padding: 1px 4px 0px;
}

.pst-tag-area {
  margin-left: 6px;
  font-size: 1.5rem;
  position: relative;
  top: -2px;
  .pst-tag-entry {
    margin-left: 6px;
  }
}

.fst-tag-area {
  margin-left: 6px;
  font-size: 1.5rem;
  position: relative;
  top: -2px;
  .fst-tag-entry {
    margin-left: 6px;
  }
}
</style>

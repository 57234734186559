/** This is the Home Page, which a user will see immediately after logging in.
*/
<template>
  <div class="main-area-full home-main-area">
    <NotificationBanner
      v-if="hubDownBanner"
      message="The Data Hub will be offline for start-of-year updates between Friday afternoon (8/23) and Sunday evening (8/25)."
    />
    <div class="scroll-area-home">
      <div class="hero-img-container">
        <img class="hero-img" src="../assets/img/hero.jpg" />
      </div>
      <div v-if="!hubDown" class="app-holder-area">
        <div class="app-welcome-message" v-if="!selectedGrade">
          <div class="welcome-header">Welcome to the RISE Data&nbsp;Hub</div>
          <div class="mission-statement">
            Our mission is to ensure all students graduate with a plan and the
            skills and confidence to achieve college and career success.
          </div>
          <div class="select-grade-instructions">
            Get started by choosing a grade level:
          </div>
          <div class="grade-select-area">
            <span
              class="grade-select-button"
              data-cy="grade-select-button"
              v-for="(grade, index) in $user.availableGrades"
              :key="index"
              @click="selectGrade(grade)"
            >
              {{ grade }}
            </span>
          </div>
          <CustomButton
            class="learn-more-button"
            @click="ee.emit('launchHelp')"
            buttonText="Learn more about the Data Hub"
          />
        </div>
        <div class="tool-select-area" v-if="selectedGrade">
          <div class="selected-grade-area">
            <span
              class="selected-grade-message"
              data-cy="selected-grade-message"
            >
              <span class="my-selected-grade">Grade {{ selectedGrade }}</span>
              Data Tools
            </span>
            <CustomButton
              class="change-grade-button"
              data-cy="change-grade-button"
              @click="selectGrade(null)"
              buttonText="Change Grade"
            />
          </div>
          <div class="tool-list-area">
            <div class="tool-intro">
              Which tool would you like to explore first? You can change tools
              later.
            </div>
            <AppSelector :routes="getRoutes()" @launchTool="launchTool" />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  </div>
</template>

<script>
import CustomButton from "@/components/customButton.vue";
import AppSelector from "@/components/nav/appSelector.vue";
import Footer from "@/components/nav/footer.vue";
import NotificationBanner from "@/components/notificationBanner.vue";
import { routes } from "@/router/index.js";

export default {
  name: "Home",
  data() {
    return {
      hubDown: false,
      hubDownBanner: false,
      selectedGrade: null,
    };
  },
  inject: ["posthog"],
  methods: {
    launchTool(route) {
      this.$router.push(route);
    },
    selectGrade(grade) {
      this.selectedGrade = grade;

      if (this.store.state.showingGradeLevel === null) {
        this.session.subscribe(this.selectedGrade);
      } else if (
        grade !== null &&
        this.store.state.showingGradeLevel !== grade
      ) {
        this.session.resubscribe({
          grade: this.selectedGrade,
          schoolKey: this.store.state.chosenSchool,
        });
      }
    },
    getRoutes() {
      return routes
        .filter((route) => route.meta.grades.includes(this.selectedGrade))
        .filter((route) =>
          route.meta.roles.some((role) => this.$user.roles.includes(role))
        )
        .filter((route) =>
          "featureFlag" in route.meta ? route.meta.featureFlag : true
        );
    },
  },
  activated() {
    this.selectedGrade = this.store.state.showingGradeLevel || null;

    // ID user and send pageview to posthog
    this.posthog.identify(this.$user.uid, {
      name: this.$user.displayName,
      district: this.$user.district,
      schools: this.$user.schools,
      roles: this.$user.roles,
    });
    this.posthog.capture("$pageview", {
      $current_url: "/home",
    });
    this.posthog.onFeatureFlags(() => {
      if (this.posthog.isFeatureEnabled("hub-down")) {
        this.hubDown = true;
      }
      if (this.posthog.isFeatureEnabled("hub-down-banner")) {
        this.hubDownBanner = true;
      }
    });
  },
  components: { CustomButton, Footer, AppSelector, NotificationBanner },
};
</script>

<style lang="scss">
.hero-img-container {
  width: 105%;
  height: calc(100vh - 100px);
  padding: 0;
  overflow: hidden;
  .hero-img {
    object-fit: cover;
    width: calc(100vw);
    height: calc(100vh - 100px);
  }
}

.app-holder-area {
  border-radius: 10px;
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translateY(-50%);
  width: 45vw;
  border: solid var(--color-bg) 1px;
  text-align: center;
  .app-welcome-message {
    border-radius: 10px;
    padding: 2rem;
    background-color: var(--color-primary);
    color: var(--color-bg);
  }
  .welcome-header {
    font-size: 4rem;
    text-transform: uppercase;
    font-weight: bold;
    padding: 10px 50px 20px;
  }
  .mission-statement {
    padding: 0px 40px 20px;
  }
  .select-grade-instructions {
    font-weight: bold;
  }
  .grade-select-area {
    display: flex;
    margin: 20px 0;
    justify-content: center;
    .grade-select-button {
      height: 40px;
      width: 40px;
      background-color: var(--color-bg);
      margin: 0 5px;
      border-radius: 5px;
      color: var(--color-contrast-full);
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: center;
      &:hover {
        background-color: var(--color-primary-light);
        color: var(--color-bg);
        cursor: pointer;
      }
    }
  }
  .tool-select-area {
    background-color: var(--color-bg);
    border-radius: 10px;
    .selected-grade-area {
      border-radius: 10px 10px 0 0;
      background-color: var(--color-primary);
      color: var(--color-bg);
      text-align: left;
      padding: 2rem;
      font-size: 2.1rem;
      .my-selected-grade {
        font-weight: bold;
      }
      .change-grade-button {
        float: right;
        position: relative;
        top: -10px;
      }
    }
    .tool-list-area {
      padding: 2rem;
    }
  }
}
</style>

/**
 * Band configuration for numeric fields in the student document.
 * The style refers to the associated color, and the label appears on charts.
 * This is used in fields.js
 */

export const GPABands = [
  { low: 0, high: 1.99, style: "warning", label: "< 2.0" },
  { low: 2.0, high: 2.99, style: "info", label: "2.0-2.99" },
  { low: 3.0, high: 3.49, style: "gradeB", label: "3.0-3.49" },
  { low: 3.5, high: 4, style: "success", label: "3.5-4.0" },
];

//This is percent of students present - not absenteeism.
export const ADABands = [
  { low: 0, high: 60, style: "warning", label: "0% - 60%" },
  { low: 60.1, high: 80, style: "alert", label: "60.1% - 80%" },
  { low: 80.1, high: 90, style: "info", label: "80.1% - 90%" },
  { low: 90.1, high: 100, style: "success", label: "90.1% - 100%" },
];

export const suspensionsBands = [
  { low: 0, high: 0, style: "success", label: "0 suspensions" },
  { low: 1, high: 1, style: "info", label: "1 suspensions" },
  { low: 2, high: 100, style: "warning", label: "2+ suspensions" },
];

// credits < 4  4 ≤ credits < 6  6 ≤ credits
export const creditBands = [
  { low: 0, high: 3.99, style: "warning", label: "< 4" },
  { low: 4, high: 5.99, style: "info", label: "4-5.99" },
  { low: 6, high: 100, style: "success", label: "6+" },
];

export const absenceBands = [
  { low: 0, high: 2, style: "success", label: "<3 absences" },
  { low: 3, high: 3, style: "info", label: "3 absences" },
  { low: 4, high: 1000, style: "warning", label: "4+ absences" },
];

export const checkInBands = [
  { low: -10000, high: 13, style: "success", label: "< 2 weeks" },
  { low: 14, high: 10000, style: "warning", label: "2+ weeks" },
];

export const recentAbsencesBands = [
  { low: 0, high: 0, style: "success", label: "0 absences" },
  { low: 1, high: 2, style: "info", label: "1-2 absences" },
  { low: 3, high: 5, style: "alert", label: "3-5 absences" },
  { low: 6, high: 1000, style: "warning", label: "6+ absences" },
];

/** Footer is used on the home page only. */
<template>
  <div class="footer-area">
    <div id="footer-border"></div>
    <div class="copyright-notice footer-text">
      © Data tools powered by the Connecticut RISE Network
    </div>
    <div class="contact-notice footer-text">
      <span class="twitter-icon"></span>
      Stay connected @ctrisenetwork | www.ctrise.org | data@ctrise.org
    </div>
  </div>
</template>

<style lang="scss">
.footer-area {
  #footer-border {
    background-image: url("../../assets/img/riseBars.svg");
    background-image: url(/img/riseBars.c51c3232.svg);
    background-repeat: repeat-x;
    background-position: left bottom;
    background-size: 375px 14px;
    height: 30px;
    width: 105%;
    position: relative;
    top: -25px;
  }
  .footer-text {
    position: relative;
    top: -12px;
    padding: 0 20px;
  }
  .copyright-notice {
    float: left;
  }
  .contact-notice {
    text-align: right;
  }
}
</style>

/** This is the Protocol page which shows slides. The components for rendering
slides are kept in components/slides folder. */
<template>
  <div id="protocol-slides">
    <Sidebar>
      <div class="sidebar-section">
        <div class="sidebar-section-title">Choose Protocol</div>
        <CustomSelect
          :options="protocolOptions"
          title=""
          v-model="selectedProtocol"
          data-cy="protocols-sidebar-option"
        />
      </div>
      <div class="sidebar-section" v-if="selectedProtocol !== ''">
        <div class="sidebar-section-title">Steps</div>
        <table class="steps-table">
          <tr
            v-for="(slide, index) in slides"
            :key="index"
            class="slide-step"
            data-cy="slide-step"
            :class="index === currentIndex ? 'active-slide' : ''"
            @click="currentIndex = index"
          >
            <td>{{ index + 1 }}</td>
            <td>{{ slide.title }}</td>
          </tr>
        </table>
      </div>
      <hr class="sidebar-division" />
      <Info />
    </Sidebar>
    <div
      class="main-area-narrow main-area-indented"
      data-cy="protocols-main-area"
    >
      <div class="scroll-area" v-if="selectedProtocol !== ''">
        <component
          :is="slides[currentIndex].component"
          :slide="slides[currentIndex]"
        >
        </component>
      </div>
      <div v-else>
        <h1>Select a protocol</h1>
        <p>Select a protocol in the app sidebar.</p>
      </div>
    </div>
  </div>
</template>

<script>
import { protocols } from "@/apps/protocols.js";
import Sidebar from "@/components/nav/sidebar.vue";
import Info from "@/components/nav/info.vue";
import CustomSelect from "@/components/customSelect.vue";
import ChartAndQuestions from "@/components/slides/chartAndQuestions.vue";
import HeaderSlide from "@/components/slides/headerSlide.vue";
import ROCustomA from "@/components/slides/roCustomA.vue";
import ROCustomB from "@/components/slides/roCustomB.vue";
import SingleList from "@/components/slides/singleList.vue";
import OnTrackCustomA from "@/components/slides/onTrackCustomA.vue";
import OnTrackHistory from "@/components/slides/onTrackHistory.vue";
import OnTrackSubGroups from "@/components/slides/onTrackSubGroups.vue";

export default {
  components: {
    Sidebar,
    CustomSelect,
    Info,
    ChartAndQuestions,
    ROCustomA,
    ROCustomB,
    HeaderSlide,
    OnTrackCustomA,
    OnTrackHistory,
    OnTrackSubGroups,
    SingleList,
  },
  data() {
    return {
      selectedProtocol: "",
      mySlide: "ChartAndQuestions",
      currentIndex: 0,
      protocols,
    };
  },
  inject: ["posthog"],
  watch: {
    selectedProtocol: function () {
      // when changing protocols
      this.currentIndex = 0; // set current slide to first step
    },
  },
  computed: {
    protocolOptions: function () {
      // dropdown for available protocols
      return [""]
        .concat(Object.keys(this.protocols))
        .map((key) => ({ displayName: key }));
    },
    slides: function () {
      // available slides
      return this.protocols[this.selectedProtocol]?.slides.map((slide, i) => ({
        ...slide,
        index: i,
      }));
    },
  },
  activated() {
    if (this.store.state.customProp) {
      /* Programmatically set a protocol when redirected from another page, e.g. SDC */
      this.selectedProtocol = this.store.state.customProp;
      this.store.setCustomProp(null);
    }

    // Send pageview to posthog
    this.posthog.capture("$pageview", {
      $current_url: "/protocols",
    });
  },
};
</script>

<style lang="scss">
.slide-step {
  td {
    vertical-align: top;
    padding-bottom: 10px;
  }
  &:hover {
    cursor: pointer;
    background-color: var(--color-bg);
  }
}
.active-slide {
  font-weight: bold;
  color: var(--color-primary);
}
.steps-table {
  margin-top: 10px;
}
.construction-notice {
  color: var(--color-warning);
  font-weight: bold;
}
</style>

/** Sidebar component. */
<template>
  <div class="sidebar">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "Sidebar",
};
</script>

<style lang="scss">
.sidebar {
  float: left;
  width: 250px;
  height: calc(100vh - 50px);
  background-color: var(--color-contrast-lower);
  overflow-y: auto;
  overflow-x: hidden;
}
</style>

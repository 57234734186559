/**
 * This contains functions related to integrating school postsecondary tags in the Analytics view.
 */

export const addTagFields = ({ fields, postSecTags, freshSuccessTags }) => {
  let result = [...fields];

  if (!postSecTags && !freshSuccessTags) return result;
  if (!postSecTags.length && !freshSuccessTags) return result;

  postSecTags.forEach((tag) => {
    result.push({
      key: `Tag: ${tag.title}`,
      displayName: `Tag: ${tag.title}`,
      type: "boolean",
      options: {
        true: `Tagged with ${tag.title}`,
        false: `Not Tagged with ${tag.title}`,
      },
    });
  });

  freshSuccessTags.forEach((tag) => {
    result.push({
      key: `Tag: ${tag.title}`,
      displayName: `Tag: ${tag.title}`,
      type: "boolean",
      options: {
        true: `Tagged with ${tag.title}`,
        false: `Not Tagged with ${tag.title}`,
      },
    });
  });

  return result;
};

export const addTagValues = ({ data, postSecTags, freshSuccessTags }) => {
  let result = [...data];

  if (!postSecTags && !freshSuccessTags) return result;
  if (!postSecTags.length && !freshSuccessTags.length) return result;

  const hasPostSecTag = (record, tag) => {
    return record.postSecTags?.[0]?.value
      ? record.postSecTags[0].value.includes(tag.docID)
        ? true
        : false
      : false;
  };

  const hasFreshSuccessTag = (record, tag) => {
    return record.freshSuccessTags?.[0]?.value
      ? record.freshSuccessTags[0].value.includes(tag.docID)
        ? true
        : false
      : false;
  };

  postSecTags.forEach((tag) => {
    result.forEach((record) => {
      record[`Tag: ${tag.title}`] = hasPostSecTag(record, tag);
    });
  });

  freshSuccessTags.forEach((tag) => {
    result.forEach((record) => {
      record[`Tag: ${tag.title}`] = hasFreshSuccessTag(record, tag);
    });
  });

  return result;
};

/**
 * This file contains all of the functions and configuration that relate to activities, i.e. tasks, check-ins, and notes.
 */

import { blackOrWhite, months } from "@/functions/utils.js";

const getCategoryStyle = (category) =>
  `background-color: ${category.color}; color: ${blackOrWhite(
    category.color
  )};`;

export const formatDueDate = (value) => {
  if (!value) return "(None)";
  const due = new Date(value + "T12:00:00");
  const now = new Date();
  const month = months[due.getMonth()];
  const date = due.getDate();
  const diff = due - now;
  const dateClass =
    diff < 0
      ? "date-overdue"
      : diff < 172800000
      ? "date-approaching"
      : "date-future";

  return `<span class="${dateClass}">${month} ${date}</span>`;
};

export const formatDate = (value) => {
  if (!value) return "(None)";
  const myDate = new Date(value + "T12:00:00");
  const month = months[myDate.getMonth()];
  const date = myDate.getDate();

  return `<span class="date-future">${month} ${date}</span>`;
};

export const taskFields = [
  {
    title: "Completed",
    hozAlign: "center",
    width: 110,
    field: "completed",
    formatter: (cell) => {
      const done = cell.getValue();
      return `<span class="material-icons-outlined">${
        done ? "check_circle" : "circle"
      }</span>`;
    },
  },
  {
    title: "Task",
    width: 400,
    field: "title",
    headerFilter: true,
  },
  {
    title: "Student(s)",
    field: "studentArr",
    headerFilter: true,
    headerFilterFunc: (headerValue, rowValue, rowData, filterParams) => {
      if (!headerValue) return true;
      const str = rowValue
        .map((e) => e.name)
        .join(", ")
        .toLowerCase();
      return str.includes(headerValue.toLowerCase());
    },
    sorter: (a, b) => {
      const _a = a.map((e) => e.name).join(", ");
      const _b = b.map((e) => e.name).join(", ");
      return _a > _b ? 1 : -1;
    },
    formatter: (cell) => {
      const value = cell.getValue();
      return value.map((e) => e.name).join(", ");
    },
  },
  {
    title: "Categories",
    field: "categories",
    headerFilter: true,
    headerFilterFunc: (headerValue, rowValue, rowData, filterParams) => {
      if (!headerValue) return true;
      const str = rowValue
        .map((e) => e.title)
        .join(", ")
        .toLowerCase();
      return str.includes(headerValue.toLowerCase());
    },
    sorter: (a, b) => {
      const _a = a.map((e) => e.title).join(", ");
      const _b = b.map((e) => e.title).join(", ");
      return _a > _b ? 1 : -1;
    },
    formatter: (cell) => {
      const value = cell.getValue();
      if (!value) return "";
      return value
        .map(
          (category) =>
            `<span class="table-category" style="${getCategoryStyle(
              category
            )}">${category.title}</span>`
        )
        .join("");
    },
  },
  {
    title: "Due date",
    field: "dueDate",
    formatter: (cell) => {
      const value = cell.getValue();
      return formatDueDate(value);
    },
  },
];

export const noteFields = [
  {
    title: "Note",
    width: 600,
    field: "title",
    headerFilter: true,
  },
  {
    title: "Student(s)",
    field: "studentArr",
    headerFilter: true,
    headerFilterFunc: (headerValue, rowValue, rowData, filterParams) => {
      if (!headerValue) return true;
      const str = rowValue
        .map((e) => e.name)
        .join(", ")
        .toLowerCase();
      return str.includes(headerValue.toLowerCase());
    },
    sorter: (a, b) => {
      const _a = a.map((e) => e.name).join(", ");
      const _b = b.map((e) => e.name).join(", ");
      return _a > _b ? 1 : -1;
    },
    formatter: (cell) => {
      const value = cell.getValue();
      return value.map((e) => e.name).join(", ");
    },
  },
  {
    title: "Categories",
    field: "categories",
    headerFilter: true,
    headerFilterFunc: (headerValue, rowValue, rowData, filterParams) => {
      if (!headerValue) return true;
      const str = rowValue
        .map((e) => e.title)
        .join(", ")
        .toLowerCase();
      return str.includes(headerValue.toLowerCase());
    },
    sorter: (a, b) => {
      const _a = a.map((e) => e.title).join(", ");
      const _b = b.map((e) => e.title).join(", ");
      return _a > _b ? 1 : -1;
    },
    formatter: (cell) => {
      const value = cell.getValue();
      if (!value) return "";
      return value
        .map(
          (category) =>
            `<span class="table-category" style="${getCategoryStyle(
              category
            )}">${category.title}</span>`
        )
        .join("");
    },
  },
];

export const checkInFields = [
  {
    title: "Educator",
    field: "author",
    headerFilter: true,
  },
  {
    title: "Student(s)",
    field: "studentArr",
    headerFilter: true,
    headerFilterFunc: (headerValue, rowValue, rowData, filterParams) => {
      if (!headerValue) return true;
      const str = rowValue
        .map((e) => e.name)
        .join(", ")
        .toLowerCase();
      return str.includes(headerValue.toLowerCase());
    },
    sorter: (a, b) => {
      const _a = a.map((e) => e.name).join(", ");
      const _b = b.map((e) => e.name).join(", ");
      return _a > _b ? 1 : -1;
    },
    formatter: (cell) => {
      const value = cell.getValue();
      return value.map((e) => e.name).join(", ");
    },
    accessorDownload: function (value) {
      return value.map((e) => e.name).join(", ");
    },
  },
  {
    title: "Check-in Type",
    field: "checkInCategory",
    headerFilter: true,
  },
  {
    title: "YDF Category",
    field: "YDFCategories",
    headerFilter: true,
  },
  {
    title: "Length",
    field: "length",
    headerFilter: true,
  },
  {
    title: "Date",
    field: "date",
    width: 120,
    formatter: (cell) => {
      const value = cell.getValue();
      return formatDate(value);
    },
  },
  {
    title: "Check-in notes",
    width: 400,
    field: "title",
    headerFilter: true,
  },
];

/** This component shows the tooltips / hints when you hover over various parts
of the app. */
<template>
  <div class="sidebar-section">
    <div class="info-area" data-cy="info-area">
      <span class="material-icons-outlined info-icon"> info </span>
      {{
        store.state.tooltip ||
        "Information will appear here when you hover your mouse over parts of the app."
      }}
    </div>
  </div>
</template>

<script>
export default {
  name: "Info",
};
</script>

<style lang="scss">
.info-area {
  padding: 0 5px;
}
</style>

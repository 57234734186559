<template>
  <div class="table-loader">
    <div class="table-header" aria-hidden="true"></div>
    <div class="table-body" aria-hidden="true"></div>
    <div class="scrim" aria-hidden="true"></div>
  </div>
</template>

<style lang="scss" scoped>
.table-loader {
  width: 100%;
  overflow-y: hidden;
  height: 100%;
  position: relative;

  .table-header {
    height: 75px;
    background-image: linear-gradient(
      to right,
      var(--color-contrast-low),
      var(--color-contrast-low)
    );
    background-size: 100%;
  }

  .table-body {
    height: 100%;
    background-image: linear-gradient(
      to bottom,
      transparent 50%,
      var(--color-contrast-low) 50%
    );
    //each line is 34px so double the height.
    background-size: 100% 68px;
  }

  //this gives us the illusion that the animation is only over the grey areas.
  .table-body::before {
    height: 100%;
    width: 100%;
    display: block;
    content: "";
    position: relative;
    z-index: 1;
    background-image: linear-gradient(
      to bottom,
      var(--color-bg) 50%,
      transparent 50%
    );
    //each line is 34px so double the height.
    background-size: 100% 68px;
  }

  .scrim {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    //scrim that moves left to right
    background-image: linear-gradient(
      to right,
      transparent,
      var(--color-contrast-lower),
      transparent
    );
    animation: scrim 2s infinite ease;
  }
}

@keyframes scrim {
  //gets as close to 60fps as possible!
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}
</style>

<script>
export default {
  name: "TableLoader",
  props: {},
  data: () => ({}),
};
</script>

/** This modal shows when the user is inactive and automatically logs the user
out. */
<template>
  <Modal
    data-cy="idleWarning"
    ref="idleWarning"
    title="Still here?"
    :showButtons="false"
  >
    <p>Are you still using this app?</p>
    <p>Move your mouse to prevent logout.</p>
    <p>Logout will occur in {{ logOutTimer }} seconds.</p>
  </Modal>
</template>

<script>
import Modal from "@/components/modals/modal.vue";

export default {
  data() {
    return {
      logOutTimer: 30,
    };
  },
  components: { Modal },
  methods: {
    setEventListeners() {
      this.ee.on("showIdleModal", () => this.showIdleModal());
      this.ee.on("setNotIdle", () => this.setNotIdle());
    },
    startTimer() {
      this.logOutTimer = 30;

      this.logOutTimerId = setInterval(() => {
        this.logOutTimer -= 1;
        if (this.logOutTimer < 1) {
          this.expelUser();
        }
      }, 1000); // 1 second
    },
    clearTimer() {
      if (!this.logOutTimerId) return;
      clearInterval(this.logOutTimerId);
    },
    expelUser() {
      this.clearTimer();
      this.hideIdleModal();
      this.logOut();
    },
    showIdleModal() {
      if (!this.$refs.idleWarning) return;
      this.$refs.idleWarning.show();
      this.startTimer();
    },
    hideIdleModal() {
      if (!this.$refs.idleWarning) return;
      this.$refs.idleWarning.hide();
    },
    setNotIdle() {
      this.hideIdleModal();
      this.clearTimer();
    },
    logOut() {
      this.session.processLogOut();
    },
  },
  mounted() {
    this.setEventListeners();
  },
};
</script>

/**Select element with custom styling. Options need to be passed in as an array
of objects.*/

<template>
  <div :style="`width: ${customWidth}px;`">
    <div class="select-title">{{ title }}</div>
    <div class="select" v-if="optionGroups">
      <select :value="modelValue" @change="processChange($event)">
        <optgroup
          v-for="(optionGroup, i) in optionGroups"
          :key="i"
          :label="optionGroup"
        >
          <option
            v-for="(item, j) in options.filter(
              (e) => e.optionGroup == optionGroup
            )"
            :key="j"
          >
            {{ item[displayField] }}
          </option>
        </optgroup>
      </select>
    </div>
    <div class="select" v-else>
      <select :value="modelValue" @change="processChange($event)">
        <option v-for="(item, index) in options" :key="index">
          {{ item[displayField] }}
        </option>
      </select>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      required: false,
      type: String,
    },
    customWidth: {
      required: false,
      type: Number,
      default: 225,
    },
    groupOrder: {
      required: false,
      type: Array,
    },
    options: Array,
    modelValue: String,
    displayField: {
      required: false,
      type: String,
      default: "displayName",
    },
  },
  computed: {
    optionGroups: function () {
      if (!this.options.length) return false;
      if (this.options.every((item) => !item.optionGroup)) return false;
      this.options.forEach((item) => {
        if (!item.optionGroup) {
          item.optionGroup = "(uncategorized)";
        }
      });
      return [
        ...new Set(this.options.map((option) => option.optionGroup)),
      ].sort((a, b) => {
        if (this.groupOrder) {
          return this.groupOrder.indexOf(a) > this.groupOrder.indexOf(b)
            ? 1
            : -1;
        }
        return a > b ? 1 : -1;
      });
    },
  },
  methods: {
    processChange(event) {
      this.$emit("update:modelValue", event.target.value);
      this.$emit("selectedIndex", event.target.selectedIndex);
    },
  },
};
</script>

<style lang="scss">
@use "../assets/styles/mixins/pieces";

.select-title {
  margin-top: 10px;
}

//TECH DEBT: We should not be wrapping input elements in a grid. This is completely unecessary like hammering a nail with a pineapple.
.select {
  margin: 10px 0 10px;
  width: 100%;
  min-width: 15ch;
  max-width: 30ch;
  border: 1px var(--color-contrast-high);
  border-radius: 0.25em;
  padding: 0.25em 0 0.25em 0em;
  font-size: 1.5rem;
  cursor: pointer;
  line-height: 1.1;
  background-color: var(--color-bg);
  display: grid;
  grid-template-areas: "select";
  align-items: center;

  //moved this to be scoped by this component.
  //TECH DEBT - need to scope component css app-wide. Needs to be taken on a case by case basis because styles may break.
  //+1 ran into the issue again :(
  select {
    // Additional resets for further consistency
    background-color: var(--color-bg);
    color: var(--color-contrast-full);
    border: none;
    padding: 5px;
    margin: 0;
    width: 100%;
    font-family: inherit;
    font-size: inherit;
    cursor: inherit;
    line-height: inherit;
    outline: none;

    @include pieces.downArrow;
  }

  &:after,
  select {
    grid-area: select;
  }
}
</style>

/** R&O protocol slide: Attendance, GPA and suspensions. */
<template>
  <h1 class="slide-header">{{ slide.title }}</h1>
  <h3>{{ slide.subtitle }}</h3>
  <div class="slide-body">
    <table class="custom-table">
      <tr>
        <td class="title-col">Attendance</td>
        <td class="graph-col">
          <MyChart
            :chartConfig="addInteraction(charts[0].chartConfig)"
            :chartData="store.state.studentData"
          />
        </td>
        <td class="legend-col">
          <div>
            <span class="legend-block legend-success">&nbsp;</span>90.1% - 100%
            Avg. Daily Attendance
          </div>
          <div>
            <span class="legend-block legend-info">&nbsp;</span>80.1% - 90% Avg.
            Daily Attendance
          </div>
          <div>
            <span class="legend-block legend-alert">&nbsp;</span>60.1% - 80%
            Avg. Daily Attendance
          </div>
          <div>
            <span class="legend-block legend-warning">&nbsp;</span>0% - 60% Avg.
            Daily Attendance
          </div>
          <div>
            <span class="legend-block legend-contrast-low">&nbsp;</span>No data
          </div>
        </td>
      </tr>
      <tr>
        <td class="title-col">GPA</td>
        <td class="graph-col">
          <MyChart
            :chartConfig="addInteraction(charts[1].chartConfig)"
            :chartData="store.state.studentData"
          />
        </td>
        <td class="legend-col">
          <div>
            <span class="legend-block legend-success">&nbsp;</span>≥ 3.5
          </div>
          <div>
            <span class="legend-block legend-gradeB">&nbsp;</span>3.0-3.5
          </div>
          <div><span class="legend-block legend-info">&nbsp;</span>2.0-3.0</div>
          <div>
            <span class="legend-block legend-warning">&nbsp;</span>&lt; 2.0
          </div>
          <div>
            <span class="legend-block legend-contrast-low">&nbsp;</span>No data
          </div>
        </td>
      </tr>
      <tr>
        <td class="title-col">Suspensions</td>
        <td class="graph-col">
          <MyChart
            :chartConfig="addInteraction(charts[2].chartConfig)"
            :chartData="store.state.studentData"
          />
        </td>
        <td class="legend-col">
          <div>
            <span class="legend-block legend-success">&nbsp;</span>0 suspensions
          </div>
          <div>
            <span class="legend-block legend-info">&nbsp;</span>1 suspension
          </div>
          <div>
            <span class="legend-block legend-warning">&nbsp;</span>≥ 2
            suspensions
          </div>
          <div>
            <span class="legend-block legend-contrast-low">&nbsp;</span>No data
          </div>
        </td>
      </tr>
    </table>
    <ul class="slide-questions">
      <li v-for="(question, index) in slide.questions" :key="index">
        {{ question }}
      </li>
    </ul>
  </div>
</template>

<script>
import MyChart from "@/components/charts.vue";
import { getRedirectCustomPropFromChart } from "@/functions/summary.js";
import { fields } from "@/apps/fields.js";

export default {
  props: {
    slide: Object,
  },
  data() {
    return {
      charts: [
        {
          chartConfig: {
            type: "singleBand",
            title: "Attendance",
            primaryField: fields.find(
              (field) => field.key === "g9RiskAndOpportunity.ADA"
            ),
            showAsPercentage: false,
            displayTicks: false,
          },
        },
        {
          chartConfig: {
            type: "singleBand",
            title: "GPA",
            primaryField: fields.find(
              (field) => field.key === "g9RiskAndOpportunity.GPA"
            ),
            showAsPercentage: false,
            displayTicks: false,
          },
        },
        {
          chartConfig: {
            type: "singleBand",
            title: "Suspensions",
            primaryField: fields.find(
              (field) => field.key === "g9RiskAndOpportunity.suspensions"
            ),
            showAsPercentage: false,
            displayTicks: false,
          },
        },
      ],
    };
  },
  components: { MyChart },
  methods: {
    addInteraction(chartConfig) {
      chartConfig.onClick = this.chartClickHandler;
      return chartConfig;
    },
    chartClickHandler({ filters }) {
      this.store.setCustomProp(
        getRedirectCustomPropFromChart({
          filters,
          redirectDefaultFields:
            this.config.gradeConfig[this.store.state.showingGradeLevel]
              .analyticsConfig.redirectDefaultFields,
          fields: this.config.fields,
        })
      );

      this.$router.push("/tracker");
    },
  },
};
</script>

<style lang="scss">
.custom-table {
  height: 250px;
  .title-col {
    width: 130px;
    font-weight: bold;
    text-align: center;
  }
  .graph-col {
    width: 500px;
  }
  .legend-col {
    width: 300px;
    padding: 20px;
    .legend-block {
      display: inline-block;
      width: 20px;
      margin: 3px 8px;
    }
    .legend-warning {
      background-color: var(--color-warning);
    }
    .legend-info {
      background-color: var(--color-info);
    }
    .legend-gradeB {
      background-color: var(--color-grade-b);
    }
    .legend-success {
      background-color: var(--color-success);
    }
    .legend-contrast-low {
      background-color: var(--color-contrast-low);
    }
    .legend-alert {
      background-color: var(--color-alert);
    }
  }
}
</style>

/** This is the Summary Tables view which displays a special summary view for
postsecondary teams. */
<template>
  <div id="old-summary">
    <Sidebar>
      <div class="sidebar-section sidebar-section-top">
        <div class="sidebar-section-title">Display Format</div>
        <CustomSelect
          data-cy="summary-table-display-format"
          :options="formatOptions"
          title=""
          v-model="selectedFormatOption"
        />
      </div>
      <div class="sidebar-section">
        <div class="sidebar-section-title">Download Tables</div>
        <CustomSelect
          :options="downloadOptions"
          title=""
          v-model="selectedDownloadOption"
        />
        <CustomButton @click="download" buttonText="Download" />
      </div>
      <div class="sidebar-section">
        <div class="sidebar-section-title">Data Last Updated</div>
        <div class="sidebar-section-padded" data-cy="summary-tables-freshness">
          <p>PowerSchool: {{ freshness.powerschool }}</p>
          <p>Postsecondary Software: {{ freshness.naviance }}</p>
          <p>FAFSA: {{ freshness.fafsa }}</p>
        </div>
      </div>
    </Sidebar>
    <div class="main-area-narrow main-area-indented">
      <div class="scroll-area">
        <div v-for="(block, index) in protocolBlocks" :key="index">
          <h2 class="subheading">{{ block.heading }}</h2>
          <p class="explanation">{{ block.explanation }}</p>
          <div class="table-area" v-for="(table, j) in block.tables" :key="j">
            <p class="table-title">{{ table.tableTitle }}</p>
            <TableLoader v-show="loading" />
            <MyTable
              v-show="!loading"
              :ref="table.ref"
              :id="table.ref"
              :tableData="table.tableBody.data"
              :columns="table.tableBody.columns"
              :customConfig="summaryTableConfig"
              :showAsInlineTable="true"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getSummaryDataAndColumns,
  getVictorySummaryDataAndColumns,
  getRedirectCustomProp,
  formatCellValue,
  addTotalColumn,
} from "@/functions/summary.js";
import CustomButton from "@/components/customButton.vue";
import CustomSelect from "@/components/customSelect.vue";
import MyTable from "@/components/table.vue";
import Sidebar from "@/components/nav/sidebar.vue";
import TableLoader from "@/components/loaders/tableLoader.vue";

export default {
  components: { CustomButton, CustomSelect, MyTable, Sidebar, TableLoader },
  props: {},
  data() {
    return {
      freshness: this.getFreshness(),
      loading: true,
      selectedDownloadOption: "All",
      protocolBlocks: [
        {
          heading: "Postsecondary Plans",
          explanation:
            "Table 1a summarizes students’ postsecondary plans. Through surveys and conversations with counselors, students indicate their plans to pursue one or multiple pathway(s). Please note that students may appear in multiple rows if they are exploring multiple pathways. As a network, our goal is to ensure all students graduate with a postsecondary plan; therefore, the “No Selected Plan” designation is an area of focus. Table 1b provides students’ postsecondary plan information broken down by race/ethnicity.",
          tables: [
            {
              tableTitle: "Table 1a: Postsecondary plans",
              rowGroup: "plans",
              columnGroup: "school.name",
              tableBody: this.getSummaryTable(
                "plans",
                "school.name",
                true,
                false
              ),
              percentageByGroup: true,
              ref: "protocol-table-1a",
              totalColumn: false,
            },
            {
              tableTitle: "Table 1b: Postsecondary plans by race/ethnicity",
              rowGroup: "plans",
              columnGroup: "raceAndEthnicity.race",
              tableBody: this.getSummaryTable(
                "plans",
                "raceAndEthnicity.race",
                false,
                true
              ),
              percentageByGroup: false,
              ref: "protocol-table-1b",
              totalColumn: true,
            },
          ],
        },
        {
          heading: "Postsecondary Applications",
          explanation:
            "Table 2a shows students in rows based on their postsecondary plans; students appear in the appropriate column based on the number of postsecondary applications submitted by each student. Table 2b shows students in rows based on their race/ethnicity; students appear in the appropriate column based on the number of postsecondary applications submitted by each student. Postsecondary application data pull directly from postsecondary software; therefore, it is important for students to regularly update their postsecondary software accounts with their postsecondary application details.",
          tables: [
            {
              tableTitle:
                "Table 2a: Number of postsecondary applications completed by postsecondary plan",
              rowGroup: "plans",
              columnGroup: "_collegeAppsSubmitted",
              tableBody: this.getSummaryTable(
                "plans",
                "_collegeAppsSubmitted",
                false,
                true
              ),
              percentageByGroup: false,
              ref: "protocol-table-2a",
              totalColumn: true,
            },
            {
              tableTitle:
                "Table 2b: Number of postsecondary applications completed by race/ethnicity",
              rowGroup: "raceAndEthnicity.race",
              columnGroup: "_collegeAppsSubmitted",
              tableBody: this.getSummaryTable(
                "raceAndEthnicity.race",
                "_collegeAppsSubmitted",
                false,
                true
              ),
              percentageByGroup: false,
              ref: "protocol-table-2b",
              totalColumn: true,
            },
          ],
        },
        {
          heading: "FAFSA Review",
          explanation:
            "This section provides information about students’ FAFSA completion status. The data presented in the tables below are from the U.S. Department of Education via Connecticut’s EdSight portal. High schools are unable to track AACTUS completion for students without social security numbers. Table 3a summarizes FAFSA status for all students in the selected grade. Tables 3b and 3c summarize FAFSA status by postsecondary plan and race/ethnicity.",
          tables: [
            {
              tableTitle: "Table 3a: FAFSA status",
              rowGroup: "FAFSAStateStatus",
              columnGroup: "school.name",
              tableBody: this.getSummaryTable(
                "FAFSAStateStatus",
                "school.name",
                true,
                false
              ),
              percentageByGroup: true,
              ref: "protocol-table-3a",
              fieldsToAddOnRedirect: ["FAFSAStatus"],
              totalColumn: false,
            },
            {
              tableTitle: "Table 3b: FAFSA status by postsecondary plan",
              rowGroup: "plans",
              columnGroup: "FAFSAStateStatus",
              tableBody: this.getSummaryTable(
                "plans",
                "FAFSAStateStatus",
                false,
                true
              ),
              percentageByGroup: false,
              ref: "protocol-table-3b",
              fieldsToAddOnRedirect: ["FAFSAStatus"],
              totalColumn: true,
            },
            {
              tableTitle: "Table 3c: FAFSA status by race/ethnicity",
              rowGroup: "FAFSAStateStatus",
              columnGroup: "raceAndEthnicity.race",
              tableBody: this.getSummaryTable(
                "FAFSAStateStatus",
                "raceAndEthnicity.race",
                false,
                true
              ),
              percentageByGroup: false,
              ref: "protocol-table-3c",
              fieldsToAddOnRedirect: ["FAFSAStatus"],
              totalColumn: true,
            },
          ],
        },
        {
          heading: "College Access Milestones",
          explanation:
            "This section summarizes students’ progress completing key college access milestones. Students appear in the charts below if they have indicated plans to pursue either a 2- or 4-year college degree. Data in these tables draw from postsecondary software and student surveys, and counselors may enter data for specific variables based on their knowledge of student progress. Table 4a lists college access milestones and the number of students having completed each access milestone.",
          tables: [
            {
              tableTitle: "Table 4a: College-bound milestone completion",
              type: "victory",
              rowFields: [
                "collegeApps",
                "navianceCollegeList",
                "studentRequestedRecs",
                "teacherSubmittedRecs",
              ],
              columnGroup: "raceAndEthnicity.race",
              tableBody: this.getVictoryTable(
                [
                  "collegeApps",
                  "navianceCollegeList",
                  "studentRequestedRecs",
                  "teacherSubmittedRecs",
                ],
                "raceAndEthnicity.race",
                true,
                true
              ),
              ref: "protocol-table-4a",
              totalColumn: true,
              totalColumnByGroup: true,
            },
          ],
        },
      ],
      summaryTableConfig: {
        height: "100%",
        width: "100%",
        cellDblClick: (e, cell) => {
          this.redirectToTracker(cell);
        },
      },
      selectedFormatOption: "Show count",
      formatOptions: [
        { displayName: "Show count" },
        { displayName: "Show percentage" },
        { displayName: "Show count and percentage" },
      ],
    };
  },
  inject: ["posthog"],
  methods: {
    setEventListeners() {
      this.ee.on("gradeLevelChange", () => {
        // if there is no summary tables page, ignore
        if (this.store.state.showingGradeLevel < 11) return;
        this.loading = true;
      }),
        this.ee.on("studentDataReceived", () => {
          // if there is no summary tables page, ignore
          if (this.store.state.showingGradeLevel < 11) return;
          this.renderAllTables();
          this.loading = false;
        });
    },
    getFreshness() {
      const meta = this.store.state.schoolMetaData;
      const myDate = (str) => new Date(str + "T12:00").toDateString();

      return meta
        ? {
            powerschool: myDate(meta.powerschoolLastUpdate),
            naviance: myDate(meta.navianceLastUpdate),
            fafsa: myDate(meta.FAFSALastUpdate),
          }
        : {
            powerschool: "",
            naviance: "",
            fafsa: "",
          };
    },
    redirectToTracker(cell) {
      // navigate to tracker page and show relevant students
      const myTable = this.protocolBlocks
        .map((e) => e.tables)
        .flat()
        .find((e) => e.ref == cell._cell.table.element.parentElement.id);

      // do not redirect for victory table
      if (myTable.type === "victory") return;

      // if user double-clicked on category column(s), ignore
      if (cell._cell.value == cell._cell.row.cells[0].value) return;

      const redirectDefaultFields = this.config.gradeConfig[
        this.store.state.showingGradeLevel
      ].analyticsConfig.redirectDefaultFields.concat(
        myTable.fieldsToAddOnRedirect || []
      );

      this.store.setCustomProp(
        getRedirectCustomProp({
          rowKey: myTable.rowGroup,
          columnKey: myTable.columnGroup,
          fields: this.config.fields,
          rowValue: cell._cell.row.cells[0].value,
          rowSubValue: cell._cell.row.cells[1].value,
          columnValue: cell._cell.column.field,
          redirectDefaultFields,
        })
      );

      this.$router.push("/tracker");
    },
    getSummaryTable(
      rowGroup,
      columnGroup,
      percentageByGroup = false,
      totalColumn = false
    ) {
      let rowAndColumnData = getSummaryDataAndColumns({
        studentData: this.store.state.studentData,
        postSecTags: this.store.state.postSecTags,
        freshSuccessTags: this.store.state.freshSuccessTags,
        fields: this.config.fields,
        rowKey: rowGroup,
        columnKey: columnGroup,
        selectedFormatOption: this.selectedFormatOption || "Show count",
        showSumRow: true,
        percentageByGroup,
      });

      // Only add a total column if requested
      if (totalColumn) {
        rowAndColumnData = addTotalColumn(
          rowAndColumnData,
          this.selectedFormatOption
        );
      }

      return rowAndColumnData;
    },
    getVictoryTable(rowFields, columnGroup, totalColumn, totalColumnByGroup) {
      let rowAndColumnData = getVictorySummaryDataAndColumns({
        data: this.store.state.studentData,
        fields: this.config.fields,
        rowFieldKeys: rowFields,
        columnKey: columnGroup,
        selectedFormatOption: this.selectedFormatOption || "Show count",
      });

      //Only add a total column if requested
      if (totalColumn) {
        const totalRecords = totalColumnByGroup
          ? this.store.state.studentData.length
          : null;
        rowAndColumnData = addTotalColumn(
          rowAndColumnData,
          this.selectedFormatOption,
          totalRecords
        );
      }

      return rowAndColumnData;
    },
    renderAllTables() {
      if (this.store.state.showingGradeLevel < 11) return;
      this.protocolBlocks.forEach((block) => {
        block.tables.forEach((table) => {
          table.tableBody =
            table.type === "victory"
              ? //why go off of table config instead of the pregenerated table body?
                this.getVictoryTable(
                  table.rowFields,
                  table.columnGroup,
                  table.totalColumn,
                  table.totalColumnByGroup
                )
              : this.getSummaryTable(
                  table.rowGroup,
                  table.columnGroup,
                  table.percentageByGroup,
                  table.totalColumn
                );
          this.$refs[table.ref][0].doMethod(
            "setColumns",
            table.tableBody.columns
          );
          this.$refs[table.ref][0].doMethod("setData", table.tableBody.data);
        });
      });

      //Update Freshness after tables load. The change is almost instantaneous so it is percieved as loading at the same time.
      this.freshness = this.getFreshness();
    },
    download() {
      //TECH DEBT: we need to rename variables with `my___` in them. if they are user based selections, then we should rename accordingly.
      const myOption = this.downloadOptions.find(
        (element) => element.displayName == this.selectedDownloadOption
      );

      if (myOption.ref) {
        this.$refs[myOption.ref][0].doMethod(
          "download",
          "xlsx",
          "appData.xlsx",
          { sheetName: "App Download Data" }
        );
      } else {
        let sheets = {};
        const tables = this.downloadOptions.filter((element) => element.ref);
        // this is targeting the main tabulator div: `<div class="tabulator"...
        const nodes = document.querySelectorAll(
          ".table-area > div:first-of-type > div"
        );

        for (let i = 0, sheetName = ""; i < nodes.length; i++) {
          sheetName = tables[i]?.displayName
            .substring(0, 30)
            .replaceAll(":", "");
          if (sheetName) sheets[sheetName] = nodes[i];
        }

        const firstTable = this.downloadOptions.find((element) => element.ref);
        this.$refs[firstTable.ref][0].doMethod(
          "download",
          "xlsx",
          "allTables.xlsx",
          { sheets }
        );
      }
    },
  },
  watch: {
    selectedFormatOption: function () {
      this.renderAllTables();
    },
  },
  computed: {
    downloadOptions: function () {
      let result = [{ displayName: "All" }];
      this.protocolBlocks.forEach((block) => {
        block.tables.forEach((table) => {
          result.push({ displayName: table.tableTitle, ref: table.ref });
        });
      });
      return result;
    },
  },
  mounted() {
    if (this.store.state.studentData.length) this.loading = false;
  },
  activated() {
    // Send pageview to posthog
    this.posthog.capture("$pageview", {
      $current_url: "/summarytables",
    });
  },
  created() {
    this.setEventListeners();
  },
};
</script>

<style lang="scss">
.subheading,
.explanation,
.table-title {
  margin-bottom: 2rem;
}
.table-title {
  font-weight: bold;
}
.table-area {
  padding-right: 1.5rem;
}
</style>

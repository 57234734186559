/**
 * This contains functions used to build summary tables in Analytics.vue view
 * and get redirect props (objects used to redirect users to a custom tracker/list view)
 */

import {
  getValueFromObject,
  distinctSet,
  getGroupValue,
  orderSet,
  getDisplayValue,
  testValue,
  getAutoLabels,
} from "@/functions/utils.js";
import { addTagFields, addTagValues } from "@/functions/tagFunctions.js";
import { applyFilters } from "@/functions/filters.js";
import { processTrueFalse } from "./utils";

const dataMissingWarning = "No data";

const getFieldDisplayName = ({ key, fields }) => {
  if (key === null || key === "None") return null;
  return fields.find((element) => element.key == key)?.displayName;
};

const clean = (val) => {
  if (val === undefined || val === null) return "noData";
  return typeof val === "string" ? val.replaceAll(".", "_") : val;
};

const unclean = (val) =>
  typeof val === "string" ? val.replaceAll("_", ".") : val;

const getUniqueSet = ({ key, fields, data }) => {
  if (key === null) return [undefined];
  const field = fields.find((f) => f.key === key);
  if (field.bands) return field.bands.map((band) => band.label);
  if (field.type === "numeric")
    return getAutoLabels({ chartData: data, fieldKey: key });
  return distinctSet({ fieldKey: key, data }).sort((a, b) =>
    orderSet({ a, b, dataMissingWarning, field })
  );
};

const hasDataMissing = ({ key, data }) => {
  return data.some((record) => {
    const entry = record[key];
    const value = Array.isArray(entry) ? entry?.[0]?.value : entry;
    return value === null;
  });
};

const getCategories = ({
  key,
  fields,
  data,
  uniqueSetFn = getUniqueSet,
  dataMissingFn = hasDataMissing,
}) => {
  const result = uniqueSetFn({ key, fields, data });
  if (dataMissingFn({ key, data })) {
    result.push("No data");
  }
  return result;
};

export const exportedForTesting = { hasDataMissing, getCategories };

const getRecordTotal = ({
  data,
  category,
  rowField,
  rowSubField,
  subCategory,
}) =>
  data
    .filter((record) => {
      if (category === undefined) return true;
      return testValue({
        field: rowField,
        value: getValueFromObject(record, rowField.key),
        category,
      });
    })
    .filter((record) => {
      if (subCategory === undefined) return true;
      if (rowSubField === undefined) return true;
      return testValue({
        field: rowSubField,
        value: getValueFromObject(record, rowSubField.key),
        category: subCategory,
      });
    }).length;

// function to build each row in the table

const getRecords = ({
  data,
  columnField,
  column,
  rowField,
  category,
  rowSubField,
  subCategory,
}) =>
  data
    .filter((record) =>
      testValue({
        field: columnField,
        value: getValueFromObject(record, columnField.key),
        category: column,
      })
    )
    .filter((record) => {
      if (category === undefined) return true;
      return testValue({
        field: rowField,
        value: getValueFromObject(record, rowField.key),
        category,
      });
    })
    .filter((record) => {
      if (subCategory === undefined) return true;
      if (rowSubField === undefined) return true;
      return testValue({
        field: rowSubField,
        value: getValueFromObject(record, rowSubField.key),
        category: subCategory,
      });
    });

const getPercentage = ({ percentageByGroup, myRecords, recordTotal, data }) =>
  percentageByGroup
    ? ((myRecords.length / data.length).toFixed(3) * 100).toFixed(1)
    : recordTotal
    ? ((myRecords.length / recordTotal).toFixed(3) * 100).toFixed(1)
    : "N/A";

export function formatCellValue(count, percentage, selectedFormatOption) {
  return [
    {
      mode: "Show count",
      str: count,
    },
    {
      mode: "Show percentage",
      str: percentage + "%",
    },
    {
      mode: "Show count and percentage",
      str: `${count} (${percentage}%)`,
    },
  ].find((element) => element.mode == selectedFormatOption).str;
}

const createTableRow = ({
  category,
  subCategory,
  rowField,
  rowSubField,
  columnField,
  data,
  columnCategories,
  percentageByGroup,
  selectedFormatOption,
}) => {
  const result = {};

  // first column (title column)
  result[clean(rowField.key)] =
    category === undefined
      ? "Total"
      : getDisplayValue({ value: category, field: rowField });

  // secondary title column (if using subcategory)
  if (subCategory !== undefined && rowSubField !== undefined) {
    result[clean(rowSubField.key)] = getDisplayValue({
      value: subCategory,
      field: rowSubField,
    });
  }

  // calculate the total number of records
  const recordTotal = getRecordTotal({
    data,
    category,
    rowField,
    rowSubField,
    category,
    subCategory,
  });
  let totalCount = 0;
  let totalPercentage = 0;

  // populate all columns with values in
  columnCategories.forEach((column) => {
    const myRecords = getRecords({
      data,
      columnField,
      column,
      rowField,
      category,
      rowSubField,
      subCategory,
    });
    const count = myRecords.length.toString();
    const percentage = getPercentage({
      percentageByGroup,
      myRecords,
      recordTotal,
      data,
    });

    result[clean(column)] = formatCellValue(
      count,
      percentage,
      selectedFormatOption
    );
    totalCount += Number(count);
    totalPercentage += Number(percentage);
  });

  result["totalCount"] = totalCount;
  result["totalPercentage"] = totalPercentage;
  return result;
};

/* RETURNS:
 * data: array of objects to make up Tabulator table data
 * columns: columns config object for Tabulator to present data
 */

export function getSummaryDataAndColumns({
  studentData, // main data for app
  postSecTags, // main postsecondary tags object
  freshSuccessTags, // main freshmen success tags object
  fields: myFields, // main fields object
  rowKey, //  field key for row disaggregation
  rowSubKey = null, // field key for secondary row disaggregation
  columnKey, //  field key for column disaggregation
  filters = []
  /** array of filter objects in following format:
   *       field: field key (string)
   *       type: (optional) operation to compare values. Can be any of '=', '>', '<'. Defaults to '='. (string)
   *       value: value to match (string/number/bool depending on field data type) */,
  selectedFormatOption
  /** show table values as percentage, count, or both. Options are:
   *  - 'Show count'
   *  - 'Show percentage'
   *  - 'Show count and percentage' */,
  showSumRow = false, // function will return a final row summing each column, OR (if showAsPercentage is true) showing the percentage of all students. (bool)
  percentageByGroup = false, // calculate percentages by columns, not rows (bool)
}) {
  // Validate input

  let shouldAbort = false;

  [
    studentData,
    postSecTags,
    freshSuccessTags,
    myFields,
    rowKey,
    columnKey,
    selectedFormatOption,
  ].forEach((attribute) => {
    if (attribute === undefined) {
      //changed this to function arguments because this will always just return undefined otherwise
      console.warn("Missing expected attribute:", arguments[0]);
      shouldAbort = true;
    }
  });

  if (shouldAbort) return { data: [], columns: [] };

  // Set up inputs

  const fields = addTagFields({
      fields: myFields,
      postSecTags,
      freshSuccessTags,
    }),
    processedData = applyFilters({
      data: addTagValues({ data: studentData, postSecTags, freshSuccessTags }),
      filters,
    });

  const columnField = fields.find((field) => field.key === columnKey),
    rowField = fields.find((field) => field.key === rowKey),
    rowSubField = fields.find((field) => field.key === rowSubKey);

  // do not chain variable declarations - changed to individual declarations.
  const rowCategories = getCategories({
    key: rowKey,
    fields,
    data: processedData,
  });
  const columnCategories = getCategories({
    key: columnKey,
    fields,
    data: processedData,
  });
  const rowSubCategories = getCategories({
    key: rowSubKey,
    fields,
    data: processedData,
  });

  // create table data

  //wrapper functions like this are bad practice
  const getTableRow = ({ category, subCategory } = {}) =>
    createTableRow({
      category,
      subCategory,
      columnCategories,
      columnField,
      rowField,
      rowSubField,
      percentageByGroup,
      selectedFormatOption,
      data: processedData,
    });

  const data = rowCategories
    .map((category) =>
      rowSubCategories.map((subCategory) =>
        getTableRow({ category, subCategory })
      )
    )
    .concat(showSumRow ? getTableRow() : [])
    .flat();

  // create columns config

  const columns = [
    {
      // first column
      title: rowField.displayName.toString(),
      field: clean(rowField.key).toString(),
    },
  ]
    .concat(
      rowSubKey
        ? [
            {
              // second column
              title: rowSubField.displayName.toString(),
              field: clean(rowSubField.key).toString(),
            },
          ]
        : []
    )
    .concat(
      // other columns
      columnCategories
        .map((category) => ({
          title: getDisplayValue({ value: category, field: columnField }),
          field: clean(category).toString(),
          // Only sort columns for tables that don't have a specific "11+" column
        }))
        .sort((a, b) =>
          columnCategories[4] !== "11+"
            ? orderSet({
                a: a.title,
                b: b.title,
                dataMissingWarning,
                field: columnField,
              })
            : ""
        )
    );

  return { data, columns };
}

const createVictoryTableRow = ({
  field,
  data,
  columnField,
  columnCategories,
  selectedFormatOption,
}) => {
  const result = {};

  // first column
  result.rowField = field.displayName;
  let totalCount = 0;
  let totalPercentage = 0;

  // other columns
  columnCategories.forEach((category) => {
    const recordTotal = getRecordTotal({
      data,
      category,
      rowField: columnField,
    });
    const myRecords = data
      .filter(
        (record) => getValueFromObject(record, columnField.key) == category
      )
      .filter((record) => field.victory(getValueFromObject(record, field.key)));

    const count = myRecords.length.toString();
    const percentage = getPercentage({
      percentageByGroup: false,
      myRecords,
      recordTotal,
      data,
    });

    result[category] = formatCellValue(count, percentage, selectedFormatOption);
    totalCount += Number(count);
    totalPercentage += Number(percentage);
  });

  result["totalCount"] = totalCount;
  result["totalPercentage"] = totalPercentage;
  return result;
};

/*
 * RETURNS:
 * data: array of objects to make up Tabulator table data
 * columns: columns config object for Tabulator to present data
 *
 */

export function getVictorySummaryDataAndColumns({
  data: inputData, // main data for app
  fields, // main fields object
  rowFieldKeys, // array of field keys for rows (array)
  columnKey, // field key for disaggregation (string)
  selectedFormatOption
  /** show table values as percentage, count, or both. Options are:
   *  - 'Show count'
   *  - 'Show percentage'
   *  - 'Show count and percentage' */,
}) {
  // Validate input

  let shouldAbort = false;

  [inputData, fields, rowFieldKeys, columnKey, selectedFormatOption].forEach(
    (attribute) => {
      if (attribute === undefined) {
        console.warn("Missing expected attribute:", attribute);
        shouldAbort = true;
      }
    }
  );

  if (shouldAbort) return { data: [], columns: [] };

  // Set up inputs

  const processedData = inputData.filter((record) =>
    ["4-Year College", "2-Year College"].includes(record.plans[0].value)
  );
  const rowFields = rowFieldKeys.map((element) =>
      fields.find((field) => field.key === element)
    ),
    columnGroup = getFieldDisplayName({ key: columnKey, fields }),
    columnCategories = getUniqueSet({
      key: columnKey,
      fields,
      data: processedData,
    }),
    columnField = fields.find((field) => field.key === columnKey);

  const data = rowFields.map((field) =>
    createVictoryTableRow({
      field,
      data: processedData,
      columnField,
      columnCategories,
      selectedFormatOption,
    })
  );

  const columns = [
    {
      // first column
      title: "Measure",
      field: "rowField",
    },
  ].concat(
    // other columns
    columnCategories.map((category) => ({
      title: getDisplayValue({
        value: category,
        field: fields.find((f) => f.displayName === columnGroup),
      }),
      field: category.toString(),
    }))
  );

  return { data, columns };
}

const isLowest = ({ band, field }) => {
  if (!field.bands) return false;
  return !field.bands.some((b) => b.low < band.low);
};

const isHighest = ({ band, field }) => {
  if (!field.bands) return false;
  return !field.bands.some((b) => b.high > band.high);
};
const getBandFromValue = (value) => {
  const arr = value.split("-").map((str) => Number(str));
  return { low: arr[0], high: arr[1] };
};

export const getBandsFilter = ({ field, value }) => {
  if ([null, "No data"].some((element) => element === value))
    return {
      displayName: field.displayName,
      field: field.key,
      type: "customEquals",
      displayType: "=",
      value: "No data",
    };

  const band =
    field.bands?.find((b) => b.label === value) || getBandFromValue(value);

  const result = [];

  //check if value is a single number. return equals
  if (!isNaN(value)) {
    result.push({
      displayName: field.displayName,
      field: field.key,
      type: "=",
      displayType: "=",
      value: value,
    });

    return result;
  }

  // If the low and high band values are equal to each other pass in an "=" filter
  if (band.low === band.high) {
    result.push({
      displayName: field.displayName,
      field: field.key,
      type: "=",
      displayType: "=",
      value: band.low,
    });
  } else {
    // If the low band value is 0 add a filter to filter out "No data" entries
    if (band.low === 0) {
      result.push({
        displayName: field.displayName,
        field: field.key,
        type: "!=",
        displayType: "!=",
        value: null,
      });
    }

    // If it isn't the highest band value add a "≤" filter for the high value
    if (!isHighest({ band, field })) {
      result.push({
        displayName: field.displayName,
        field: field.key,
        type: "<=",
        displayType: "≤",
        value: band.high,
      });
    }

    // For each entry add a "≥" filter for the low value
    result.push({
      displayName: field.displayName,
      field: field.key,
      type: ">=",
      displayType: "≥",
      value: band.low,
    });
  }

  return result;
};

const getNumericFilter = ({ field, type, value }) => {
  const displayType =
    [
      { type: "<", displayType: "<" },
      { type: ">", displayType: ">" },
      { type: "<=", displayType: "≤" },
      { type: ">=", displayType: "≥" },
      { type: "=", displayType: "customEquals" },
    ].find((element) => (element.type = type))?.displayType || "";

  return {
    displayName: field.displayName,
    field: field.key,
    type,
    displayType,
    value,
  };
};

const getFilter = ({ key, value, type, fields }) => {
  if (key === null || key === "None") return;

  const field = fields.find((f) => f.key === key);
  if (!field) return;

  if (field.type === "numeric") {
    return type
      ? getNumericFilter({ field, type, value })
      : getBandsFilter({ field, value });
  }
  return {
    displayName: field.displayName,
    field: key,
    type: "customIn",
    displayType: ":",
    value: [unclean(value)],
  };
};

/**
 * RETURNS: Custom prop object
 * name: 'Custom'
 * fields: fields to show on tracker table
 * filters: filters to implement on tracker table
 *
 */
//TECH DEBT: This whole function is very confusing especially when it is called and more things are being passed in than needed. There are also args not being used.?!?

export function getRedirectCustomPropFromChart({
  filters: inputFilters, // array of objects {key: field key, value: value to filter by}
  sidebarFilters = [], // array of filter objects
  fields: inputFields, // main fields object
  redirectDefaultFields, // field keys to add in as standard when creating tracker view
}) {
  return {
    name: "Custom",
    fields: inputFilters
      .map((element) => element.key)
      .concat(sidebarFilters.map((element) => element.field))
      .concat(redirectDefaultFields)
      .map((key) => getFieldDisplayName({ key, fields: inputFields })),
    filters: inputFilters
      .map((element) =>
        getFilter({
          key: element.key,
          value: element.value,
          type: element.type,
          fields: inputFields,
        })
      )
      .concat(sidebarFilters)
      .flat(),
  };
}

export function getRedirectCustomProp({
  rowKey, // field key for row disaggregation (string)
  rowSubKey = null, // (optional) field key for secondary row disaggregation (string)
  columnKey, //  field key for column disaggregation (string)
  fields: inputFields, // main fields object
  rowValue,
  rowSubValue,
  columnValue,
  sidebarFilters = [], // array of filter objects
  redirectDefaultFields, // field keys to add in as standard when creating tracker view
}) {
  const rowField = inputFields.find((field) => field.key === rowKey);
  const colField = inputFields.find((field) => field.key === columnKey);
  const rowSubField =
    rowSubKey === null
      ? null
      : inputFields.find((field) => field.key === rowSubKey);

  let shouldAbort = false;
  [rowField, colField, rowSubField].forEach((element) => {
    if (element === undefined) {
      console.warn("field is undefined");
      shouldAbort = true;
    }
  });
  if (shouldAbort) return;

  if (columnValue === "No data") columnValue = null;

  const rowGroupValue = getGroupValue({ field: rowField, value: rowValue });
  const rowSubGroupValue =
    rowSubKey === null
      ? null
      : getGroupValue({ field: rowSubField, value: rowSubValue });
  const columnGroupValue = processTrueFalse(columnValue);

  const filters = []
    .concat(
      rowValue === "Total"
        ? []
        : getFilter({
            key: rowField.key,
            value: rowGroupValue,
            fields: inputFields,
          })
    )
    .concat(
      //Alan don't hate me for adding another terniary operator lol. I don't have time to refactor this to be better.
      columnGroupValue === "total"
        ? []
        : getFilter({
            key: colField.key,
            value: columnGroupValue,
            fields: inputFields,
          })
    )
    .concat(
      rowSubKey === null
        ? []
        : getFilter({
            key: rowSubField.key,
            value: rowSubGroupValue,
            fields: inputFields,
          })
    )
    .concat(sidebarFilters)
    .flat();

  const fields = [rowKey, columnKey]
    .concat(rowSubKey === null ? [] : rowSubKey)
    .concat(sidebarFilters.map((element) => element.field))
    .concat(redirectDefaultFields)
    .map((key) => getFieldDisplayName({ key, fields: inputFields }));

  return {
    name: "Custom",
    fields: [...new Set(fields)],
    filters,
  };
}

/**
 * This function takes row and column data that is going to be send to Tabulator and populates a total column and its associated row data.
 * It sums up each row and will provide totals based on the selectedFormat Option.
 *
 * @param {object} rowAndColumnData Obect that has rows(array) and columns(array)
 * @param {string} formatOption Format option selected by user. (count, percentage, count & percentage)
 * @returns object with the same structure as rowAndColumnData
 */
export function addTotalColumn(rowAndColumnData, formatOption, totalRecords) {
  // console.log(rowAndColumnData);
  //Add Total Column header and matching field
  rowAndColumnData.columns.push({
    title: "Total",
    field: "total",
  });

  //Sum up row and add on total value to row data
  const rowsWithTotals = rowAndColumnData.data.map((row) => {
    //Normally each row add up to 100%. But if they want the entire Total column to add up to 100% then we have to recalculate.
    let percentage = totalRecords
      ? (row.totalCount / totalRecords).toFixed(3) * 100
      : row.totalPercentage;
    percentage = percentage.toFixed(0);

    row["total"] = formatCellValue(
      row.totalCount,
      percentage,
      formatOption || "Show count"
    );

    //return updated row data
    return row;
  });

  //Set data with totals as table data
  rowAndColumnData.data = rowsWithTotals;
  return rowAndColumnData;
}

<template>
  <div id="loading"></div>
</template>

<style lang="scss">
// copied from https://codepen.io/mandelid/pen/kNBYLJ?editors=1100
#loading {
  display: inline-block;
  width: 50px;
  height: 50px;
  border: 3px solid rgba(var(--color-primary-rgb), 0.3);
  border-radius: 50%;
  border-top-color: var(--color-primary);
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
</style>

<script>
export default {
  name: "spinner",
};
</script>

/**Select students from lists corresponding to the myTags field. Used in
activitiesModal.vue*/

<template>
  <div class="student-select-widget" data-cy="student-select-widget">
    <div>
      Select student(s)
      <span
        v-for="(icon, index) in icons"
        :key="index"
        class="material-icons my-tag"
        :class="getTagClass(icon)"
        @click="selectIcon(icon)"
      >
        {{ icon }}
      </span>

      <div class="selected-student-area">
        <span
          v-for="(student, index) in selectedStudents"
          :key="index"
          class="selected-student"
          @click="removeSelected(student)"
        >
          {{ student.name }}
        </span>
      </div>
    </div>
    <input
      type="text"
      data-cy="student-name-input"
      placeholder="Start typing student name..."
      class="student-select-area"
      ref="student-textarea"
      v-model="myMessage"
      @keydown.enter="addStudentFromList"
      @keydown.down="nextActiveStudent"
      @keydown.up="previousActiveStudent"
    />
    <div class="student-name-area" v-if="matchingStudents.length">
      <div
        v-for="(student, index) in matchingStudents"
        :key="index"
        :class="{ activeStudentClass: index === activeStudent }"
      >
        <div
          @mouseover="activeStudent = index"
          @click="addStudentFromList"
          data-cy="student-name-entry"
        >
          {{ student.name }}
        </div>
      </div>
    </div>
  </div>
  <div
    class="student-pool"
    :class="
      mode == 'Check-ins' ? 'student-pool-expanded' : 'student-pool-normal'
    "
    v-show="studentPool.length"
  >
    <div class="student-pool-title">Choose students</div>
    <div class="student-pool-content-area" data-cy="student-pool-content-area">
      <CustomButton @click="addAllFromPool" buttonText="Add all" />
      <div
        v-for="(student, index) in studentPool"
        :key="index"
        class="student-pool-entry"
      >
        <span @click="add({ student })">
          {{ student.name }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import CustomButton from "@/components/customButton.vue";

export default {
  props: {
    mode: String,
    studentsToEdit: Array,
  },
  components: { CustomButton },
  data() {
    return {
      myMessage: "",
      matchingStudents: [],
      activeStudent: 0,
      activeStudentClass: "activeStudentClass",
      selectedStudents: [],
      icons: ["star", "flag", "warning", "help", "done"],
      chosenIcon: "",
    };
  },
  emits: ["selectionChanged"],
  watch: {
    myMessage: function () {
      this.matchingStudents = this.getMatchingStudents(this.myMessage);
      this.activeStudent = 0;
    },
    studentsToEdit: function () {
      this.chosenIcon = "";
      this.myMessage = "";
      this.selectedStudents = this.studentsToEdit;
      this.$emit("selectionChanged", this.selectedStudents);
    },
  },
  methods: {
    selectIcon(icon) {
      this.chosenIcon = this.chosenIcon === icon ? "" : icon;
    },
    getTagClass(icon) {
      const active = this.chosenIcon == icon;
      return `my-tag-${icon}${active ? " my-tag-active" : ""}`;
    },
    getMatchingStudents(msg) {
      const ids = this.selectedStudents.map((element) => element.id);
      const names = this.store.state.studentData
        .map((record) => ({
          name: `${record.firstName} ${record.lastName}`,
          id: record.studentRISEId,
        }))
        .filter((record) => !ids.includes(record.id));
      const nameArr = msg.split(",").map((str) => str.trim());
      if (!nameArr.length) return [];
      const lastName = nameArr[nameArr.length - 1];
      const result = names.filter((n) =>
        n.name.toLowerCase().includes(lastName.toLowerCase())
      );
      if (result.length == names.length) return [];
      return result;
    },
    add({ student }) {
      const ids = this.selectedStudents.map((element) => element.id);
      if (ids.includes(student.id)) return;
      this.selectedStudents.push(student);
      this.myMessage = "";
      this.$emit("selectionChanged", this.selectedStudents);
    },
    addStudentFromList(e = null) {
      // add student to message being typed
      if (this.matchingStudents.length) {
        this.add({ student: this.matchingStudents[this.activeStudent] });
        this.$refs["student-textarea"].focus();
      }
      if (e) e.preventDefault();
    },
    addAllFromPool() {
      this.studentPool.forEach((student) => {
        this.add({ student });
      });
      this.chosenIcon = "";
    },
    removeSelected(student) {
      this.selectedStudents = this.selectedStudents.filter(
        (str) => str !== student
      );
      this.$emit("selectionChanged", this.selectedStudents);
    },
    nextActiveStudent(e) {
      if (this.matchingStudents.length) {
        this.activeStudent++;
        if (this.activeStudent >= this.matchingStudents.length)
          this.activeStudent = 0;
        e.preventDefault();
      }
    },
    previousActiveStudent(e) {
      if (this.matchingStudents.length) {
        this.activeStudent--;
        if (this.activeStudent < 0)
          this.activeStudent = this.matchingStudents.length - 1;
        e.preventDefault();
      }
    },
  },
  computed: {
    studentPool: function () {
      if (!this.chosenIcon) return [];
      const myList = this.$user.preferences.taggedStudents[this.chosenIcon];
      return this.store.state.studentData
        .map((record) => ({
          name: `${record.firstName} ${record.lastName}`,
          id: record.studentRISEId,
        }))
        .filter((record) => myList.includes(record.id))
        .filter(
          (record) => !this.selectedStudents.some((r) => r.id == record.id)
        );
    },
  },
};
</script>

<style lang="scss">
.student-select-widget {
  margin-top: 1.5rem;
  .student-select {
    margin-top: 2rem;
    width: 400px;
    border: none;
    height: 34px;
    margin-bottom: 1rem;
  }
  .student-select-area {
    width: 400px;
    height: 34px;
    border: none;
    margin: 1rem 0;
  }
  .student-name-area {
    position: absolute;
    background-color: var(--color-bg);
    padding: 5px;
    border-radius: 5px;
    line-height: 2.2rem;
    max-height: 250px;
    overflow: auto;
    width: 250px;
  }
  .activeStudentClass {
    background-color: var(--color-primary);
    color: var(--color-bg);
  }
  .selected-student-area {
    max-height: 100px;
    overflow: auto;
  }
  .selected-student {
    display: inline-block;
    background-color: var(--color-primary);
    color: var(--color-bg);
    border-radius: 10px;
    margin-right: 5px;
    margin-top: 5px;
    padding: 0 5px;
    &:hover {
      cursor: pointer;
      background-color: var(--color-contrast-medium);
      text-decoration: line-through;
    }
  }
}

.student-pool-expanded {
  left: 515px;
}

.student-pool-normal {
  left: 455px;
}

.student-pool {
  position: absolute;
  background-color: var(--color-contrast-lower);
  width: 200px;
  top: 20px;
  border-radius: 10px;
  .student-pool-title {
    background-color: var(--color-success);
    color: var(--color-bg);
    padding: 10px;
    font-weight: bold;
    border-radius: 10px 10px 0 0;
  }
  .student-pool-content-area {
    overflow-y: auto;
    max-height: 450px;
    padding: 0 10px 10px;
    .student-pool-entry {
      &:hover {
        background-color: var(--color-bg);
        cursor: pointer;
      }
      padding: 3px 0;
      border-radius: 4px;
    }
  }
}
</style>

/** R&O protocol slide: detailed R&O matrix. */
<template>
  <h1 class="slide-header">{{ slide.title }}</h1>
  <h3>{{ slide.subtitle }}</h3>

  <table class="ro-table">
    <tr>
      <td class="graph-col">
        <Matrix
          :matrixConfig="matrixConfig"
          @addMatrixFilter="addMatrixFilter"
        />
      </td>
      <td class="legend-col">
        <div>
          <span class="legend-block legend-success">&nbsp;</span>High
          Opportunity
        </div>
        <div>
          <span class="legend-block legend-gradeB">&nbsp;</span>Opportunity
        </div>
        <div>
          <span class="legend-block legend-info">&nbsp;</span>Vulnerable
        </div>
        <div>
          <span class="legend-block legend-warning">&nbsp;</span>High Risk
        </div>
      </td>
    </tr>
  </table>

  <div>
    <ul class="slide-questions">
      <li v-for="(question, index) in slide.questions" :key="index">
        {{ question }}
      </li>
    </ul>
  </div>
</template>

<script>
import Matrix from "@/components/matrix.vue";
import { getMatrixCells, getMatrixFilters } from "@/functions/matrix.js";

const matrixConfig = {
  columnHeader: "G8 GPA",
  rowHeader: "G8 Avg. Daily Attendance",
  columnFieldKey: "g9RiskAndOpportunity.GPA",
  rowFieldKey: "g9RiskAndOpportunity.ADA",
  columns: ["0.0-0.99", "1.0-1.99", "2.0-2.99", "3.0-4.0"],
  rows: [">98%", "95%-98%", "90-95%", "80-90%", "<80%"],
  labels: ["High Risk", "Vulnerable", "Opportunity", "High Opportunity"],
  rowFilters: [
    { gte: 98 },
    { lt: 98, gte: 95 },
    { lt: 95, gte: 90 },
    { lt: 90, gte: 80 },
    { lt: 80 },
  ],
  columnFilters: [{ lt: 1 }, { lt: 2, gte: 1 }, { lt: 3, gte: 2 }, { gte: 3 }],
  cells: [
    // calculated later
    [0, 0, 0, 0],
    [0, 0, 0, 0],
    [0, 0, 0, 0],
    [0, 0, 0, 0],
    [0, 0, 0, 0],
  ],
  cellClasses: [
    ["warning", "warning", "success", "high-success"],
    ["problem", "warning", "warning", "high-success"],
    ["problem", "problem", "warning", "success"],
    ["problem", "problem", "problem", "warning"],
    ["problem", "problem", "problem", "warning"],
  ],
};

export default {
  props: {
    slide: Object,
  },
  data() {
    return {
      matrixConfig: this.getMatrix(matrixConfig),
    };
  },
  methods: {
    getMatrix(config) {
      config.cells = getMatrixCells({
        config,
        data: this.store.state.studentData,
        fields: this.config.fields,
      });
      return config;
    },
    addMatrixFilter({ i, j }) {
      const myMatrixFilters = getMatrixFilters({
        config: matrixConfig,
        fields: this.config.fields,
        i,
        j,
      });

      const fieldList = [
        "_studentName",
        "g9RiskAndOpportunity.GPA",
        "g9RiskAndOpportunity.ADA",
        "gender",
        "raceAndEthnicity.race",
        "englishLearner",
        "specialEducation",
      ];

      this.store.setCustomProp({
        filters: myMatrixFilters,
        fields: fieldList
          .map(
            (f) =>
              this.config.fields.find((field) => field.key == f)?.displayName
          )
          .filter((f) => f !== undefined),
        columnLayout: fieldList.map((f) => ({ field: f })),
      });

      this.$router.push("/tracker");
    },
  },
  components: { Matrix },
};
</script>

<style lang="scss">
.ro-table {
  .graph-col {
    .matrix-cell {
      width: 100px !important;
      padding: 20px 0;
    }
    .row-header {
      width: 100px !important;
      text-align: center;
    }
    .matrix-header-row {
      height: 50px;
    }
  }
  .legend-col {
    width: 250px;
    padding: 20px;
    .legend-block {
      display: inline-block;
      width: 20px;
      margin: 3px 8px;
    }
    .legend-warning {
      background-color: var(--color-warning);
    }
    .legend-info {
      background-color: var(--color-info);
    }
    .legend-gradeB {
      background-color: var(--color-grade-b);
    }
    .legend-success {
      background-color: var(--color-success);
    }
  }
}
</style>

/**
 * Contains the contents of the each protocol, as used in Slides.vue
 */

import { fields } from "@/apps/fields.js";

export const protocols = {
  "R/O Protocol": {
    slides: [
      {
        title: "Introduction",
        component: "HeaderSlide",
        heading: "Risk and Opportunity Protocol",
        content: [
          {
            title: "Summary",
            body: "Risk and Opportunity (R/O) is a method of identifying potential student needs for all incoming Grade 9 students by using two key 8th grade indicators: Grade 8 GPA in core classes and Grade 8 attendance.",
          },
          {
            title: "Purpose",
            body: "Develop an understanding of R/O data to identify students who may have struggled in 8th grade. That way, early supports can be provided for students to disrupt previous attendance or academic patterns.",
          },
          {
            title: "Key Actions",
            body: "Plan key targeted supports, especially for students who have been identified as high risk or vulnerable to ensure that they have successful transitions to high school.",
          },
        ],
      },
      {
        title: "Student Attendance, GPA, and Behavior Performance Bands",
        subtitle:
          "To understand students’ 8th grade performance, we look at attendance, GPA, and number of suspensions. The green thresholds tell us where we want students to be.",
        component: "ROCustomA",
        questions: [
          "What stands out in the data?  What do you notice about students attendance, grades, and behavior in middle school?",
          "What might the data tell us about students’ experiences in middle school?",
          "How can we support students in continuing on a good trajectory or facilitating a clean start in high school?",
        ],
      },
      {
        title: "Students by Number of Risk Factors",
        subtitle:
          "A risk factor is when a student is not meeting the threshold set for one of the areas in the previous slide (attendance, behavior, or course performance). A student may have 0 or multiple risk factors, based on 8th grade data.",
        component: "ChartAndQuestions",
        charts: [
          {
            chartConfig: {
              type: "analyticsPie",
              selectedFormatOption: "Show count and percentage",
              title: "",
              primaryField: fields.find(
                (field) => field.key === "g9RiskAndOpportunity.riskFactors"
              ),
              legendPosition: "right",
              redirectDefaultFields: [
                "_studentName",
                "g9RiskAndOpportunity.ADA",
                "g9RiskAndOpportunity.GPA",
                "g9RiskAndOpportunity.suspensions",
                "g9RiskAndOpportunity.riskAndOpportunity",
                "gender",
                "raceAndEthnicity.race",
                "englishLearner",
                "specialEducation",
              ],
            },
          },
        ],
        questions: [
          "Is the number of students with zero risk factors lower or higher than you expected?",
          "Is the number of students with 1, 2, and 3 risk factors lower or higher than you expected?",
        ],
      },
      {
        title: "Detailed Risk & Opportunity Grid",
        subtitle:
          "A student’s R/O status is determined by both their GPA and their attendance. The matrix below shows how the two interact to give a student their final status.",
        component: "ROCustomB",
        questions: [
          "Note: Repeaters are not included in this view",
          "What do you notice about the distribution of students in their attendance and grades?  Where do we see concentrations of students?",
          "What information does this provide about students’ needs for this year?",
          "How might our team act upon this information?",
          "How might the data inform our use of team time and/or our collective team focus area?",
        ],
      },
      {
        title: "Risk and Opportunity Breakdown",
        subtitle:
          "The bar graph below shows the total number and percentage of students in each R/O category.",
        component: "ChartAndQuestions",
        charts: [
          {
            chartConfig: {
              type: "singleBand",
              title: "Performance Groups",
              primaryField: fields.find(
                (field) =>
                  field.key === "g9RiskAndOpportunity.riskAndOpportunity"
              ),
              showAsPercentage: true,
              displayTicks: true,
            },
          },
        ],
        questions: [
          "What do you notice about the distribution of students across the categories?",
          "What additional information would we want to collect to provide support for students in the high risk and vulnerable categories?",
          "How might our team act upon this information?",
          "How might the data inform our use of team time and/or our collective team focus area?",
        ],
      },
      {
        title: "Risk and Opportunity Breakdown by ELL Status",
        subtitle:
          "The bar graphs below show the number and percentages of students in each R/O category for ELL / Non-ELL students",
        component: "ChartAndQuestions",
        charts: [
          {
            chartConfig: {
              type: "grouped",
              title: "",
              primaryField: fields.find(
                (field) => field.key === "englishLearner"
              ),
              secondaryField: fields.find(
                (field) =>
                  field.key === "g9RiskAndOpportunity.riskAndOpportunity"
              ),
              stacked: true,
              selectedFormatOption: "Show count and percentage",
              showAsPercentage: true,
            },
          },
        ],
        questions: [
          "What opportunity gaps stand out in the data?",
          "If we don’t address these gaps, what could that mean for our students?",
          "How can we work to disrupt these gaps?",
          "How do we avoid labelling students as the transition into high school, and instead provide them with the equitable supports they need?",
        ],
      },
      {
        title: "Risk and Opportunity Breakdown by Special Education Status",
        subtitle:
          "The bar graphs below show the number and percentages of students in each R/O category for SpEd / Non-SpEd students",
        component: "ChartAndQuestions",
        charts: [
          {
            chartConfig: {
              type: "grouped",
              title: "",
              primaryField: fields.find(
                (field) => field.key === "specialEducation"
              ),
              secondaryField: fields.find(
                (field) =>
                  field.key === "g9RiskAndOpportunity.riskAndOpportunity"
              ),
              stacked: true,
              selectedFormatOption: "Show count and percentage",
              showAsPercentage: true,
            },
          },
        ],
        questions: [
          "What opportunity gaps stand out in the data?",
          "If we don’t address these gaps, what could that mean for our students?",
          "How can we work to disrupt these gaps?",
          "How do we avoid labelling students as the transition into high school, and instead provide them with the equitable supports they need?",
        ],
      },
      {
        title: "Risk and Opportunity Breakdown by Race / Ethnicity",
        subtitle:
          "The bar graphs below show the number and percentages of students in each R/O category, broken down by race / ethnicity",
        component: "ChartAndQuestions",
        charts: [
          {
            chartConfig: {
              type: "grouped",
              title: "",
              primaryField: fields.find(
                (field) => field.key === "raceAndEthnicity.race"
              ),
              secondaryField: fields.find(
                (field) =>
                  field.key === "g9RiskAndOpportunity.riskAndOpportunity"
              ),
              stacked: true,
              selectedFormatOption: "Show count and percentage",
              showAsPercentage: true,
            },
          },
        ],
        questions: [
          "What opportunity gaps stand out in the data?",
          "If we don’t address these gaps, what could that mean for our students?",
          "How can we work to disrupt these gaps?",
          "How do we avoid labelling students as the transition into high school, and instead provide them with the equitable supports they need?",
        ],
      },
    ],
  },
};

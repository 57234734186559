/** Fresh Success Tag Manager is a special type of Modal for managing the
school's Freshmen Success tags collection. */
<template>
  <div class="tag-manager-container">
    <div :class="dynamicClass" class="tag-manager">
      <div class="tag-manager-content">
        <div class="tag-manager-header">
          <span @click="hide" class="close">&times;</span>
          <h2 v-show="mode === 'manage'">
            {{ student.firstName }}'s Grade 9 Tags
          </h2>
          <h2 v-show="mode === 'edit'">Edit Tag</h2>
          <h2 v-show="mode === 'add'">New Tag</h2>
        </div>
        <div class="tag-manager-body">
          <div v-show="mode === 'manage'">
            <p>All changes are saved automatically.</p>
            <div
              class="tag-listing-area grid-container"
              v-if="store.state.freshSuccessTags.length"
            >
              <div
                class="tag-listing"
                v-for="(tag, index) in store.state.freshSuccessTags"
                :key="index"
              >
                <span
                  class="badge tag-badge"
                  :style="
                    'background-color: #' +
                    tag.color +
                    '; color:' +
                    blackOrWhite(tag.color) +
                    ';'
                  "
                  @click="updateStudent(tag)"
                >
                  <span v-if="studentHas(tag)">&#x2714;</span>
                  {{ tag.title }}
                </span>
                <span
                  v-if="userIsManager()"
                  class="material-icons edit-message-button"
                  @click="launchEditTag(tag)"
                >
                  edit
                </span>
              </div>
            </div>
            <div v-else class="no-tags-message">
              <p>No Tags to display</p>
            </div>
            <CustomButton
              data-cy="tag-manager-success"
              class="btn"
              @click="success"
              buttonText="Close"
            />
            <CustomButton
              data-cy="add-tag-button"
              v-if="userIsManager()"
              style="float: right"
              class="btn btn-success"
              @click="changeMode('add')"
              buttonText="New Tag"
            />
          </div>
          <div v-show="mode == 'add'">
            <div class="edit-tag-area">
              <p style="margin: 0 0 10px">Input tag title</p>
              <input
                data-cy="add-tag-input"
                id="addTagInput"
                style="width: 230px"
                v-model="newTagName"
                placeholder="Tag name"
              />
              <p style="margin: 10px 0 0">Select tag color</p>
              <input class="color-input" type="color" v-model="newTagColor" />
            </div>
            <CustomButton
              data-cy="add-tag-confirm-button"
              class="btn"
              @click="addTag"
              buttonText="OK"
            />
            <CustomButton
              class="btn"
              @click="changeMode('manage')"
              buttonText="Cancel"
            />
          </div>
          <div v-show="mode == 'edit'">
            <div class="edit-tag-area">
              <p style="margin: 0 0 10px">Input tag title</p>
              <input
                data-cy="edit-tag-input"
                id="editTagInput"
                style="width: 230px"
                v-model="tagName"
                placeholder="Tag name"
              />
              <p style="margin: 10px 0 0">Select tag color</p>
              <input class="color-input" type="color" v-model="tagColor" />
            </div>
            <CustomButton
              data-cy="edit-tag-confirm-button"
              class="btn"
              @click="updateTag"
              buttonText="OK"
            />
            <CustomButton
              class="btn"
              @click="changeMode('manage')"
              buttonText="Cancel"
            />
            <CustomButton
              data-cy="delete-tag-button"
              style="float: right"
              class="btn btn-warning"
              @click="deleteTag"
              buttonText="Delete Tag"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CustomButton from "@/components/customButton.vue";
import { blackOrWhite } from "@/functions/utils.js";

export default {
  name: "FreshSuccessTagManager",
  data() {
    return {
      myValue: "",
      dynamicClass: "hidden",
      newTagName: "",
      tagName: "",
      tagColor: "#000000",
      newTagColor: "#000000",
      mode: "manage",
      tagBeingEdited: {},
      studentID: "",
    };
  },
  methods: {
    setEventListeners() {
      this.ee.on("openFreshSuccessTagManager", (id) => this.show(id));
    },
    changeMode(newMode) {
      this.mode = newMode;
    },
    show(id) {
      this.studentID = id;
      this.mode = "manage";
      this.dynamicClass = "visible";
    },
    hide() {
      this.dynamicClass = "hidden";
    },
    success() {
      this.hide();
    },
    addTag(
      event, // mouse click event
      newTagName = this.newTagName,
      newTagColor = this.newTagColor,
      dbInterface = this.fb.freshSuccessTags,
      reset = this.reset
    ) {
      if (!newTagName) return;

      // add to firestore
      dbInterface.add({
        title: newTagName,
        color: newTagColor.replace("#", ""),
      });

      // reset
      reset();
    },
    reset() {
      this.newTagName = "";
      this.mode = "manage";
    },
    deleteTag(
      event, // mouse click event
      tagBeingEdited = this.tagBeingEdited,
      dbInterface = this.fb.freshSuccessTags,
      studentData = this.store.state.studentData,
      updateStudentRecord = this.updateStudentRecord,
      reset = this.reset
    ) {
      const tagID = tagBeingEdited.docID;

      // delete from firestore
      dbInterface.delete(tagBeingEdited);

      // remove tag from all students
      studentData.forEach((student) => {
        const value = student.freshSuccessTags[0]?.value // is there a log entry?
          ? student.freshSuccessTags[0].value.includes(tagID) // does that tag exist in the array?
            ? student.freshSuccessTags[0].value.filter(
                (element) => element !== tagID
              ) // remove the tag from the array
            : null
          : null;

        if (value !== null) {
          updateStudentRecord({
            fieldKey: "freshSuccessTags",
            id: student.studentRISEId,
            value: value,
          });
        }
      });

      // reset UI
      reset();
    },
    updateStudentRecord(obj) {
      this.ee.emit("updateRecord", obj);
    },
    launchEditTag(tag) {
      this.tagBeingEdited = tag;
      this.tagName = tag.title;
      this.tagColor = "#" + tag.color;
      this.mode = "edit";
      setTimeout(() => {
        document.getElementById("editTagInput").focus();
      }, 50);
    },
    updateTag() {
      this.tagBeingEdited.title = this.tagName;
      this.tagBeingEdited.color = this.tagColor.replace("#", "");
      this.tagName = "";
      this.fb.freshSuccessTags.update(
        this.tagBeingEdited.docID,
        this.tagBeingEdited
      );
      this.mode = "manage";
    },
    updateStudent(
      tag,
      student = this.student,
      updateStudentRecord = this.updateStudentRecord
    ) {
      // add or remove tag to student's list of tags

      const value = student.freshSuccessTags[0]?.value // is there a log entry?
        ? student.freshSuccessTags[0].value.includes(tag.docID) // does that tag exist in the array?
          ? student.freshSuccessTags[0].value.filter(
              (element) => element !== tag.docID
            ) // remove the tag from the array
          : student.freshSuccessTags[0].value.concat(tag.docID) // add the tag to the array
        : [tag.docID]; // the array will contain this tag only

      updateStudentRecord({
        fieldKey: "freshSuccessTags",
        id: student.studentRISEId,
        value: value,
      });
    },
    studentHas(tag, student = this.student) {
      // returns true if student has the tag
      return student.freshSuccessTags?.[0]?.value // is there a log entry?
        ? student.freshSuccessTags[0].value.includes(tag.docID) // does that tag exist in the array?
          ? true
          : false
        : false;
    },
    blackOrWhite(color) {
      return blackOrWhite(color);
    },
    userIsManager() {
      return this.$user ? this.$user.hasPower("managePostSecTags") : false;
    },
  },
  computed: {
    student: function () {
      const result = this.store.state.studentData.find(
        (element) => element.studentRISEId === this.studentID
      );
      return result ?? {};
    },
  },
  components: {
    CustomButton,
  },
  mounted() {
    this.setEventListeners();
  },
};
</script>

<style lang="scss" scoped>
.btn {
  margin-right: 20px;
  margin-bottom: 20px;
}

.hidden {
  display: none;
}

.visible {
  display: block;
}

.no-tags-message {
  margin-top: 10px;
}

.tag-badge {
  color: var(--color-bg);
  margin-right: 10px;
  &:hover {
    cursor: pointer;
  }
}

.tag-listing-area {
  margin-top: 12px;
  margin-bottom: 12px;
}

.edit-tag-area {
  margin-top: 12px;
  margin-bottom: 20px;
}

.tag-listing {
  margin-top: 5px;
}

.tag-manager {
  font-family: "Lato", sans-serif;
  position: fixed;
  z-index: 10;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: var(--color-contrast-full);
  background-color: rgba(0, 0, 0, 0.4);
}

.tag-manager-content {
  position: relative;
  background-color: var(--color-contrast-lower);
  margin: auto;
  padding: 0;
  border: 1px solid var(--color-contrast-higher);
  width: 450px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  -webkit-animation-name: animatetop;
  -webkit-animation-duration: 0.4s;
  animation-name: animatetop;
  animation-duration: 0.4s;
  border-radius: 10px;
  h2 {
    margin-top: 0;
    margin-bottom: 0;
  }
  .tag-manager-header {
    padding: 10px 16px;
    background-color: var(--color-success);
    color: var(--color-bg);
    border-radius: 10px 10px 0px 0px;
  }
  .tag-manager-body {
    padding: 0px 16px 0px;
  }
  .color-input {
    position: relative;
    top: 8px;
    left: 5px;
  }
}

@-webkit-keyframes animatetop {
  from {
    top: -300px;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}

@keyframes animatetop {
  from {
    top: -300px;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}

.close {
  color: var(--color-bg);
  float: right;
  font-size: 28px;
  font-weight: bold;
  &:hover,
  &:focus {
    color: var(--color-contrast-medium);
    text-decoration: none;
    cursor: pointer;
  }
}

.grid-container {
  display: grid;
  grid-template-columns: auto auto;
  //   padding: 10px;
}
</style>
